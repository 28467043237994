import { SITE_IDS } from 'utils/enums';
import Restricted from 'components/ui/Restricted';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './LocationRunSearchButton.scss';
import cx from 'classnames';

interface Props {
  hasFocusOnExpand?: boolean;
  isHomeRoute: boolean;
  to?: string;
  openLocationBar(isLocationVisible: boolean);
  handleSearch(event?: Event): void;
  isSellerDirectorySearchResults?: boolean;
}

function LocationRunSearchButton({
  isSellerDirectorySearchResults,
  hasFocusOnExpand,
  isHomeRoute,
  openLocationBar,
  handleSearch
}: Props) {
  return (
    <div
      role="button"
      tabIndex={0}
      className={cx(
        styles['button-search'],
        isSellerDirectorySearchResults ? styles['seller-directory-search-results'] : ''
      )}
      data-qa-searchbutton
      onClick={() => {
        openLocationBar(false);
        handleSearch();
      }}
      aria-label="Search"
    >
      <Restricted to={[SITE_IDS.LANDWATCH, SITE_IDS.LANDANDFARM]}>
        <span></span>
      </Restricted>

      <Restricted to={SITE_IDS.LAND}>
        {isHomeRoute ? (
          <span className={styles.magnify} />
        ) : (
          <span className={hasFocusOnExpand ? styles.magnify_active : styles.magnify_icon} />
        )}
      </Restricted>
    </div>
  );
}

export default LocationRunSearchButton;
