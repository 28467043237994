/* eslint-disable @typescript-eslint/naming-convention */
import ListingLogInfo from 'store/models/listingLogInfo';
import { GA4Event, GA4EventData } from '../ga4Classes';
import { getListingLevel, getUpgradeLevelString, pushToDataLayer } from '../ga4Helpers';

import { PropertyListing } from 'store/commonInterfaces/iSearchObjects';

export function leadCreatedOnFinancePage() {
  const event = new GA4Event({
    event: 'generate_lead_finance',
    detailed_event: 'Generate Lead - Finance',
    event_data: new GA4EventData({
      count_lead_finance: 1
    })
  });
  pushToDataLayer(event);
}

export function leadCreatedOnListingPage(optIn: boolean) {
  const event = new GA4Event({
    event: 'generate_lead',
    detailed_event: 'Generate Lead',
    event_data: new GA4EventData({
      count_lead_newsletter_optin: optIn == true ? 1 : 0
    })
  });

  pushToDataLayer(event);
}

// This event is for leads created everywhere other
// than on the listing and finance pages.
export function leadCreatedOther(accountId: string, optIn: boolean, listingLogInfo: ListingLogInfo) {
  const event = new GA4Event({
    event: 'generate_lead',
    detailed_event: 'Generate Lead - Search',
    event_data: new GA4EventData({
      account_id: accountId,
      count_lead_newsletter_optin: optIn == true ? 1 : 0,
      global_listing_id: listingLogInfo?.listingId.toString(),
      site_listing_id: listingLogInfo?.siteListingId.toString(),
      listing_tier: listingLogInfo?.listingTier ? getListingLevel(listingLogInfo?.listingTier) : undefined,
      upgrade_level: getUpgradeLevelString(listingLogInfo?.propertyUpgradeLevel)
    })
  });

  pushToDataLayer(event);
}

export function shortLeadFormClicked() {
  const event = new GA4Event({
    event: 'lead_short_form_clicked',
    detailed_event: 'Lead Short Form Clicked'
  });

  pushToDataLayer(event);
}

// We use this event version when viewing Lead Forms in situations where the context is clear from previous events.
// E.g. - if we are viewing the Lead Form on an LDP, we don't need to log the listing as it's clear from previous
// events which listing this event is related to.
export function leadFormViewed() {
  const event = new GA4Event({
    event: 'lead_form_viewed',
    detailed_event: 'Lead Form Viewed'
  });

  pushToDataLayer(event);
}

// We use this event version when the contact button is clicked on broker/agent placards or ads.
// We include broker data since previous events don't provide context as to which broker/agent this event is related to.
export function leadFormViewedFromBroker(brokerId: number) {
  const eventData = new GA4EventData({
    account_id: brokerId ? brokerId.toString() : undefined
  });

  const event = new GA4Event({
    event: 'lead_form_viewed',
    detailed_event: 'Lead Form Viewed - Search',
    event_data: eventData
  });

  pushToDataLayer(event);
}

// We use this event version when the contact button is clicked on property placards.
// We include listing data since previous events don't provide context as to which listing this event is related to.
export function leadFormViewedFromListing(listing: PropertyListing) {
  const eventData = new GA4EventData({
    account_id: listing.accountId ? listing.accountId.toString() : undefined,
    global_listing_id: listing.id ? listing.id.toString() : undefined,
    site_listing_id: listing.siteListingId ? listing.siteListingId.toString() : undefined,
    listing_tier: listing.listingLevel ? getListingLevel(listing.listingLevel) : undefined,
    upgrade_level: getUpgradeLevelString(listing.upgradeLevelId)
  });

  const event = new GA4Event({
    event: 'lead_form_viewed',
    detailed_event: 'Lead Form Viewed - Search',
    event_data: eventData
  });

  pushToDataLayer(event);
}
