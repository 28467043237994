import styles from './MarketBlurb.scss';

interface Props {
  seoTopHeader: string;
  seoTopText: string;
  seoTopFooter: string;
}

const MarketBlurb = (props: Props) => {
  return (
    <div className={styles['market-blurb']}>
      <div className={styles['content-container']}>
        <div className={styles.title} dangerouslySetInnerHTML={{ __html: props.seoTopHeader }} />
        <div className={styles.content}>
          <span dangerouslySetInnerHTML={{ __html: props.seoTopText }} /> <br />
          <span className={styles.desktop} dangerouslySetInnerHTML={{ __html: props.seoTopFooter }} />
        </div>
      </div>
    </div>
  );
};

export default MarketBlurb;
