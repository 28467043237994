import React from 'react';
import { Helmet } from 'react-helmet-async';

const RouteHead: React.FunctionComponent<{
  pageTitle: string;
  metaDescription: string;
  canonicalUrl: string;
  children?: React.ReactNode;
  // Required to ensure preloading of css and js so we don't experience flashes of content on page load
  pageAssets: [string, string][];
  encodeSpecialCharacters?: boolean;
  noIndex?: boolean;
}> = ({
  pageTitle,
  metaDescription,
  canonicalUrl,
  pageAssets,
  children,
  encodeSpecialCharacters = false,
  noIndex = false
}) => {
  const isProd = process.env.NODE_ENV === 'production';

  if (isProd && !pageAssets.every(item => !!item)) {
    console.error('Route does not have pageAssets');
  }

  return (
    // have to call helmet with encodeSpecialCharacters to ensure ampersands, etc show up correctly
    <Helmet encodeSpecialCharacters={encodeSpecialCharacters}>
      <title>{pageTitle}</title>
      {metaDescription && <meta name="description" content={metaDescription} />}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {noIndex && <meta name="robots" content="noindex" />}
      {isProd
        ? pageAssets.map(
            asset =>
              asset && [
                // Oddly , it seems the attributes on these link elements must start with the rel attribute or it causes a bug in the react-helmet package and the links are not server side rendered
                <link rel="preload" key={1} href={`/${asset[0]}`} as="style" />,
                <link rel="stylesheet" key={2} href={`/${asset[0]}`} />,
                <link rel="preload" key={3} href={`/${asset[1]}`} as="script" />,
                <script key={4} src={`/${asset[1]}`} />
              ]
          )
        : ''}
      {children}
    </Helmet>
  );
};

export default RouteHead;
