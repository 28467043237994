import { ReactNode } from 'react';
import cx from 'classnames';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './PlacardBody.scss';

export default function PlacardBody({ children, listingType }: { children: ReactNode; listingType: string }) {
  return (
    <div id="placard-body" className={cx(styles[`level-${listingType}`], styles['placard-body'])}>
      {children}
    </div>
  );
}
