/* eslint-disable @typescript-eslint/naming-convention */
import { GA4Event, GA4EventData } from '../ga4Classes';
import { pushToDataLayer } from '../ga4Helpers';

export function imageGalleryOpened() {
  const event = new GA4Event({
    event: 'image_gallery_opened',
    detailed_event: 'Image Gallery Opened'
  });

  pushToDataLayer(event);
}

export function imageGalleryViewed(photoNumber: number, totalPhotos: number) {
  const event = new GA4Event({
    event: 'image_gallery_photo_viewed',
    detailed_event: 'Image Gallery Photo Viewed',
    event_data: new GA4EventData({
      photo_number: `${photoNumber}/${totalPhotos}`
    })
  });

  pushToDataLayer(event);
}

export function nearMeCarouselNavigation(lastVisibleImage: number, totalImages: number) {
  const event = new GA4Event({
    event: 'homepage_popular_land_carousel_navigation',
    detailed_event: 'Homepage Popular Land Carousel Navigation',
    event_data: new GA4EventData({
      home_carousel_navigation: `${lastVisibleImage}/${totalImages}`
    })
  });

  pushToDataLayer(event);
}

export function nearMeCarouselImageClicked(searchName: string) {
  const event = new GA4Event({
    event: 'homepage_popular_land_carousel_image_clicked',
    detailed_event: 'Homepage Popular Land Carousel',
    event_data: new GA4EventData({
      home_carousel_click: `Count_Homepage_Popular_${searchName}`
    })
  });

  pushToDataLayer(event);
}

export function activitiesCarouselNavigation(lastVisibleImage: number, totalImages: number) {
  const event = new GA4Event({
    event: 'homepage_activities_carousel_navigation',
    detailed_event: 'Homepage Activities Carousel Navigation',
    event_data: new GA4EventData({
      home_carousel_navigation: `${lastVisibleImage}/${totalImages}`
    })
  });

  pushToDataLayer(event);
}

export function activitiesCarouselImageClicked(searchName: string) {
  const event = new GA4Event({
    event: 'homepage_activities_carousel_image_clicked',
    detailed_event: 'Homepage Activities Land Carousel',
    event_data: new GA4EventData({
      home_carousel_click: `Count_Homepage_Activity_${searchName}`
    })
  });

  pushToDataLayer(event);
}
