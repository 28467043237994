import cx from 'classnames';
import Loading from 'components/ui/Loading';
import { ReactNode, useContext } from 'react';
import { PropertyListing } from 'store/commonInterfaces/iSearchObjects';
import { tagQa } from './PlacardHelpers';
import { PlacardContext } from './PlacardCTX';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './Placard.module.scss';

/* Note On Placard Component Design & Usage
 * ************************************
 * This design favors component composition
 * to reduce the need for prop drilling.
 * As such the returned content of placard can be used
 * without placard so that you can pass the props
 * directly to child components without the drilling through.
 */

/* Usage example (recommended):
 *************************************
 *   import { PlacardContainer } from '../Placard';  
 *   import { PlacardImageOrCarousel } from '../Placard/PlacardImageOrCarousel';
 *   import PlacardImageOverlays from '../Placard/PlacardImageOverlays';
 *   import PlacardInfo from '../Placard/PlacardInfo';
 *   import PlacardBroker from '../Placard/PlacardBroker';
 *   import PlacardToggleIcon from '../Placard/PlacardToggleIcon';
 *   import PlacardBody from '../Placard/PlacardBody';
 *
 *************************************
 *   <PlacardCTX
          isHome={isHome}
          isListingDetailsPage={isListingDetailsPage}
          isSearchResults={isSearchResults}
          isSimilarResult={isSimilarResult}
          isLanding={isLanding}
          listing={listing}
        >
          <PlacardContainer listing={listing} isLoading={loading}  className={styles.placard}> 
            <PlacardImageOrCarousel
              key={`${listing.id}-carousel`}
              siteIdentity={siteIdentity}
              listing={listing}
              imageDomain={this.props.store.imageDomain}
              store={store}
            
              isMapView={isMapView}
              isLazy={shouldLazyLoadImages}
              isSearchResults={isSearchResults}
            >
              <PlacardImageOverlays listing={listing} handler={handler} siteIdentity={siteIdentity} />
            </PlacardImageOrCarousel>
            <PlacardBody>
              <PlacardInfo siteIdentity={siteIdentity} listing={listing}/>
              <PlacardBroker
                listing={listing}
                isLazy={true}
                imageDomain={store.imageDomain}
                siteIdentity={store.siteIdentity}
                brokerUrl={getBrokerUrl(listing, store.siteId)}
           
              />
            </PlacardBody>
          </PlacardContainer>
        </PlacardCTX>
 *************************************
 */

export function PlacardContainer({
  children,
  className,
  listing,
  isLoading,
  onMouseEnter,
  onMouseLeave,
  listingType,
  isMapView = false
}: {
  children: ReactNode;
  className?: string;
  listing: PropertyListing;
  isLoading: boolean;
  onMouseEnter?: (id: number) => void;
  onMouseLeave?: () => void;
  listingType: string;
  isMapView?: boolean;
}) {
  const { isHovered, setIsHovered } = useContext(PlacardContext);

  const handleMouseEnter = () => {
    onMouseEnter && onMouseEnter(listing.id);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    onMouseLeave && onMouseLeave();
    setIsHovered(false);
  };

  return (
    <div
      id="placard-container"
      className={cx(
        className,
        styles['placard-container'],
        isMapView ? styles['map-view'] : '',
        styles[`level-${listingType}`],
        isHovered ? styles.hover : ''
      )}
      {...tagQa('placard', listing.siteListingId)}
      {...tagQa('listing', listing.siteListingId)}
      {...tagQa('placard-type', listingType)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isLoading && <Loading withOpacity blackDots containerClass={styles.loading} />}
      {children}
    </div>
  );
}
