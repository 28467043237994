import { useEffect, useMemo, useState } from 'react';
import { htmlToText } from 'utils/convenience';
import { LandMagazineArticle } from 'ssr/hydrationData';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './LandMagazineArticleItem.scss';
import Picture from 'components/ui/Picture';
import utils from 'utils/convenience';
import { ImageClass, ImageUrl, ImageFormat } from 'utils/variables';

interface LandMagazineArticleItemProps {
  landMagazineArticle: LandMagazineArticle;
  imageDomain: string;
}

export default function LandMagazineArticleItem(props: LandMagazineArticleItemProps) {
  const { landMagazineArticle, imageDomain } = props;
  const [summaryLineCount, setSummaryLineCount] = useState(3);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const handleSetLineCount = () => {
      const TITLE_LINE_HEIGHT = 24;
      const titleElement = document.getElementById(landMagazineArticle.title);
      setSummaryLineCount(titleElement?.scrollHeight > TITLE_LINE_HEIGHT ? 3 : 4);
      setInitialized(true);
    };

    handleSetLineCount();
    window.addEventListener('resize', handleSetLineCount);

    return () => window.removeEventListener('resize', handleSetLineCount);
  }, [landMagazineArticle.title]);

  const articleDate = useMemo(() => {
    const date = new Date(landMagazineArticle.publishDate);
    const monthAndDay = date.toLocaleDateString('US', { month: 'long', day: 'numeric' });
    return `${monthAndDay}, ${date.getFullYear()}`;
  }, [landMagazineArticle.publishDate]);

  const articleUrl = useMemo(() => {
    return ImageUrl(ImageClass.LandMagazineArticleImg, imageDomain, landMagazineArticle.imageId, ImageFormat.webp);
  }, [imageDomain, landMagazineArticle.imageId]);

  return (
    <div className={styles['article-grid-item']} key={landMagazineArticle.title} datatest-id="land-magazine-article">
      <div className={styles['image-container']}>
        <Picture alt={landMagazineArticle.title} className={styles['article-image']} isLazy={true} src={articleUrl} />
      </div>
      <div className={styles['article-content']}>
        <p className={styles['article-date']}>{articleDate}</p>
        <div className={styles['article-body-section']}>
          <a
            href={landMagazineArticle.articleLink}
            onClick={e => {
              utils.yieldExternalLinkClickToMainThread(e, landMagazineArticle.articleLink);
            }}
            rel="noopener noreferrer"
          >
            <h3 id={landMagazineArticle.title} className={styles['article-title']}>
              {landMagazineArticle.title}
            </h3>
          </a>
          {initialized ? (
            <p className={styles['article-body-' + summaryLineCount]}>{htmlToText(landMagazineArticle.summary)}</p>
          ) : (
            <p className={styles['article-body-placeholder']} />
          )}
        </div>
        <a
          href={landMagazineArticle.articleLink}
          rel="noopener noreferrer"
          onClick={e => {
            utils.yieldExternalLinkClickToMainThread(e, landMagazineArticle.articleLink);
          }}
          target="_blank"
          className={styles['article-link']}
        >
          Full Article <span className={styles['right-arrow-white']} />
        </a>
      </div>
    </div>
  );
}
