/* eslint-disable @typescript-eslint/naming-convention */
import { GA4Event, GA4EventData } from '../ga4Classes';
import { pushToDataLayer } from '../ga4Helpers';

export function userSignedIn() {
  const eventData = new GA4EventData({
    method: 'email'
  });
  const event = new GA4Event({
    event: 'login',
    detailed_event: 'User Signed In',
    event_data: eventData
  });
  pushToDataLayer(event);
}

export function userSignedUp() {
  const event = new GA4Event({
    event: 'sign_up',
    detailed_event: 'User Registered'
  });
  pushToDataLayer(event);
}
