import { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropertyLW from './PropertyLW';
import PropertyLand from './PropertyLand';
import { SITE_IDS } from 'utils/enums';
import Restricted from 'components/ui/Restricted';
import { PropertyCardProps } from 'utils/propertyCard';

const Property = (props: PropertyCardProps) => {
  return (
    <Fragment>
      <Restricted to={[SITE_IDS.LANDWATCH, SITE_IDS.LANDANDFARM]}>
        <PropertyLW {...props} />
      </Restricted>
      <Restricted to={SITE_IDS.LAND}>
        <PropertyLand key={`${props.listing.id}-property-land`} {...props} />
      </Restricted>
    </Fragment>
  );
};

export default withRouter(Property);
