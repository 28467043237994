export class FinanceCenterArticle {
  articleContent: string;
  articleId: number;
  articleSortOrder: number;
  created: Date;
  edited: boolean;
  financeId: number;
  heading: string;
  includeArticleLinks: boolean;
  rootId: number;
  typeID: number;
  typeName: string;
  typeSortOrder: number;
  updated: Date;

  constructor(data: Partial<FinanceCenterArticle> = {}) {
    Object.assign(this, data);
  }
}

export class FinanceCenterBasicInfo {
  active = true;
  checkboxAlt: string;
  checkboxDefaultState = false;
  checkboxLabel = 'Contact me with financing info about this property.';
  financeId: number;
  logoDocumentId: string;
  logoUrlPart: string;
  mapTrackableLink: string;
  minAcres: number;
  minPrice: number;
  name = 'A representative';
  navTrackableLink: string;
  queryString: string;
  showOnLAF = true;
  showOnLOA = true;
  showOnLW = true;
  urlPart: string;

  constructor(data: Partial<FinanceCenterBasicInfo> = {}) {
    Object.assign(this, data);
  }
}

export class FinanceCenterFullInfo extends FinanceCenterBasicInfo {
  articles: FinanceCenterArticle[];
  contactFormHeading: string;
  farmCreditURL: string;
  footerText: string;
  headingHeader: string;
  headingText: string;
  heroDesktopDocumentId: string;
  heroMobileDocumentId: string;
  mortgageCalculatorURL: string;
  preApproveURL: string;
  requirePhone: boolean;

  constructor(data: Partial<FinanceCenterFullInfo> = {}) {
    super(data);
  }
}
