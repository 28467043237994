import { LAND_MARKET_STATUS_OPTIONS, LAND_MARKET_STATUSES_CLASSES, MARKET_STATUSES } from 'utils/enums';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './PlacardStatusPill.scss';
import cx from 'classnames';

export default function PlacardStatusPill({
  status,
  salesDate,
  isFreeListing,
  isMobileDevice
}: {
  status: number;
  salesDate: string;
  isFreeListing: boolean;
  isMobileDevice: boolean;
}) {
  const statusStyling = LAND_MARKET_STATUSES_CLASSES[status];
  const statusText =
    status === MARKET_STATUSES.UNDER_CONTRACT && isFreeListing && isMobileDevice
      ? 'Pending'
      : LAND_MARKET_STATUS_OPTIONS[status];

  let salesDateText = '';
  // Free listings on mobile devices should not show the sales date.
  // Any dates that start with 0 are not valid dates, such as the default C# DateTime value.
  const shouldShowSalesDate =
    !(isFreeListing && isMobileDevice) && status === MARKET_STATUSES.SOLD && salesDate && !salesDate.startsWith('0');
  if (shouldShowSalesDate) {
    salesDateText =
      ' ' +
      new Date(Date.parse(salesDate)).toLocaleDateString('US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
  }

  return (
    <span className={cx(styles['status-pill'], styles[statusStyling])}>
      <span className={cx(styles.dot, styles[statusStyling], isFreeListing && styles.free)} />
      {statusText + salesDateText}
    </span>
  );
}
