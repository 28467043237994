export class GA4Event {
  constructor(data: Partial<GA4Event>) {
    Object.assign(this, data);
  }

  event?: string;
  detailed_event?: string;
  user_data?: GA4UserData;
  ecommerce?: GA4Ecommerce;
  event_data?: GA4EventData;
  page_data?: GA4PageData;
}

export class GA4EventData {
  constructor(data: Partial<GA4EventData>) {
    Object.assign(this, data);
  }

  account_id: string = undefined;
  count_lead_finance: number = undefined;
  count_lead_newsletter_optin: number = undefined;
  global_listing_id: string = undefined;
  file_name: string = undefined;
  home_carousel_navigation: string = undefined;
  home_carousel_click: string = undefined;
  identifier: string = undefined;
  listing_tier: string = undefined;
  photo_number: string = undefined;
  site_listing_id: string = undefined;
  type: string = undefined;
  upgrade_level: string = undefined;
  method: string = undefined;
}

export class GA4UserData {
  constructor(data: Partial<GA4UserData>) {
    Object.assign(this, data);
  }

  user_id: string = undefined;
}

export class GA4PageData {
  constructor(data: Partial<GA4PageData>) {
    Object.assign(this, data);
  }

  account_id: string = undefined;
  brand: string = undefined;
  city: string = undefined;
  country: string = undefined;
  county: string = undefined;
  global_listing_id: string = undefined;
  language: string = undefined;
  listing_tier: string = undefined;
  name: string = undefined;
  page_location: string = undefined;
  page_title: string = undefined;
  parent_account_id: string = undefined;
  region: string = undefined;
  search_action: string = undefined;
  search_activities: string = undefined;
  search_facets: string = undefined;
  search_keyword: string = undefined;
  search_listing_age: string = undefined;
  search_listing_sale_type: string = undefined;
  search_listing_status: string = undefined;
  search_max_acres: string = undefined;
  search_max_bathrooms: string = undefined;
  search_max_bedrooms: string = undefined;
  search_max_price: string = undefined;
  search_max_square_feet: string = undefined;
  search_min_acres: string = undefined;
  search_min_bathrooms: string = undefined;
  search_min_bedrooms: string = undefined;
  search_min_price: string = undefined;
  search_min_square_feet: string = undefined;
  search_property_type: string = undefined;
  search_radius: string = undefined;
  search_residence: string = undefined;
  site_listing_id: string = undefined;
  site_name: string = undefined;
  site_section: string = undefined;
  state: string = undefined;
  type: string = undefined;
  upgrade_level: string = undefined;
  zipcode: string = undefined;
}

export class GA4Ecommerce {
  constructor(data: Partial<GA4Ecommerce>) {
    Object.assign(this, data);
  }

  currency: string = undefined;
  item_list_id: string = undefined;
  item_list_name: string = undefined;
  items: GA4EcommerceItem[] = undefined;
}

export class GA4EcommerceItem {
  constructor(data: Partial<GA4EcommerceItem>) {
    Object.assign(this, data);
  }

  affiliation: string = undefined;
  item_brand: string = undefined;
  item_category: string = undefined;
  item_category2: string = undefined;
  item_category3: string = undefined;
  item_category4: string = undefined;
  item_id: string = undefined;
  item_name: string = undefined;
  item_variant: string = undefined;
  value: string = undefined;
}
