import { Fragment } from 'react';
import LocationDisplayButton from '../LocationDisplayButton';
import { SiteIdentity } from 'store/commonInterfaces/iSearchObjects';
import Tag from 'components/ui/Input/Tags/Tag';
import GlobalHandler from 'handler/globalHandler';
import { SITE_IDS } from 'utils/enums';
import Restricted from 'components/ui/Restricted';
import { BreakPoints } from 'utils/variables';
import cx from 'classnames';
import styles from './SearchBarShell.scss';

/* 
  The searchBar component contains a lot of javascript, making it a large file.  The searchBar is also
  one of the largest items in the intial viewport of our home page.  So we want to ensure it's UI
  is rendered to the screen quickly.  The shell allows us to render the searchBar UI quickly by allowing us
  to delay rendering of the full search bar.
*/

interface Props {
  handler: GlobalHandler;
  siteIdentity: SiteIdentity;
  placeholder: string;
  isHomeRoute: boolean;
  searchUI: any;
  windowWidth: number;
}

const SearchBarShell = (props: Props) => {
  const { siteIdentity, placeholder, isHomeRoute, searchUI, windowWidth } = props;
  const { locationDisplayValueArray } = searchUI;

  return (
    <div className={styles['desktop-container']}>
      <div
        className={cx(
          styles['search-container'],
          styles['search-bar-collapsed'],
          !isHomeRoute && styles['non-home-search-bar-styles']
        )}
      >
        <LocationDisplayButton isHomeRoute={isHomeRoute} siteIdentity={siteIdentity}>
          {locationDisplayValueArray.length > 0 ? (
            <Tag showX={false}> {locationDisplayValueArray[0]}</Tag>
          ) : (
            placeholder
          )}
        </LocationDisplayButton>

        {windowWidth >= BreakPoints.medium && (
          <Fragment>
            <div className={cx(styles.range, styles['search-button'])}>Search</div>
            <Restricted to={SITE_IDS.LAND}>
              <Fragment>
                <div className={styles.range}>
                  <div className={styles['range-content']}>
                    <span className={styles.text}>Price</span>
                    <span className={styles.arrow} />
                  </div>
                </div>
                <div className={styles.range}>
                  <div className={styles['range-content']}>
                    <span className={styles.text}>Acres</span>
                    <span className={styles.arrow} />
                  </div>
                </div>
              </Fragment>
            </Restricted>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default SearchBarShell;
