import { HomepageCarouselSlideData } from './HomeCarousel';

export const getSlides = (
  slideData: HomepageCarouselSlideData[],
  imagesPerSlide: number,
  getSlideImage: (slides: HomepageCarouselSlideData[]) => JSX.Element[]
): JSX.Element[][] => {
  const slideJsx: JSX.Element[][] = [];
  for (let i = 0; i < slideData.length; i += 1) {
    // If imagesPerSlide = 3 then add a slide every 3 images, imagesPerSlide = 2 then add a slide every 2 images, etc.
    if (i % imagesPerSlide === 0) {
      const slideDataToAdd = slideData.slice(i, i + imagesPerSlide);
      const slideImage = getSlideImage(slideDataToAdd);
      slideJsx.push(slideImage);
    }
  }

  return slideJsx;
};

export const getLastSegmentOfPath = (path: string): string => {
  const urlParts = path.split('/');
  return urlParts[urlParts.length - 2]; // Slide data search URLs have trailing slash
};
