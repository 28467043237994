import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import styles from './Button.scss';

export type ButtonProps = Partial<{
  id: string;
  className: string;
  children: React.ReactNode;
  label: string;
  tabIndex: number;
  isPrimary: boolean;
  isSecondary: boolean;
  isInverted: boolean;
  disabled: boolean;
  isCircle: boolean;
  isExpander: boolean;
  isGradient: boolean;
  isTertiary: boolean;
  to: string;
  type: string;
  href?: string;
  newTab?: boolean;
  ref: any;
  onKeyDown?(event: React.KeyboardEvent);
  onClick?(event: React.MouseEvent);
}>;

const Button: React.FunctionComponent<ButtonProps> = props => {
  const {
    id,
    className,
    label,
    children,
    isPrimary,
    isSecondary,
    isInverted,
    isCircle,
    isGradient,
    isTertiary,
    ref,
    href,
    newTab,
    ...otherProps
  } = props;

  const isLink = !!props.to;
  const component = isLink ? Link : href ? 'a' : 'button';

  const componentProps = {
    className: cx(
      styles.button,
      {
        [styles['is-primary']]: isPrimary,
        [styles['is-secondary']]: isSecondary,
        [styles['is-inverted']]: isInverted,
        [styles['is-circle']]: isCircle,
        [styles['is-gradient']]: isGradient,
        [styles['is-tertiary']]: isTertiary
      },
      className
    ),
    ref: ref,
    id: id,
    target: newTab ? '_blank' : null,
    href: href || null,
    'aria-label': label,
    ...otherProps
  };

  return React.createElement(component, componentProps, children);
};

export default Button;
