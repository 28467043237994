import React, { Fragment } from 'react';
import { retry } from 'utils/retry';
import MapOrHero from '../MapOrHero';
import { LandingPage } from 'ssr/hydrationData';
import cx from 'classnames';
import Restricted from 'components/ui/Restricted';
import { SITE_IDS } from 'utils/enums';
import loadable from '@loadable/component';
import ShowcaseWithDynamicAds from '../ShowcaseWithDynamicAds';
import LAFAmenityLinks from '../LAFAmenityLinks';
import styles from './HomeLWLAF.scss';
import { SharedHomeLandingProps } from '../interfaces';
import { getFeatureFlagBySite } from 'utils/convenience';
import LazyLoad from 'react-lazyload';

interface State {
  propertyCarousel: any;
  propertyTypeGrid: any;
}

class HomeLWLAF extends React.Component<SharedHomeLandingProps, State> {
  static AddListing: any = null;
  static AdvertiseWithUs: any = null;

  constructor(props) {
    super(props);

    this.state = {
      propertyCarousel: null,
      propertyTypeGrid: null
    };
  }

  importModulesOnScroll = () => {
    if (!HomeLWLAF.AdvertiseWithUs) {
      HomeLWLAF.AdvertiseWithUs = loadable(() =>
        retry(
          () =>
            import(
              /* webpackChunkName: "AdvertiseWithUs" */ 'components/pages/Home/MarketingContent/LAFandLW/AdvertiseWithUs'
            )
        )
      );
    }

    if (!HomeLWLAF.AddListing) {
      HomeLWLAF.AddListing = loadable(() =>
        retry(
          () =>
            import(/* webpackChunkName: "AddListing" */ 'components/pages/Home/MarketingContent/LAFandLW/AddListing')
        )
      );
    }

    // Force another render to render these newly loaded components.
    this.forceUpdate();
  };

  dynamicImports() {
    const { isLAF, isLW } = this.props.store.siteIdentity;

    if (isLAF && this.state.propertyTypeGrid === null) {
      retry(() => import(/* webpackChunkName: "PropertyTypeGrid" */ '../../../ui/PropertyTypeGrid')).then(component =>
        this.setState(() => ({ propertyTypeGrid: component.default }))
      );
    }

    if (isLW && this.state.propertyCarousel === null) {
      retry(() => import(/* webpackChunkName: "PropertyTypeCarousel" */ '../../../ui/PropertyTypeCarousel')).then(
        component => this.setState(() => ({ propertyCarousel: component.default }))
      );
    }
  }

  componentDidMount() {
    getFeatureFlagBySite('show_placard_carousel_on_homepage').then((flagValue: boolean) => {
      const { featureFlags } = this.props.store;
      if (featureFlags.showPlacardCarouselonHomePage != flagValue) {
        const newFeatureFlags = { ...featureFlags, showPlacardCarouselonHomePage: flagValue };
        this.props.handler.app.setState({ featureFlags: newFeatureFlags });
      }
    });

    this.dynamicImports();

    window.addEventListener('scroll', this.importModulesOnScroll, { once: true, capture: true });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.importModulesOnScroll, true);
  }

  render() {
    const { propertyCarousel: PropertyCarousel, propertyTypeGrid: PropertyTypeGrid } = this.state;

    const {
      store,
      handler,
      landingPage,
      loading,
      showcaseListings,
      onDynamicAdsFinishedRendering,
      homePageType,
      history
    } = this.props;
    const { paginationData, seoTextSection, carouselCounts, routeContext } = landingPage as LandingPage;
    const { pageHeader, pageSubHeader } = paginationData;

    return (
      <Fragment>
        <div role="main" className={cx('inline-home-main', styles.main)}>
          <section data-testid="MapOrHero" className={cx(styles['hero-section'])}>
            <MapOrHero
              store={store}
              handler={handler}
              pageHeader={pageHeader}
              pageSubHeader={pageSubHeader}
              stateName={landingPage.stateName}
            />
          </section>

          {!loading && (
            <Fragment>
              <Restricted to={[SITE_IDS.LANDWATCH]}>
                <section className={styles.section}>
                  <ShowcaseWithDynamicAds
                    store={store}
                    handler={handler}
                    homePageType={homePageType}
                    showcaseListings={showcaseListings}
                    adTargeting={landingPage.adTargeting}
                    onAdsFinishedRendering={onDynamicAdsFinishedRendering}
                  />
                </section>
                {carouselCounts.length > 0 && this.state.propertyCarousel && (
                  <section data-testid="property-carousel" className={styles.carousel}>
                    <PropertyCarousel
                      locationName={routeContext.locationName}
                      windowWidth={store.windowWidth}
                      isHomeCarousel={true}
                      carouselCounts={carouselCounts}
                      siteId={store.siteId}
                    />
                  </section>
                )}
              </Restricted>
              <Restricted to={[SITE_IDS.LANDANDFARM]}>
                <section className={styles.section}>
                  <ShowcaseWithDynamicAds
                    store={store}
                    handler={handler}
                    homePageType={homePageType}
                    showcaseListings={showcaseListings}
                    adTargeting={landingPage.adTargeting}
                    onAdsFinishedRendering={onDynamicAdsFinishedRendering}
                  />
                </section>
                <section className={cx(styles.section, styles['property-grid-container'])}>
                  {carouselCounts.length > 0 && this.state.propertyTypeGrid && (
                    <PropertyTypeGrid carouselCounts={carouselCounts} />
                  )}
                </section>
                <section className={styles.section}>
                  <LAFAmenityLinks />
                </section>
              </Restricted>

              <LazyLoad>
                <section className={styles['advertise-section']}>
                  {HomeLWLAF.AdvertiseWithUs && <HomeLWLAF.AdvertiseWithUs store={store} />}
                </section>
              </LazyLoad>

              <section className={styles['add-listing-section']}>
                {HomeLWLAF.AddListing && (
                  <HomeLWLAF.AddListing
                    store={store}
                    handler={handler}
                    history={history}
                    seoTopText={seoTextSection.seoText}
                  />
                )}
              </section>
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}

export default HomeLWLAF;
