import React from 'react';
import { PropertyListing, SiteIdentity } from 'store/commonInterfaces/iSearchObjects';
import { LISTING_LEVELS } from 'utils/enums';
import ga4 from 'utils/GA4';
import { BreakPoints, MobileLargeWidth } from 'utils/variables';
import { isNullOrUndefined } from 'utils/lodash';

export enum PlacardLocation {
  Home,
  Landing,
  SearchResult,
  ListingDetail,
  SimilarResult
}

export function getLevelName(level: LISTING_LEVELS): string {
  if (level === LISTING_LEVELS.HOMES) {
    // treat Homes listings as standard listings
    return LISTING_LEVELS[LISTING_LEVELS.STANDARD].toLowerCase();
  }

  return LISTING_LEVELS[level].toLowerCase();
}

// This function returns the type of Placard to display.
export const getPlacardType = (listing, placardLocation, isMapClickCard = false) => {
  const { isPlatinum, isDiamond, isShowcase, isGold, listingLevel, isCourtesy } = listing;
  const isSimilarResult = placardLocation === PlacardLocation.SimilarResult;
  const isSearchResultPage = placardLocation == PlacardLocation.SearchResult;

  if (isSearchResultPage && !isSimilarResult) {
    if (isMapClickCard) return listingLevel === LISTING_LEVELS.FREE ? 'free-map-click-card' : 'showcase-map-click-card';
    if (isDiamond) return 'diamond';
    if (isPlatinum) return 'platinum';
    if (isGold) return 'gold';
    if (isShowcase) return 'showcase';
    if (isCourtesy) return 'free';
    return getLevelName(listingLevel);
  } else {
    return 'default';
  }
};

export const getPlacardLocation = (isLanding, isHome, isListingDetailsPage, isSearchResults, isSimilarResult) => {
  let location;

  if (isLanding) location = PlacardLocation.Landing;
  if (isHome) location = PlacardLocation.Home;
  if (isListingDetailsPage) location = PlacardLocation.ListingDetail;
  if (isSearchResults) location = PlacardLocation.SearchResult;
  if (isSimilarResult) location = PlacardLocation.SimilarResult;

  return location;
};

// This function returns a boolean flag for
// VIP listing levels that allow all placard component features.
// Ie. CTA icons, Broker Infomation, and Descriptions.
// It serves as a gate to render or remove certain sections of the placard
export function checkHasAllFeaturesAllowed({
  isPlatinum,
  isShowcase,
  isGold,
  listingLevel,
  isDiamond
}: Partial<PropertyListing>) {
  const { SIGNATURE } = LISTING_LEVELS;
  const hasAllFeaturesAllowed = isDiamond || isPlatinum || isShowcase || isGold || listingLevel === SIGNATURE;
  return hasAllFeaturesAllowed;
}

export function checkHasAgentImage(listing: Partial<PropertyListing>) {
  const { PREMIUM } = LISTING_LEVELS;
  const hasAllFeaturesAllowed = checkHasAllFeaturesAllowed(listing);
  const hasAgentImage = hasAllFeaturesAllowed || listing.listingLevel === PREMIUM;
  return hasAgentImage;
}

export function tagQa(key, val) {
  return { [`data-qa-${key}`]: val };
}

export function OnlyShow({
  when,
  children
}: {
  children: React.ReactElement<any>;
  when: boolean;
}): React.ReactElement<any> {
  return when ? <>{children}</> : null;
}

export const getDiamondType = (isTabletDevice, isMobileDevice, windowWidth, isHome = false) => {
  if (
    isTabletDevice ||
    (!isNullOrUndefined(windowWidth) && windowWidth > MobileLargeWidth && windowWidth <= BreakPoints.listViewMapView)
  ) {
    return windowWidth > BreakPoints.small ? 'diamondTablet' : 'diamondSmallTablet';
  } else if (isMobileDevice || (!isNullOrUndefined(windowWidth) && windowWidth < MobileLargeWidth)) {
    return 'diamondMobile';
  }

  // If we're on the home page and its not mobile or tablet then default to mobile. We're specifically
  // doing it this way because tablet view shows a larger placard than mobile or desktop.
  if (isHome) {
    return 'diamondMobile';
  }

  return 'diamondDesktop';
};

export function logPlacardListingClickedEvent(
  listing: PropertyListing,
  siteIdentity: SiteIdentity,
  placardLocation: PlacardLocation
) {
  let siteLocation;

  if (placardLocation === PlacardLocation.SearchResult) {
    siteLocation = ga4.siteLocations.SEARCH_PAGE;
  } else if (placardLocation === PlacardLocation.Home) {
    siteLocation = ga4.siteLocations.HOMEPAGE;
  } else if (placardLocation === PlacardLocation.Landing) {
    siteLocation = ga4.siteLocations.LANDING_PAGE;
  } else if (placardLocation === PlacardLocation.ListingDetail) {
    siteLocation = ga4.siteLocations.LISTING_DETAIL_PAGE;
  } else {
    siteLocation = ga4.siteLocations.UNKNOWN;
    console.error('Listing clicked from unknown location.');
  }

  ga4.listingEvents.listingResultClicked(listing, siteIdentity, siteLocation);
}

export function Circle({ isShown = true }: { isShown?: boolean }): JSX.Element {
  return isShown ? (
    <span aria-hidden="true" style={{ margin: '0 0.05rem' }}>
      &nbsp;&#x2022;&nbsp;
    </span>
  ) : null;
}
