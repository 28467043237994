import { PropertyListing, SiteIdentity } from 'store/commonInterfaces/iSearchObjects';
import { checkHasAllFeaturesAllowed, logPlacardListingClickedEvent, PlacardLocation } from '../PlacardHelpers';
import { useHistory } from 'react-router-dom';
import GlobalHandler from 'handler/globalHandler';
import styles from './PlacardIcon.scss';

function PlacardIcon(props: {
  handler: GlobalHandler;
  iconText?: string;
  listing: PropertyListing;
  siteIdentity: SiteIdentity;
  placardLocation: PlacardLocation;
  type: 'video' | 'tour' | 'map';
}) {
  const { handler, type, listing, iconText, siteIdentity, placardLocation } = props;
  const { canonicalUrl, relativeUrl } = listing;
  const listingUrl = canonicalUrl || relativeUrl;
  const hasAllFeaturesAllowed = checkHasAllFeaturesAllowed(listing);
  const history = useHistory();

  const label = iconText || 'Property ' + type[0].toUpperCase() + type.substring(1);
  let buttonAction;
  let isVisible;
  let icon;

  const handleMapIcon = (link, handler, history) => {
    handler.handleGlobalUI().then(handleGlobalUI => {
      handleGlobalUI.setUrlHash('MapContainer');
    });
    history.push(link);
  };

  const handleVideoAndTour = (link, isVideo, isTour, handler, history) => {
    handler.handleCarousel.openCarousel({ isVideo, isTour });
    history.push(link);
  };

  switch (type) {
    case 'map':
      buttonAction = () => handleMapIcon(listingUrl, handler, history);
      isVisible = listing.hasCustomMap;
      icon = styles['map-icon'];
      break;
    case 'tour':
      buttonAction = () => handleVideoAndTour(listingUrl, false, true, handler, history);
      isVisible = listing.hasVirtualTour;
      icon = styles['tour-icon'];
      break;
    case 'video':
      buttonAction = () => handleVideoAndTour(listingUrl, true, false, handler, history);
      isVisible = listing.hasVideo;
      icon = styles['video-icon'];
      break;
  }

  isVisible = isVisible && hasAllFeaturesAllowed;

  const onButtonClick = () => {
    logPlacardListingClickedEvent(listing, siteIdentity, placardLocation);
    buttonAction();
  };

  return isVisible ? (
    <span tabIndex={0} role="button" onClick={onButtonClick} aria-label={label} className={icon}>
      {iconText}
    </span>
  ) : null;
}

export default PlacardIcon;
