import LocationRunSearchButton from '../LocationRunSearchButton';
import cx from 'classnames';
import { SiteIdentity } from 'store/commonInterfaces/iSearchObjects';
import { SITE_IDS } from 'utils/enums';
import Restricted from 'components/ui/Restricted';
import styles from './LocationDisplayButton.scss';

interface Props {
  isHomeRoute: boolean;
  to?: string;
  children: any;
  onClick?(param: boolean);
  openLocationBar?(isLocationVisible: boolean);
  siteIdentity: SiteIdentity;
  handleSearch?(event?: Event): void;
  searchPlaceholderText?: string;
}

function LocationDisplayButton({
  isHomeRoute,
  onClick,
  openLocationBar,
  children,
  to,
  siteIdentity,
  handleSearch,
  searchPlaceholderText
}: Props) {
  return (
    <div className={styles['location-display-button-wrap']}>
      <div
        role="button"
        tabIndex={0}
        className={cx(styles['location--button-open'])}
        data-qa-searchlocation
        onClick={() => onClick(true)}
        aria-label={searchPlaceholderText || 'Search'}
      >
        <Restricted to={[SITE_IDS.LANDWATCH, SITE_IDS.LANDANDFARM]}>
          <span className={styles['magnify-icon']} />
        </Restricted>
        {children}
      </div>
      {(((siteIdentity.isLW || siteIdentity.isLAF) && !openLocationBar) || siteIdentity.isLand) && (
        <LocationRunSearchButton
          isHomeRoute={isHomeRoute}
          to={to}
          openLocationBar={() => openLocationBar(false)}
          handleSearch={handleSearch}
        />
      )}
    </div>
  );
}

export default LocationDisplayButton;
