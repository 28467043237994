import React, { Fragment } from 'react';
import MapOrHero from '../MapOrHero';
import loadable from '@loadable/component';
import { LandingPage } from 'ssr/hydrationData';
import { retry } from 'utils/retry';
import cx from 'classnames';
import LandingHeader from '../LandingHeader';
import Showcase from '../Showcase';
import ShowcaseWithDynamicAds from '../ShowcaseWithDynamicAds';
import NearMeModule from '../Land/NearMeModule';
import ActivitiesCarousel from '../ActivitiesCarousel';
import Button from 'components/ui/Button';
import AreaTiles from '../AreaTiles';
import MarketBlurb from '../MarketingContent/Land/MarketBlurb';
import LandMagazineArticleGroup from 'components/common/LandMagazineArticles';
import SeoLinkTabs from '../SeoLinks/SeoLinkTabs';
import { SharedHomeLandingProps } from '../interfaces';
import styles from './HomeLand.scss';
import { HomePageTypes } from 'utils/enums';
import { HomeFavoriteListing } from '../Favorites/HomeFavorites';

interface State {
  isLoading: boolean;
  userHasNoFavorites: boolean;
  favorites: HomeFavoriteListing[];
}

class HomeLand extends React.Component<SharedHomeLandingProps, State> {
  static BrokerDirectory: any = null;
  static FindBuyerSeller: any = null;
  static HomeFavorites: any = null;

  constructor(props: SharedHomeLandingProps) {
    super(props);
    this.state = {
      favorites: [],
      isLoading: false,
      userHasNoFavorites: false
    };
  }

  importModulesOnScroll = () => {
    if (!HomeLand.BrokerDirectory) {
      HomeLand.BrokerDirectory = loadable(() =>
        retry(() => import(/* webpackChunkName: "BrokerDirectory" */ '../BrokerDirectory'))
      );
    }

    if (!HomeLand.FindBuyerSeller) {
      HomeLand.FindBuyerSeller = loadable(() =>
        retry(() => import(/* webpackChunkName: "FindBuyerSeller" */ '../MarketingContent/Land/FindBuyerSeller'))
      );
    }

    if (!HomeLand.HomeFavorites) {
      HomeLand.HomeFavorites = loadable(() =>
        retry(() => import(/* webpackChunkName: "HomeFavorites" */ '../Favorites/HomeFavorites'))
      );
    }

    // Force another render to render these newly loaded components.
    this.forceUpdate();
  };

  getData = async () => {
    const rawResponse = await fetch(`/api/user/getLikedListingsForHomepage/0/${5}`);
    if (rawResponse.ok) {
      if (rawResponse.status === 204) {
        this.setState({ userHasNoFavorites: true });
        return;
      }

      const response = (await rawResponse.json()) as HomeFavoriteListing[];
      this.setState({ favorites: response, isLoading: false });
    } else {
      // Fail silently to prevent taking the whole homepage down in the event of an error.
      this.setState({ favorites: [], isLoading: false });
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.importModulesOnScroll, { once: true, capture: true });
    if (this.props.store.loginInfo.isLoggedIn) {
      this.setState({ isLoading: true });
      this.getData();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.importModulesOnScroll, true);
  }

  componentDidUpdate() {
    if (
      this.props.store.loginInfo.isLoggedIn &&
      !this.state.favorites.length &&
      !this.state.isLoading &&
      !this.state.userHasNoFavorites
    ) {
      this.setState({ isLoading: true });
      this.getData();
    }

    if (!this.props.store.loginInfo.isLoggedIn && this.state.favorites.length) {
      this.setState({ favorites: [] });
    }
  }

  render() {
    const {
      store,
      handler,
      landingPage,
      isLandingPage,
      loading,
      topLeaderBoardAdElement,
      bottomLeaderBoardAdElement,
      farmCreditAdElement,
      listingCardAdElement,
      showcaseListings,
      onDynamicAdsFinishedRendering,
      homePageType
    } = this.props;
    const { paginationData, seoLinkSections, metricsSection, seoTextSection, locationNames, stateName } =
      landingPage as LandingPage;
    const { locationName, relativeUrl, pageHeader, pageSubHeader } = paginationData;
    const isHomePage = homePageType === HomePageTypes.Home;
    const shouldShowFavorites = this.props.store.loginInfo.isLoggedIn && !!this.state.favorites.length;

    return (
      <div>
        <Fragment>
          <div role="main" className={cx('inline-home-main', styles.main)}>
            {isHomePage && (
              <section className={cx(styles['hero-section'])}>
                <MapOrHero
                  store={store}
                  handler={handler}
                  pageHeader={pageHeader}
                  pageSubHeader={pageSubHeader}
                  stateName={stateName}
                />
              </section>
            )}
            {!loading && (
              <Fragment>
                {isLandingPage && (
                  <section className={styles.section}>
                    <LandingHeader
                      metricsSection={metricsSection}
                      seoTextSection={seoTextSection}
                      homePageType={homePageType}
                      locationName={locationName}
                      areaName={locationNames.namePlural}
                      siteId={store.siteId}
                    />
                  </section>
                )}

                {isHomePage ? null : farmCreditAdElement && farmCreditAdElement}

                {showcaseListings && showcaseListings.length && (
                  <section id="showcase-section" className={styles['showcase-section']}>
                    {isLandingPage ? (
                      <Showcase
                        store={store}
                        handler={handler}
                        homePageType={homePageType}
                        showcaseListings={showcaseListings}
                        bannerAd={topLeaderBoardAdElement}
                        listingCardAd={listingCardAdElement}
                      />
                    ) : (
                      <ShowcaseWithDynamicAds
                        store={store}
                        handler={handler}
                        homePageType={homePageType}
                        showcaseListings={showcaseListings}
                        adTargeting={landingPage.adTargeting}
                        onAdsFinishedRendering={onDynamicAdsFinishedRendering}
                      />
                    )}
                  </section>
                )}

                {isHomePage && (
                  <Fragment>
                    <section className={cx(styles.section, styles['popular-land-container'])}>
                      <NearMeModule windowWidth={store.windowWidth} />
                    </section>
                    <section className={cx(styles.section, styles['activities-container'])}>
                      <ActivitiesCarousel windowWidth={store.windowWidth} />
                    </section>
                    {shouldShowFavorites && HomeLand.HomeFavorites && (
                      <section className={cx(styles.section, styles['favorites-container'])}>
                        <HomeLand.HomeFavorites
                          favorites={this.state.favorites}
                          imageDomain={store.imageDomain}
                          windowWdith={store.windowWidth}
                          isMobileDevice={store.isMobileDevice}
                        />
                      </section>
                    )}
                  </Fragment>
                )}

                {isLandingPage && (
                  <Fragment>
                    <Button className={styles['view-results-button']} isPrimary to={`/${relativeUrl}/all-land/`}>
                      <div>View All Listings</div>
                    </Button>
                    <section className={styles.section}>
                      {HomeLand.BrokerDirectory && (
                        <HomeLand.BrokerDirectory url={store.routing.brokerDirectory.link} />
                      )}
                    </section>
                    {bottomLeaderBoardAdElement && (
                      <section className={cx(styles.section)}>{bottomLeaderBoardAdElement}</section>
                    )}

                    {seoLinkSections &&
                      seoLinkSections.length > 0 &&
                      seoLinkSections.map(item => (
                        <section key={item.type} className={styles.section}>
                          <AreaTiles
                            title={item.header}
                            areaCollection={item.links}
                            areaType={item.type}
                            viewAllLink={item.viewAll}
                          />
                        </section>
                      ))}
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>

          {isHomePage && (
            <>
              <div className={styles['marketing-container']}>
                <section className={styles['marketing-section']}>
                  <LandMagazineArticleGroup
                    landMagazineArticles={landingPage.landMagazineArticles}
                    imageDomain={store.imageDomain}
                    imageLocation="top"
                  />
                </section>
                <section className={styles['marketing-section']}>
                  <div className={styles['marketing-blurbs']}>
                    <MarketBlurb
                      seoTopHeader={seoTextSection.header}
                      seoTopText={seoTextSection.seoText}
                      seoTopFooter={seoTextSection.footer}
                    />

                    {HomeLand.FindBuyerSeller && (
                      <HomeLand.FindBuyerSeller brokerUrl={store.routing.brokerDirectory.link} />
                    )}
                  </div>
                </section>
              </div>
              <section className={styles['home-link-section']}>
                <SeoLinkTabs seoLinkSections={seoLinkSections} />
              </section>
            </>
          )}
        </Fragment>
      </div>
    );
  }
}

export default HomeLand;
