import { Fragment } from 'react';
import { HomePageTypes, SITE_IDS } from 'utils/enums';
import { SeoTextSection } from 'ssr/hydrationData';
import { Metric } from 'store/commonInterfaces/HydrationData';
import StatTile from '../StatTile';
import styles from './LandingHeader.scss';

interface Props {
  homePageType: HomePageTypes;
  locationName: string;
  seoTextSection: SeoTextSection;
  metricsSection: Metric[];
  areaName: string;
  siteId: SITE_IDS;
}

function LandingHeader({ homePageType, locationName, seoTextSection, metricsSection, areaName, siteId }: Props) {
  const { seoText, header } = seoTextSection;
  return seoText ? (
    <Fragment>
      <h1 data-testid={`${siteId}-${locationName}`} className={styles.header}>
        {header}
      </h1>
      <div className={styles.overview}>
        <div
          className={styles.seo}
          dangerouslySetInnerHTML={{ __html: `${seoText.replace(/https:\/\/www.land.com/gi, '')}` }}
        />
      </div>
      <div className={styles.stats}>
        {metricsSection &&
          metricsSection.map &&
          metricsSection.map(item => (
            <StatTile
              key={item.name}
              value={item.value}
              name={item.name}
              count={item.count}
              // Look into the below
              // below line is a quickfix for 839040, a second bug to throw out DC and PR will be created and then this can be simplified
              rank={homePageType === HomePageTypes.Landing_State ? (item.rank > 50 ? 50 : item.rank) : item.rank}
              areaName={areaName}
            />
          ))}
      </div>
    </Fragment>
  ) : null;
}

export default LandingHeader;
