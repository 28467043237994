import { Component } from 'react';
import GlobalHandler from 'handler/globalHandler';
import GlobalStore from 'store/globalStore';
import { retry } from 'utils/retry';
import LandHero from 'components/pages/Home/LandHero';
import Hero from 'components/pages/Home/Hero';

interface State {
  mapSearch: any;
  showMapSearch: boolean;
}

interface Props {
  store: GlobalStore;
  handler: GlobalHandler;
  pageHeader: string;
  pageSubHeader: string;
  stateName: string;
}

class MapOrHero extends Component<Props, State> {
  state = {
    mapSearch: null,
    showMapSearch: false
  };

  toggleMapSearch = () => {
    if (!this.state.mapSearch) {
      retry(() => import(/* webpackChunkName: "HomeSearchMap" */ 'components/common/HomeSearchMap/HomeSearchMap')).then(
        component =>
          this.setState(() => ({
            mapSearch: component.default,
            showMapSearch: true
          }))
      );
    } else {
      this.setState(() => ({
        showMapSearch: !this.state.showMapSearch
      }));
    }
  };

  render() {
    const { mapSearch: MapSearch } = this.state;
    const { store, handler, pageHeader, pageSubHeader, stateName } = this.props;
    const { isLand } = store.siteIdentity;

    if (this.state.showMapSearch) {
      return <MapSearch toggleMapUI={this.toggleMapSearch} siteIdentity={store.siteIdentity} />;
    }

    if (isLand) {
      return <LandHero store={store} handler={handler} pageHeader={pageHeader} pageSubHeader={pageSubHeader} />;
    }

    return (
      <Hero
        store={store}
        handler={handler}
        pageHeader={pageHeader}
        pageSubHeader={pageSubHeader}
        stateName={stateName}
        toggleMapUI={this.toggleMapSearch}
      />
    );
  }
}

export default MapOrHero;
