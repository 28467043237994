import styles from './LoadingIndicator.scss';
import { SITE_IDS } from 'utils/enums';
import Restricted from 'components/ui/Restricted';

const LoadingIndicator = props => {
  const { message } = props;

  return (
    <div>
      <Restricted to={SITE_IDS.LAND}>
        <img className={styles['loading-indicator']} src="/assets/images/logo-no-text.svg" alt="loading-indicator" />
      </Restricted>
      {message.length > 0 && <p className={styles['loading-indicator-text']}>{message}</p>}
    </div>
  );
};

export default LoadingIndicator;
