import { PropertyListing } from 'store/commonInterfaces/iSearchObjects';
import Button from 'components/ui/Button';
import styles from './DiamondPlacardCTA.scss';

export default function DiamondPlacardCTA(props: {
  listing: PropertyListing;
  brokerPhoneNumberFormatted: string;
  handleContactButton: () => void;
  handlePhoneClick: () => void;
}) {
  const { listing, brokerPhoneNumberFormatted, handleContactButton, handlePhoneClick } = props;
  return (
    <div className={styles['action-buttons']}>
      <a
        href={brokerPhoneNumberFormatted}
        className={styles['contact-button']}
        data-qa-contact={`${listing.siteListingId}-call`}
      >
        <Button className={styles['contact-button-text']} id={`call-${listing.id}`} onClick={handlePhoneClick}>
          Call
        </Button>
      </a>
      <div
        tabIndex={0}
        className={styles['contact-button']}
        data-qa-contact={`${listing.siteListingId}-message`}
        onClick={handleContactButton}
        role="button"
        aria-label="Message"
      >
        <Button className={styles['contact-button-text']} id={`message-${listing.id}`}>
          Message
        </Button>
      </div>
    </div>
  );
}
