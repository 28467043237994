export interface AdInputEventMessageData {
  type: 'MouseEvent' | 'TouchEvent';
  data: MouseEventData | TouchEventData;
}

interface EventData {
  bubbles: boolean;
  cancelable: boolean;
  defaultPrevented: boolean;
  eventPhase: number;
  type: string;
}

export interface MouseEventData extends EventData {
  altKey: boolean;
  button: number;
  buttons: number;
  clientX: number;
  clientY: number;
  ctrlKey: boolean;
  metaKey: boolean;
  movementX: number;
  movementY: number;
  offsetX: number;
  offsetY: number;
  pageX: number;
  pageY: number;
  screenX: number;
  screenY: number;
  shiftKey: boolean;
  x: number;
  y: number;
}

interface TouchData {
  clientX: number;
  clientY: number;
  force: number;
  identifier: number;
  pageX: number;
  pageY: number;
  radiusX: number;
  radiusY: number;
  rotationAngle: number;
  screenX: number;
  screenY: number;
}

export interface TouchEventData extends EventData {
  altKey: boolean;
  changedTouches: TouchData[];
  ctrlKey: boolean;
  metaKey: boolean;
  shiftKey: boolean;
  targetTouches: TouchData[];
  touches: TouchData[];
}

export function getIframeInputEventMessageData(data: any): AdInputEventMessageData | undefined {
  if (!data || !data.type || !data.data) {
    return undefined;
  }

  const isMouse = !!data.data.clientX;
  const isTouch = !!data.data.touches;
  if (isMouse || isTouch) {
    return data as AdInputEventMessageData;
  }
  return undefined;
}

export function adInputDataToTouchEvent(data: TouchEventData, target: EventTarget, offset: DOMRectReadOnly) {
  const toTouch = (td: TouchData) =>
    new Touch({
      ...td,
      clientX: td.clientX + offset.x,
      clientY: td.clientY + offset.top,
      target: target
    });

  const touchEventData = data as TouchEventData;
  const init = {
    bubbles: touchEventData.bubbles,
    cancelable: touchEventData.cancelable,

    altKey: touchEventData.altKey,
    changedTouches: touchEventData.changedTouches.map(toTouch),
    ctrlKey: touchEventData.ctrlKey,
    metaKey: touchEventData.metaKey,
    shiftKey: touchEventData.shiftKey,
    targetTouches: touchEventData.targetTouches.map(toTouch),
    touches: touchEventData.touches.map(toTouch)
  };
  return new TouchEvent(data.type, init);
}
