import { LeadSource, LISTING_LEVELS } from 'utils/enums';
import GlobalStore from 'store/globalStore';
import GlobalHandler from 'handler/globalHandler';
import ListingLogInfo from 'store/models/listingLogInfo';
import { PropertyListing, SiteIdentity } from 'store/commonInterfaces/iSearchObjects';
import ga4 from 'utils/GA4';
import {
  ImageUrl,
  GetImageSources,
  PropertyCardImageSources,
  ImageClass,
  noPhotoProvided,
  ImageSource,
  BreakPoints,
  MobileLargeWidth
} from 'utils/variables';
import { getDiamondType } from 'components/common/Property/Placard/PlacardHelpers';

export interface PropertyCardProps {
  handler: GlobalHandler;
  history: any;
  isExpanded?: boolean;
  isGrid?: boolean;
  isHome?: boolean;
  isLanding?: boolean;
  isListingDetailsPage?: boolean;
  isLazy?: boolean;
  isLiked?: boolean;
  isListView?: boolean;
  isMailed?: boolean;
  isPlacard?: boolean;
  isPromoted?: boolean;
  isSearchResults?: boolean;
  isSimilarResult?: boolean;
  listing: PropertyListing;
  loading?: boolean;
  markupStyle?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  showSchema?: boolean;
  store: GlobalStore;
  hideEnvelope?: boolean;
  ga4Eventfn?: () => void;
}

export const isPromoted = (listing: PropertyListing): boolean => {
  return listing.isGold || listing.isPlatinum || listing.isShowcase;
};

export interface PropertyCardImageData {
  imageSrc: string;
  imageSources: Array<ImageSource>;
}

export const getPropertyCardImageData = (
  props: PropertyCardProps,
  imageDomain: string,
  isMobileDevice: boolean,
  isTabletDevice: boolean,
  windowWidth: number,
  siteId: SiteIdentity
): PropertyCardImageData => {
  const { listing, isPlacard, isHome, isSimilarResult } = props;
  const documentId = listing.thumbnailDocumentId || noPhotoProvided;

  if (isPlacard) {
    let imageClass = null;
    if (isSimilarResult) {
      imageClass = getSimilarResultImageClass(windowWidth);
    } else if (isHome) {
      imageClass = getHomeShowcasePlacardImageClass(windowWidth, siteId);
    } else if (listing.isDiamond) {
      const diamondType = getDiamondType(isTabletDevice, isMobileDevice, windowWidth, isHome);
      imageClass = ImageClass[diamondType];
    } else if (windowWidth < BreakPoints.small && listing.listingLevel !== LISTING_LEVELS.FREE) {
      imageClass = windowWidth < MobileLargeWidth ? ImageClass.placardMediumColumn : ImageClass.placardLargeColumn;
    } else {
      const isUpgradedListing = listing.isShowcase;
      imageClass = listing.isPlatinum
        ? ImageClass.platinum
        : listing.isGold
        ? ImageClass.gold
        : isUpgradedListing
        ? ImageClass.showcase
        : listing.listingLevel === LISTING_LEVELS.SIGNATURE
        ? ImageClass.signature
        : listing.listingLevel === LISTING_LEVELS.PREMIUM
        ? ImageClass.premium
        : listing.listingLevel === LISTING_LEVELS.STANDARD || listing.listingLevel === LISTING_LEVELS.HOMES
        ? ImageClass.standard
        : ImageClass.free;
    }

    const { fallbackUrl, sources } = GetImageSources(imageClass, imageDomain, documentId);
    return {
      imageSrc: fallbackUrl,
      imageSources: sources
    };
  }

  return {
    imageSrc: ImageUrl(ImageClass.tablet, imageDomain, documentId),
    imageSources: PropertyCardImageSources(imageDomain, documentId)
  };
};

const getSimilarResultImageClass = (windowWidth: number): ImageClass => {
  if (windowWidth < MobileLargeWidth) {
    return ImageClass.placardMediumColumn;
  }

  if (windowWidth < BreakPoints.small) {
    return ImageClass.placardLargeColumn;
  }

  if (windowWidth < BreakPoints.medium) {
    return ImageClass.placardHugeColumn;
  }

  return ImageClass.placardMediumColumn;
};

const getHomeShowcasePlacardImageClass = (windowWidth: number, siteId: SiteIdentity): ImageClass => {
  if (windowWidth < MobileLargeWidth) {
    return ImageClass.placardMediumColumn;
  }

  if ((windowWidth < BreakPoints.small && siteId.isLand) || (windowWidth < BreakPoints.medium && !siteId.isLand)) {
    return ImageClass.placardLargeColumn;
  }

  if (windowWidth < BreakPoints.mediumLarge) {
    return ImageClass.homeShowcaseTwoColumns;
  }

  return ImageClass.homeShowcaseFourColumns;
};

export const getViewedListings = async (inventoryIds: string[]) => {
  const response = await fetch(`/api/Property/getviewedlistings/${inventoryIds.join('-')}`, {
    method: 'get'
  });
  const responseJSON = await response.json();
  return responseJSON;
};
export const handlePropertyLikeToggle = async (listing: PropertyListing, loginInfo: any, handler: GlobalHandler) => {
  const { listOfLiked } = loginInfo.userInfo;
  const likeAction = listOfLiked.includes(listing.id) ? 'unlike' : 'like';

  if (loginInfo.isLoggedIn) {
    const handleSearchUI = await handler.handleSearchUI();
    handleSearchUI.likeUnlikeHandler(listing, listing.id, likeAction);
  } else {
    const userAuthentication = await handler.handleUserAuthentication();
    userAuthentication.openLoginModal(true, async () => {
      const searchUI = await handler.handleSearchUI();
      searchUI.likeUnlikeHandler(listing, listing.id, likeAction);
    });
  }
};

export const handleContactButton = (props: PropertyCardProps, ga4Eventfn?: () => void) => {
  const { listing, store, markupStyle, handler, isHome, isLanding } = props;
  const { siteId } = store;
  const { accountId, stateId, countyId, id, siteListingId, externalSourceId, partnerId, price, listingLevel, acres } =
    listing;
  const listingUpgradeLevel = ga4.getUpgradeLevelString(listing.upgradeLevelId);
  const url = window.location.pathname.toLocaleLowerCase();
  const isBrokerSearch =
    // test for Loa/Land broker search
    url.indexOf('/member/') !== -1 ||
    // test for landwatch broker search
    url.indexOf('/profile/') !== -1 ||
    // test for land and farm broker search
    url.indexOf('/broker/') !== -1;
  const leadSource = isBrokerSearch
    ? LeadSource.BrokerProfile
    : isHome
    ? LeadSource.ShowcaseHome
    : isLanding
    ? LeadSource.ShowcaseLanding
    : LeadSource.SearchResults;

  handler.handleBroker().then(handleBroker => {
    handleBroker.loadContactBrokerModal({
      siteId: siteId,
      leadSource: leadSource,
      brokerId: accountId,
      stateId: stateId,
      countyId: countyId,
      listingId: id,
      siteListingId: siteListingId,
      externalSourceId: externalSourceId,
      partnerId: partnerId,
      price: price,
      acres: acres,
      showCheckboxes: true,
      listingLogInfo: ListingLogInfo.ListingToLogInfo(listing),
      markupStyle: markupStyle,
      listingLevel: listingLevel,
      listingUpgradeLevel: listingUpgradeLevel,
      propertyTitle: listing.title,
      ga4Eventfn: ga4Eventfn
    });
  });
};
