import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import GlobalHandler from 'handler/globalHandler';
import GlobalStore from 'store/globalStore';
import Search from 'components/pages/PropertySearch/Search';
import cx from 'classnames';
import { splitTextIntoTwoLines } from 'utils/format';
import styles from './LandHero.scss';

interface Props {
  store: GlobalStore;
  handler: GlobalHandler;
  pageHeader: string;
  pageSubHeader: string;
}

function LandHero({ store, handler, pageHeader, pageSubHeader }: Props) {
  const pageHeaderLines = splitTextIntoTwoLines(pageHeader);
  const pageSubHeaderLines = splitTextIntoTwoLines(pageSubHeader);

  const imageSizes: string[] = ['375', '600', '900', '990', '1024', '1400', '1600', '1800', '2560'];
  const pathToAsset = (assetName: string): string => {
    const folderName = 'land-hero';
    return `/assets/images/land/${folderName}/${assetName}`;
  };

  const getMediaString = (imageSizeIndex: number) => {
    return imageSizeIndex === 0
      ? `(max-width: ${imageSizes[imageSizeIndex]}px)`
      : imageSizeIndex === imageSizes.length - 1
      ? `(min-width: ${imageSizes[imageSizeIndex - 1]}px)`
      : `(min-width: ${imageSizes[imageSizeIndex - 1]}px) and (max-width: ${imageSizes[imageSizeIndex]}px)`;
  };

  const getImageLink = (size: string, index: number): JSX.Element => {
    return (
      <link
        key={index}
        rel="preload"
        as="image"
        href={pathToAsset(`land-hero-${size}.webp`)}
        media={getMediaString(index)}
      />
    );
  };

  const getImageSource = (size: string, index: number, format: string, fileSuffix?: string): JSX.Element => {
    return (
      <source
        key={index}
        type={`image/${format}`}
        media={getMediaString(index)}
        srcSet={pathToAsset(`land-hero-${size}.${fileSuffix ?? format}`)}
      />
    );
  };

  return (
    <Fragment>
      <Helmet>{imageSizes.map(getImageLink)}</Helmet>
      <section className={styles['hero-container']}>
        <picture>
          {imageSizes.map((size, index) => getImageSource(size, index, 'webp'))}
          {imageSizes.map((size, index) => getImageSource(size, index, 'jpeg', 'jpg'))}
          <img className={styles['hero-image']} src={pathToAsset('land-hero-1600.jpg')} alt="Land for sale near me" />
        </picture>
        <div className={styles['content-area']}>
          <header className={styles.headers}>
            <div data-testid="main-header" className={styles.mainheader}>
              {pageHeaderLines.map((line, id) => (
                <span key={id} className={cx(styles.mainheader, styles.avoidwrap)}>
                  {line}
                </span>
              ))}
            </div>
            <h1 data-testid="sub-header" className={styles.subheader}>
              {pageSubHeaderLines.map((line, id) => (
                <span key={id} className={cx(styles.subheader, styles.avoidwrap)}>
                  {line}
                </span>
              ))}
            </h1>
          </header>
          <div className={styles['search-bar']}>
            <Search isHomeRoute={true} handler={handler} store={store} />
          </div>
        </div>
        <div className={styles['dark-overlay']}></div>
      </section>
    </Fragment>
  );
}
export default LandHero;
