import { Fragment, Component } from 'react';
import { PropertyListing } from 'store/commonInterfaces/iSearchObjects';
import { PropertyCardProps, handlePropertyLikeToggle, handleContactButton } from 'utils/propertyCard';
import { PlacardContainer } from '../Placard';
import { LISTING_LEVELS, MARKET_STATUSES, SITE_IDS } from 'utils/enums';
import utils from 'utils/convenience';
import Button from 'components/ui/Button';
import { lwRoutes } from 'components/routing/lwRouting';
import { lafRoutes } from 'components/routing/lafRouting';
import { PlacardImageOrCarousel } from '../Placard/PlacardImageOrCarousel/PlacardImageOrCarousel';
import cx from 'classnames';
import styles from './PropertyLW.scss';
import { PlacardCTX } from '../Placard/PlacardCTX';
import { PlacardHeader } from '../Placard/PlacardHeader';
import PlacardInfo from '../Placard/PlacardInfo';
import PlacardBroker from '../Placard/PlacardBroker';
import PlacardToggleIcon from '../Placard/PlacardToggleIcon';
import PlacardBody from '../Placard/PlacardBody';
import { getPlacardType, getPlacardLocation } from '../Placard/PlacardHelpers';
import ga4 from 'utils/GA4';
import PlacardImageOverlays from '../Placard/PlacardImageOverlays';

export function getBrokerUrl(listing: PropertyListing, siteId: SITE_IDS): string {
  return siteId == SITE_IDS.LANDWATCH
    ? lwRoutes.brokerResultsMember.link({
      companyName: listing.brokerCompany,
      contactName: listing.brokerName,
      accountId: listing.accountId
    })
    : lafRoutes.brokerResultsMember.link({
      companyName: listing.brokerCompany,
      contactName: listing.brokerName,
      accountId: listing.accountId
    });
}

class PropertyLW extends Component<PropertyCardProps> {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps: PropertyCardProps) {
    return (
      this.props.isLiked !== nextProps.isLiked ||
      this.props.listing !== nextProps.listing ||
      this.props.loading !== nextProps.loading ||
      this.props.isPlacard !== nextProps.isPlacard ||
      typeof this.props.onMouseEnter !== typeof nextProps.onMouseEnter ||
      typeof this.props.onMouseLeave !== typeof nextProps.onMouseLeave ||
      utils.getBreakpoint(this.props.store.windowWidth) !== utils.getBreakpoint(nextProps.store.windowWidth) ||
      this.props.isListView !== nextProps.isListView
    );
  }

  render() {
    const {
      listing,
      store,
      isPlacard = false,
      loading,
      showSchema,
      isLiked,
      isHome,
      isLanding,
      isListingDetailsPage,
      isSearchResults,
      isListView,
      isSimilarResult,
      handler
    } = this.props;
    const { siteIdentity, isMobileDevice, imageDomain } = store;
    const { listingLevel } = listing;
    const schemaData = showSchema ? (
      <script type="application/ld+json" id={`listingSchema_${listing.id}`} dangerouslySetInnerHTML={{ __html: listing.schemaData }} />
    ) : null;
    const { status } = listing;
    const { SOLD, OFF_MARKET } = MARKET_STATUSES;
    const showContactButton = listingLevel !== LISTING_LEVELS.FREE && status !== OFF_MARKET && status !== SOLD;
    const isDiamondSearchResult = listing.isDiamond && isSearchResults;
    const isMapView = isSearchResults && isListView === false;
    /*We always want to lazy load the property card images, except on SRP Map View.  We make an exception here
    only because the lazy load functionality has a bug on map view.  Once the map view issue is resolved, images should always be lazy loaded by default.*/
    const shouldLazyLoadImages = !isMapView;
    const displayPriceChangeTag = store.searchCriteria.priceChangeLookback !== 0 && listing.priceChangeAmount !== 0;

    const placardLocation = getPlacardLocation(
      isLanding,
      isHome,
      isListingDetailsPage,
      isSearchResults,
      isSimilarResult
    );
    const placardType = getPlacardType(listing, placardLocation);

    return (
      <Fragment>
        <PlacardCTX>
          <PlacardContainer
            isMapView={isMapView}
            listing={listing}
            listingType={placardType}
            isLoading={loading}
            className={styles.placard}
          >
            {schemaData}
            {isDiamondSearchResult && (
              <PlacardHeader siteIdentity={siteIdentity} listing={listing} placardLocation={placardLocation}>
                <PlacardToggleIcon
                  onClick={() => handlePropertyLikeToggle(listing, this.props.store.loginInfo, handler)}
                  altTextOn="Unlike"
                  altTextOff="Like"
                  icon="heart-red"
                  isOn={isLiked}
                />
              </PlacardHeader>
            )}
            <PlacardImageOrCarousel
              key={`${listing.id}-carousel`}
              siteIdentity={siteIdentity}
              listing={listing}
              imageDomain={imageDomain}
              store={store}
              isMapView={isMapView}
              isLazy={shouldLazyLoadImages}
              placardLocation={placardLocation}
              listingType={placardType}
            >
              <PlacardImageOverlays
                placardLocation={placardLocation}
                listing={listing}
                handler={handler}
                isMobileDevice={isMobileDevice}
                siteIdentity={siteIdentity}
              />
            </PlacardImageOrCarousel>
            <PlacardBody listingType={placardType}>
              <PlacardInfo
                siteIdentity={siteIdentity}
                listing={listing}
                listingType={placardType}
                placardLocation={placardLocation}
                displayPriceChangeTag={displayPriceChangeTag}
              >
                {!isDiamondSearchResult && (
                  <PlacardToggleIcon
                    onClick={() => handlePropertyLikeToggle(listing, this.props.store.loginInfo, handler)}
                    isOn={isLiked}
                    icon="heart-red"
                    altTextOn="Unlike"
                    altTextOff="Like"
                    data-qa-like={listing.siteListingId}
                  />
                )}
              </PlacardInfo>
              <PlacardBroker
                listing={listing}
                listingType={placardType}
                isLazy={true}
                imageDomain={store.imageDomain}
                siteIdentity={store.siteIdentity}
                brokerUrl={getBrokerUrl(listing, store.siteId)}
                accessibleLabel="Contact Seller"
                isMapView={isMapView}
              >
                {showContactButton && (
                  <div
                    tabIndex={0}
                    data-qa-contact={listing.siteListingId}
                    onClick={() =>
                      handleContactButton(this.props, () => ga4.leadEvents.leadFormViewedFromListing(listing))
                    }
                    role="button"
                  >
                    <Button
                      isSecondary
                      id={`contact-${listing.siteListingId}`}
                      className={cx(styles['contact-button'], isPlacard ? styles['placard-contact'] : null)}
                    >
                      <>Contact</>
                      <span className={styles['contact-seller']}>&nbsp;Seller</span>
                    </Button>
                  </div>
                )}
              </PlacardBroker>
            </PlacardBody>
          </PlacardContainer>
        </PlacardCTX>
      </Fragment>
    );
  }
}

export default PropertyLW;
