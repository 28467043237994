import { Component } from 'react';
import GlobalHandler from 'handler/globalHandler';
import cx from 'classnames';
import { LeadSource } from 'utils/enums';
import { getPropertyTitle } from 'utils/convenience';
import styles from './NativeAd.scss';
import GlobalUI from 'store/models/globalUI';
import { LAF_GPT_DETAIL, LOA_GPT_DETAIL, LW_GPT_DETAIL } from 'utils/googlePublisherTags';
import { DetailsAPIProperty } from 'store/commonInterfaces/iSearchObjects';
import ListingLogInfo from 'store/models/listingLogInfo';
import ga4 from 'utils/GA4';

interface Props {
  adContainerId: string;
  adUnitPath: string;
  leadSource?: LeadSource;
  handler?: GlobalHandler;
  className?: any;
  siteId: number;
  isBuyerAgentAd?: boolean;
  countyId?: number;
  propertyData?: DetailsAPIProperty;
}

/*
The Google Publisher Tag (GPT) is an ad tagging library for Google Ad Manager which is used to dynamically build ad requests.
googlePublisherTag.ts contains all of our GPT objects.
*/
export default class NativeAd extends Component<Props, any> {
  isCreditFarm: boolean;
  addedPubAdsEventListener = false;

  constructor(props) {
    super(props);
  }

  slotOnLoad = (event: googletag.events.Event): void => {
    if (!event?.slot) {
      return;
    }

    // Show the buyerAgentAd header if the container contains an ad.
    const slotElementId = event.slot.getSlotElementId();

    const isBuyerAgentAd =
      LW_GPT_DETAIL.buyerAgent.adContainerId === slotElementId ||
      LOA_GPT_DETAIL.buyerAgent.adContainerId === slotElementId ||
      LAF_GPT_DETAIL.buyerAgent.adContainerId === slotElementId;

    if (isBuyerAgentAd) {
      const buyerAdHeader = document.getElementById('buyerAdHeader');
      if (buyerAdHeader) {
        buyerAdHeader.style.display = 'block';
      }
    }

    // Set GlobalUI isCreditFarm based on whether this ad is a Farm Credit Ad.
    this.isCreditFarm =
      LW_GPT_DETAIL.farmCredit.adContainerId === slotElementId ||
      LOA_GPT_DETAIL.farmCredit.adContainerId === slotElementId ||
      LAF_GPT_DETAIL.farmCredit.adContainerId === slotElementId;

    const handlerApp = this.props?.handler?.app;
    if (handlerApp && this.isCreditFarm !== handlerApp.state.globalUI.isCreditFarm) {
      handlerApp.setState({
        globalUI: new GlobalUI(handlerApp.state.globalUI, {
          isCreditFarm: this.isCreditFarm
        })
      });
    }
  };

  // Define an ad slot and initialize GPT using the googletag.enableServices() method.
  constructAdSlots = () => {
    const { adUnitPath, adContainerId } = this.props;
    window.addEventListener('message', this.messageListener);
    const googletag = (window.googletag = window.googletag || ({ cmd: [] } as typeof window.googletag));

    googletag.cmd.push(() => {
      // defineSlot constructs an ad slot with a given ad unit path and size and associates it with the ID of a div element on the page that will contain the ad.
      const adSlot = googletag.defineSlot(`/21873080469/${adUnitPath}`, ['fluid'], adContainerId);
      if (!adSlot) {
        return;
      }

      adSlot.addService(googletag.pubads());

      googletag.pubads().enableLazyLoad({
        fetchMarginPercent: 500, // Fetch slots within 5 viewports.
        renderMarginPercent: 200, // Render slots within 2 viewports.
        mobileScaling: 2.0 // Double the above values on mobile.
      });

      /* By default, ad slots not yet filled are left visible, which results in blank space on page.
      Calling .collapseEmptyDivs instructs GPT to collapse them when they aren't filled.
      */
      googletag.pubads().collapseEmptyDivs(true);

      // Enables all GPT services that have been defined for ad slots on the page.
      googletag.enableServices();

      // Instructs slot services to render the slot based on the adContainerId, which must match the id used in defineSlot()
      googletag.display(adContainerId);

      // This listener is called when a creative iframe load event fires.
      googletag.pubads().addEventListener('slotOnload', this.slotOnLoad);
      this.addedPubAdsEventListener = true;
    });
  };

  componentDidMount() {
    if (typeof window === 'undefined') {
      return;
    }

    this.constructAdSlots();
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.messageListener);

    if (this.addedPubAdsEventListener) {
      window.googletag.pubads().removeEventListener('slotOnload', this.slotOnLoad);
      this.addedPubAdsEventListener = false;
    }

    // Reset showCheckboxes state.
    if (this.props && this.props.handler) {
      const newGlobalUI = new GlobalUI(this.props.handler.app.state.globalUI, {
        showCheckboxes: true
      });
      this.props.handler.app.setState({
        globalUI: newGlobalUI
      });
    }
  }

  messageListener = (event: MessageEvent<any>) => {
    let { data } = event;
    const { leadSource, siteId, countyId, isBuyerAgentAd, propertyData } = this.props;
    if (
      event.origin === 'http://tpc.googlesyndication.com' ||
      event.origin === 'https://tpc.googlesyndication.com' ||
      event.origin.indexOf('safeframe.googlesyndication.com') !== -1
    ) {
      if (typeof data === 'string' && data.length > 0) {
        if (data[0] === '{' || data[0] === '[') {
          data = JSON.parse(data);
        }
      }
      // Show finance/newsletter checkboxes.
      const showCheckboxes = propertyData?.listingId > 0;

      if (data?.brokerId) {
        this.props.handler.handleBroker().then(handleBroker => {
          handleBroker.loadContactBrokerModal({
            siteId: siteId,
            leadSource: leadSource,
            brokerId: data.brokerId,
            countyId: countyId,
            showCheckboxes: showCheckboxes,
            listingLogInfo: ListingLogInfo.APIPropertyToLogInfo(propertyData),
            isCreditFarm: this.isCreditFarm,
            isBuyerAgentAd: isBuyerAgentAd,
            price: propertyData?.price ?? 0,
            acres: propertyData?.acres ?? 0,
            propertyTitle: getPropertyTitle(propertyData),
            ga4Eventfn: () => ga4.leadEvents.leadFormViewedFromBroker(data.brokerId)
          });
        });
      } else if (data?.financeCenterId) {
        const financeCenterId = Number(data?.financeCenterId);
        this.props.handler.handleGlobalUI().then(handleGlobalUI => {
          handleGlobalUI.setShowFinanceCenterContactModal(true, financeCenterId)
        });
      }
    }
  };

  render() {
    return <div className={cx(styles.NativeAd, this.props.className)} id={this.props.adContainerId} data-google-ad />;
  }
}
