import { ReactNode } from 'react';
import { ImageClass, ImageClasses, ImageClassInfo, GetImageSources, PictureSources } from 'utils/variables';
import { checkHasAgentImage, tagQa } from '../PlacardHelpers';
import { PropertyListing, SiteIdentity } from 'store/commonInterfaces/iSearchObjects';
import { Link } from 'react-router-dom';
import Picture from 'components/ui/Picture';
import cx from 'classnames';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './PlacardBroker.scss';
import { LISTING_LEVELS } from 'utils/enums';

export default function PlacardBroker({
  brokerUrl,
  children,
  imageDomain,
  isLazy,
  listing,
  siteIdentity,
  accessibleLabel,
  listingType,
  isMapView
}: {
  brokerUrl: string;
  children?: ReactNode;
  imageDomain: string;
  isLazy: boolean;
  listing: PropertyListing;
  siteIdentity: SiteIdentity;
  accessibleLabel?: string;
  listingType: string;
  isMapView?: boolean;
}) {
  const { brokerName, brokerCompany, listingLevel } = listing;
  const { portraitDocumentId, companyLogoDocumentId } = listing;
  const logoClass = siteIdentity.isLand ? ImageClass.brokerLogoLand : ImageClass.brokerLogoLw;
  const logoClassName = ImageClass[logoClass];
  const logoId = portraitDocumentId || companyLogoDocumentId;
  const imageInfo: ImageClassInfo = ImageClasses[logoClassName];
  const logoWidth = imageInfo.size.width;
  const logoHeight = imageInfo.size.height;
  const logoImageSources =
    logoId && logoId > 0 ? GetImageSources(logoClass, imageDomain, logoId) : ({} as PictureSources);
  const hasAgentImage = checkHasAgentImage(listing);
  const isPaidListing = listingLevel !== LISTING_LEVELS.FREE;

  return (
    <>
      {isPaidListing && (
        <div
          id="placard-broker"
          className={cx(styles[`level-${listingType}`], styles['placard-broker'], isMapView ? styles['map-view'] : '')}
          {...tagQa('placard-broker', listing.accountId)}
        >
          {hasAgentImage && logoImageSources.fallbackUrl && (
            <div className={styles['placard-broker-brand']}>
              <Link to={brokerUrl} aria-label={brokerCompany}>
                <Picture
                  alt={brokerCompany}
                  className={styles.portrait}
                  height={logoHeight}
                  isLazy={isLazy}
                  sources={logoImageSources.sources}
                  src={logoImageSources.fallbackUrl}
                  width={logoWidth}
                />
              </Link>
            </div>
          )}

          <div className={styles['placard-broker-info']}>
            <Link to={brokerUrl}>
              <div className={styles['broker-name']}>{brokerName}</div>
              {brokerName !== brokerCompany && <div className={styles['broker-company']}>{brokerCompany}</div>}
            </Link>
          </div>
          <div role="button" aria-label={accessibleLabel || 'Broker Details'} tabIndex={0} className={styles.contact}>
            {children}
          </div>
        </div>
      )}
    </>
  );
}
