import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import GlobalStore from 'store/globalStore';
import GlobalHandler from 'handler/globalHandler';
import utils, { IsListingForSale } from 'utils/convenience';
import { LISTING_LEVELS } from 'utils/enums';
import { PropertyListing } from 'store/commonInterfaces/iSearchObjects';
import { PropertyCardProps, handlePropertyLikeToggle, handleContactButton } from 'utils/propertyCard';
import { BreakPoints } from 'utils/variables';
import { PlacardContainer } from '../Placard';
import { PlacardImageOrCarousel } from '../Placard/PlacardImageOrCarousel/PlacardImageOrCarousel';
import BrokerDetailsType from 'store/models/brokerDetails';
import ga4 from 'utils/GA4';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './PropertyLand.scss';
import cx from 'classnames';
import { PlacardCTX } from '../Placard/PlacardCTX';
import { PlacardHeader } from '../Placard/PlacardHeader';
import DiamondPlacardCTA from '../Placard/DiamondPlacardCTA';
import PlacardInfo from '../Placard/PlacardInfo';
import PlacardBroker from '../Placard/PlacardBroker';
import PlacardToggleIcon from '../Placard/PlacardToggleIcon';
import PlacardBody from '../Placard/PlacardBody';
import { getPlacardLocation, getPlacardType } from '../Placard/PlacardHelpers';
import { logPhoneClickFromPlacard } from 'utils/logHelpers';
import PlacardImageOverlays from '../Placard/PlacardImageOverlays';

interface State {
  brokerPhoneNumberFormatted: string;
}

class Property extends Component<PropertyCardProps, State> {
  static defaultProps = {
    store: { imageDomain: null, routing: { property: { link: () => '/', route: '/' } } } as Partial<GlobalStore>,
    handler: {} as GlobalHandler,
    listing: { acres: 0, id: 0, siteListingId: 0, types: [] } as PropertyListing,
    onMouseEnter: () => null,
    onMouseLeave: () => null,
    isExpanded: false,
    isLiked: false,
    isMailed: false,
    isGrid: false,
    isLazy: false,
    loading: false,
    isPlacard: false,
    isListView: false,
    history: null,
    isHome: false,
    isLanding: false,
    hideEnvelope: false,
    isSimilarResult: false
  };

  constructor(props) {
    super(props);
    this.state = {
      brokerPhoneNumberFormatted: ''
    };
  }

  componentDidMount() {
    const { listing, isSearchResults } = this.props;
    const isDiamondSearchResult = listing.isDiamond && isSearchResults;
    if (isDiamondSearchResult) {
      const brokerPhoneNumberFormatted = BrokerDetailsType.getFormattedBrokerPhoneNumber(listing.brokerPhone);
      this.setState({
        brokerPhoneNumberFormatted: brokerPhoneNumberFormatted
      });
    }
  }

  shouldComponentUpdate(nextProps: PropertyCardProps, nextState: State) {
    return (
      this.state.brokerPhoneNumberFormatted !== nextState.brokerPhoneNumberFormatted ||
      this.props.isLiked !== nextProps.isLiked ||
      this.props.listing !== nextProps.listing ||
      this.props.loading !== nextProps.loading ||
      this.props.isPlacard !== nextProps.isPlacard ||
      typeof this.props.onMouseEnter !== typeof nextProps.onMouseEnter ||
      typeof this.props.onMouseLeave !== typeof nextProps.onMouseLeave ||
      utils.getBreakpoint(this.props.store.windowWidth) !== utils.getBreakpoint(nextProps.store.windowWidth) ||
      this.props.isListView !== nextProps.isListView
    );
  }

  // Do not use this for styling. The page will be styled too late after page load and there will be movement of the elements.
  placardView() {
    const { isPlacard, store } = this.props;
    return isPlacard && utils.getBreakpoint(store.windowWidth) >= BreakPoints.small;
  }

  render() {
    const {
      isLazy,
      listing,
      onMouseEnter,
      onMouseLeave,
      store,
      showSchema,
      isLanding,
      isHome,
      isListingDetailsPage,
      isSearchResults,
      hideEnvelope,
      isListView,
      isSimilarResult,
      handler
    } = this.props;
    const { brokerPhoneNumberFormatted } = this.state;
    const { siteIdentity } = store;
    const { imageDomain, isMobileDevice } = this.props.store;
    const { SIGNATURE } = LISTING_LEVELS;
    const isUpgradedListing =
      listing.isDiamond || listing.isPlatinum || listing.isGold || listing.listingLevel === SIGNATURE;
    const isPropertyForSale = IsListingForSale(listing.status);
    const showEnvelope = !hideEnvelope && isUpgradedListing && isPropertyForSale;
    const isMapView = isSearchResults && isListView === false;
    const isDiamondSearchResult = listing.isDiamond && isSearchResults;
    const displayPriceChangeTag = store.searchCriteria.priceChangeLookback !== 0 && listing.priceChangeAmount !== 0;
    const schemaData = showSchema ? (
      <script type="application/ld+json" id={`listingSchema_${listing.id}`} dangerouslySetInnerHTML={{ __html: listing.schemaData }} />
    ) : null;

    const placardLocation = getPlacardLocation(
      isLanding,
      isHome,
      isListingDetailsPage,
      isSearchResults,
      isSimilarResult
    );

    const placardType = getPlacardType(listing, placardLocation);

    return (
      <>
        <PlacardCTX>
          <PlacardContainer
            key={`${listing.id}-container${isMapView ? '-map' : ''}`}
            listing={listing}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            isLoading={this.props.loading}
            listingType={placardType}
            isMapView={isMapView}
          >
            {schemaData}
            {isDiamondSearchResult && (
              <PlacardHeader siteIdentity={siteIdentity} listing={listing} placardLocation={placardLocation}>
                <PlacardToggleIcon
                  onClick={() => handlePropertyLikeToggle(listing, this.props.store.loginInfo, handler)}
                  altTextOn="Unlike"
                  altTextOff="Like"
                  icon="heart-red"
                  isOn={this.props.isLiked}
                />
              </PlacardHeader>
            )}

            <PlacardImageOrCarousel
              key={`${listing.id}-carousel-or-image`}
              siteIdentity={siteIdentity}
              listing={listing}
              imageDomain={imageDomain}
              store={store}
              isLazy={isLazy}
              isMapView={isMapView}
              placardLocation={placardLocation}
              listingType={placardType}
              isSimilarResult={isSimilarResult}
            >
              <PlacardImageOverlays
                placardLocation={placardLocation}
                listing={listing}
                handler={this.props.handler}
                isMobileDevice={isMobileDevice}
                siteIdentity={siteIdentity}
              />
            </PlacardImageOrCarousel>
            <PlacardBody listingType={placardType}>
              <PlacardInfo
                siteIdentity={siteIdentity}
                listing={listing}
                isMapView={isMapView}
                imageDomain={imageDomain}
                isLazy={isLazy}
                placardLocation={placardLocation}
                listingType={placardType}
                displayPriceChangeTag={displayPriceChangeTag}
              >
                {!isDiamondSearchResult && (
                  <PlacardToggleIcon
                    onClick={() => handlePropertyLikeToggle(listing, this.props.store.loginInfo, handler)}
                    altTextOn="Unlike"
                    altTextOff="Like"
                    icon="heart-red"
                    isOn={this.props.isLiked}
                  />
                )}
              </PlacardInfo>
              <div
                className={cx(
                  isDiamondSearchResult && styles[`broker-for-diamond-search-result${isMapView ? '-map' : ''}`]
                )}
              >
                <PlacardBroker
                  brokerUrl={listing.brokerCanonicalUrl}
                  imageDomain={imageDomain}
                  isLazy={isLazy}
                  listing={listing}
                  siteIdentity={siteIdentity}
                  listingType={placardType}
                  isMapView={isMapView}
                >
                  {!isDiamondSearchResult && showEnvelope && (
                    <PlacardToggleIcon
                      onClick={() =>
                        handleContactButton(this.props, () => ga4.leadEvents.leadFormViewedFromListing(listing))
                      }
                      icon="envelope"
                      altTextOn="Contact"
                      altTextOff="Contact"
                      isOn={this.props.isMailed}
                    />
                  )}
                </PlacardBroker>
              </div>
              {isDiamondSearchResult && (
                <DiamondPlacardCTA
                  listing={listing}
                  handleContactButton={() => {
                    handleContactButton(this.props, () => ga4.leadEvents.leadFormViewedFromListing(listing));
                  }}
                  handlePhoneClick={() => logPhoneClickFromPlacard(handler, listing)}
                  brokerPhoneNumberFormatted={brokerPhoneNumberFormatted}
                />
              )}
            </PlacardBody>
          </PlacardContainer>
        </PlacardCTX>
      </>
    );
  }
}

export default withRouter(Property);
