/* eslint-disable @typescript-eslint/naming-convention */
import { SiteIdentity, PropertyListing, DetailsAPIProperty } from 'store/commonInterfaces/iSearchObjects';
import {
  ACCOUNT_TYPE_TIER,
  ACCOUNT_TYPES,
  LISTING_LEVELS,
  MARKET_STATUSES_OPTIONS,
  PRICE_CHANGE_LOOKBACK_TO_TEXT,
  PRICE_CHANGE_TYPE,
  PROPERTY_UPGRADE_LEVELS
} from 'utils/enums';
import SearchCriteria, { DefaultMarketStatuses } from 'store/models/searchCriteria';
import { GA4Event, GA4Ecommerce, GA4EcommerceItem } from './ga4Classes';
import BrokerDetails from 'store/models/brokerDetails';

export enum GA4_SITE_LOCATIONS {
  UNKNOWN = 'Unknown Page',
  HOMEPAGE = 'Homepage',
  LANDING_PAGE = 'Landing Page',
  SEARCH_PAGE = 'Search',
  LISTING_DETAIL_PAGE = 'Listing Detail Page'
}

export function pushToDataLayer(ga4Event: GA4Event) {
  window.dataLayer = window.dataLayer || [];

  // For events with certain kinds of data, Search Discovery wants an event to clear out previous data first.
  // E.g. - if the incoming event has event_data, we post a event with event_data set to null first.
  const shouldClearData = ga4Event.event_data || ga4Event.page_data || ga4Event.ecommerce;
  if (shouldClearData) {
    const clearDataEvent = new GA4Event({});

    if (ga4Event.event_data) {
      clearDataEvent.event_data = null;
    }

    if (ga4Event.page_data) {
      clearDataEvent.page_data = null;
    }

    if (ga4Event.ecommerce) {
      clearDataEvent.ecommerce = null;
    }

    window.dataLayer.push(clearDataEvent);
  }

  window.dataLayer.push(ga4Event);
}

export function getSiteBrand(siteIdentity: SiteIdentity) {
  return siteIdentity.isLand ? 'Land.com' : siteIdentity.isLAF ? 'LandandFarm' : 'Landwatch';
}

export function getListingLevel(listingLevel: LISTING_LEVELS) {
  return LISTING_LEVELS[listingLevel];
}

export function getUpgradeLevelString(upgradeLevelId: PROPERTY_UPGRADE_LEVELS | undefined): string {
  if (!upgradeLevelId) {
    return undefined;
  }

  return PROPERTY_UPGRADE_LEVELS[upgradeLevelId];
}

export function getSearchFacets(searchCriteria: SearchCriteria) {
  const searchFacets = [];

  if (searchCriteria.acresMin || searchCriteria.acresMax) {
    searchFacets.push('Acres');
  }

  if (searchCriteria.priceMin || searchCriteria.priceMax) {
    searchFacets.push('Price');
  }

  if (searchCriteria.bathMin || searchCriteria.bathMax) {
    searchFacets.push('Baths');
  }

  if (searchCriteria.bedMin || searchCriteria.bedMax) {
    searchFacets.push('Bedrooms');
  }

  if (searchCriteria.sqftMin || searchCriteria.sqftMax) {
    searchFacets.push('Square Feet');
  }

  if (searchCriteria.propertyTypes.length > 0) {
    searchFacets.push('Property Types');
  }

  if (searchCriteria.activityTypes.length > 0) {
    searchFacets.push('Activities');
  }

  if (searchCriteria.transactionTypes.length > 0) {
    searchFacets.push('Sale Type');
  }

  if (searchCriteria.amenityTypes.length > 0) {
    searchFacets.push('Amenities');
  }

  if (searchCriteria.lakeIdList.length > 0) {
    searchFacets.push('Lake');
  }

  if (searchCriteria.radius) {
    searchFacets.push('Radius');
  }

  if (searchCriteria.priceChangeLookback) {
    const priceChangeLookbackTime = PRICE_CHANGE_LOOKBACK_TO_TEXT[searchCriteria.priceChangeLookback];
    const priceChangeType =
      searchCriteria.priceChangeType === PRICE_CHANGE_TYPE.CHANGE ? 'Price Change' : 'Price Reduction';

    searchFacets.push(`${priceChangeType} ${priceChangeLookbackTime}`);
  }

  const marketStatuses = searchCriteria.marketStatuses
    .map(marketStatus => MARKET_STATUSES_OPTIONS[marketStatus])
    .join(', ');

  const defaultMarketStatuses = DefaultMarketStatuses.map(marketStatus => MARKET_STATUSES_OPTIONS[marketStatus]).join(
    ', '
  );

  if (marketStatuses !== defaultMarketStatuses) {
    searchFacets.push('Availability');
  }

  if (searchCriteria.keywordQuery) {
    searchFacets.push('Keyword');
  }

  if (searchCriteria.mineralRights) {
    searchFacets.push('Mineral Rights');
  }

  if (searchCriteria.ownerFinancing) {
    searchFacets.push('Owner Financing');
  }

  if (searchCriteria.hasCustomMap) {
    searchFacets.push('Custom Map');
  }

  if (searchCriteria.hasVideo) {
    searchFacets.push('Property Video');
  }

  if (searchCriteria.hasVirtualTour) {
    searchFacets.push('Virtual Tour');
  }

  if (searchCriteria.dateListed !== 0) {
    searchFacets.push('Date Listed');
  }

  if (searchCriteria.cityName) {
    searchFacets.push('City');
  }

  if (searchCriteria.stateName) {
    searchFacets.push('State');
  }

  if (searchCriteria.regionName) {
    searchFacets.push('Region');
  }

  if (searchCriteria.countyName) {
    searchFacets.push('County');
  }

  if (searchCriteria.zip) {
    searchFacets.push('Zipcode');
  }

  return searchFacets.join(', ');
}

export function getEcommerceForListing(
  listing: PropertyListing | DetailsAPIProperty,
  listingId: number,
  siteIdentity: SiteIdentity
) {
  const ecommerceItem = new GA4EcommerceItem({
    affiliation: listing.accountId ? listing.accountId.toString() : undefined,
    item_brand: getSiteBrand(siteIdentity),
    item_category: listing.listingLevel ? getListingLevel(listing.listingLevel) : undefined,
    item_id: listing.siteListingId ? listing.siteListingId.toString() : undefined,
    item_name: listingId?.toString() || undefined,
    item_variant: getUpgradeLevelString(listing.upgradeLevelId),
    value: listing.price ? listing.price.toString() : undefined
  });

  const ecommerce = new GA4Ecommerce({
    currency: 'USD',
    items: [ecommerceItem]
  });

  return ecommerce;
}

export function testEcommerceData(
  dataLayer,
  expectedListingId: number,
  expectedData: DetailsAPIProperty | PropertyListing
) {
  expect(dataLayer.length).toEqual(2);
  expect(dataLayer[0].ecommerce).toBeNull();
  expect(dataLayer[1].ecommerce.currency).toEqual('USD');
  expect(dataLayer[1].ecommerce.items.length).toEqual(1);
  expect(dataLayer[1].ecommerce.items[0].affiliation).toEqual(expectedData.accountId.toString());
  expect(dataLayer[1].ecommerce.items[0].item_brand).toEqual('Land.com');
  expect(dataLayer[1].ecommerce.items[0].item_category).toEqual(LISTING_LEVELS[expectedData.listingLevel]);
  expect(dataLayer[1].ecommerce.items[0].item_id).toEqual(expectedData.siteListingId.toString());
  expect(dataLayer[1].ecommerce.items[0].item_name).toEqual(expectedListingId.toString());
  expect(dataLayer[1].ecommerce.items[0].item_variant).toEqual(getUpgradeLevelString(expectedData.upgradeLevelId));
}

export function getBrokerListingTier(brokerDetails: BrokerDetails) {
  const accountType =
    brokerDetails?.accountType === ACCOUNT_TYPES.CORPORATE_CHILD
      ? brokerDetails?.parentAccountType
      : brokerDetails?.accountType;

  return accountType ? ACCOUNT_TYPE_TIER[accountType] : undefined;
}
