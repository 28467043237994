import { DetailsAPIProperty, PropertyListing } from 'store/commonInterfaces/iSearchObjects';

// The property names in this class correspond directly to EsLogStatRequest on the back-end
// Modifying or removing properties here will need changes on the back-end
// Adding properties should not cause a problem
export default class ListingLogInfo {
  accountId?: number;
  acres?: number;
  countyId?: number;
  leadSources?: number;
  listingId?: number;
  siteListingId?: number;
  listingTier?: number;
  price?: number;
  propertyTypeIds: number;
  propertyUpgradeLevel?: number;
  regionId?: number;
  stateId?: number;

  static AccountIdToLogInfo(accountId: number): ListingLogInfo {
    if (!accountId) {
      return null;
    }

    const logInfo = new ListingLogInfo();
    logInfo.accountId = accountId;
    // Elastic logging logs listing Id as 0 for account level logging
    logInfo.listingId = 0;
    return logInfo;
  }

  static APIPropertyToLogInfo(data: DetailsAPIProperty): ListingLogInfo {
    if (data == null) return null;

    const logInfo = new ListingLogInfo();
    logInfo.accountId = data.accountId;
    logInfo.acres = data.acres;
    logInfo.countyId = data.county?.id;
    logInfo.listingId = data.listingId;
    logInfo.siteListingId = data.siteListingId;
    logInfo.listingTier = data.listingLevel;
    logInfo.price = data.price;
    logInfo.propertyTypeIds = data.typeIds?.reduce((a, b) => a + b, 0);
    logInfo.propertyUpgradeLevel = data.upgradeLevelId;
    logInfo.regionId = data.region?.id;
    logInfo.stateId = data.county?.stateId;

    return logInfo;
  }

  static ListingToLogInfo(listing: PropertyListing): ListingLogInfo {
    if (listing == null) return null;

    const logInfo = new ListingLogInfo();
    logInfo.accountId = listing.accountId;
    logInfo.acres = listing.acres;
    logInfo.countyId = listing.countyId;
    logInfo.listingId = listing.id;
    logInfo.siteListingId = listing.siteListingId;
    logInfo.listingTier = listing.listingLevel;
    logInfo.price = listing.price;
    logInfo.propertyTypeIds = listing.propertyTypes;
    logInfo.propertyUpgradeLevel = listing.upgradeLevelId;
    logInfo.regionId = listing.regionId;
    logInfo.stateId = listing.stateId;

    return logInfo;
  }
}
