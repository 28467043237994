import { useEffect, useState } from 'react';
import { fromSearchCriteria } from 'utils/url';
import loadable from '@loadable/component';
import SearchWrapper from 'components/pages/PropertySearch/Search/SearchWrapper';
import { retry } from 'utils/retry';
import { SearchProps } from './interface';
import SearchBarShell from 'components/pages/PropertySearch/Search/SearchBarShell';
import { BreakPoints } from 'utils/variables';
import { PropertyListing } from 'store/commonInterfaces/iSearchObjects';

interface Props extends SearchProps {
  isSticky?: boolean;
  toggleListView?();
  propertyResults?: PropertyListing[];
}

const Search = (props: Props) => {
  const {
    handler,
    store,
    criteriaSummary,
    isHomeRoute,
    isListView,
    toggleMoreFiltersOnClick,
    toggleListView,
    dontShowFilter,
    noMargins,
    isSavedPropertiesSearch,
    backUrl,
    propertyResults
  } = props;
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [SearchBar, setSearchBar] = useState(null);
  const [openLocation, setOpenLocation] = useState(false);
  const { siteIdentity, routeContext, windowWidth, searchUI } = store;
  const areFiltersAllowed = !store.searchCriteria.userSavedProperties;
  const shouldUnstick = (siteIdentity.isLAF || siteIdentity.isLW) && routeContext.pageType === 'PropertyDetailPage';

  const closeMobileSearch = () => {
    setIsSearchVisible(false);
  };

  useEffect(() => {
    const importSearchBar = (openLocation = false) => {
      if (SearchBar) {
        return;
      }

      if (siteIdentity.isLand && routeContext.pageType === 'SearchPage' && windowWidth < BreakPoints.medium) {
        // If we're on Land Search Page and are on a mobile size screen then don't download the SearchBar component.
        return;
      }

      const _searchBar = loadable(() =>
        retry(() => import(/* webpackChunkName: "SearchBar" */ 'components/pages/PropertySearch/Search/SearchBar'))
      );

      setSearchBar(_searchBar);
      setOpenLocation(openLocation);
    };

    importSearchBar(openLocation);
  }, [SearchBar, siteIdentity, routeContext, windowWidth, openLocation]);

  return (
    <SearchWrapper isHomeRoute={isHomeRoute} shouldUnstick={shouldUnstick}>
      {SearchBar && (
        <SearchBar
          key={1}
          handler={handler}
          store={store}
          criteriaSummary={criteriaSummary}
          isHomeRoute={isHomeRoute}
          isSearchVisible={isSearchVisible}
          toggleMoreFiltersOnClick={toggleMoreFiltersOnClick}
          closeMobileSearch={closeMobileSearch}
          areFiltersAllowed={areFiltersAllowed}
          isListView={isListView}
          openLocation={openLocation}
          toggleListView={toggleListView}
          history={history}
          getFriendlySearchUrl={fromSearchCriteria}
          dontShowFilter={dontShowFilter}
          noMargins={noMargins}
          isSavedPropertiesSearch={isSavedPropertiesSearch}
          backUrl={backUrl}
          propertyResults={propertyResults}
        />
      )}
      {isHomeRoute && !SearchBar && (
        <SearchBarShell
          siteIdentity={store.siteIdentity}
          isHomeRoute={isHomeRoute}
          searchUI={searchUI}
          placeholder={store.searchPlaceholderText}
          windowWidth={store.windowWidth}
          handler={handler}
        />
      )}
    </SearchWrapper>
  );
};

export default Search;
