import { useEffect, useRef, useCallback, useMemo } from 'react';
import styles from './ShowcaseWithDynamicAds.scss';
import { HomePageTypes, LeadSource } from 'utils/enums';
import { PropertyListing } from 'store/commonInterfaces/iSearchObjects';
import GlobalStore from 'store/globalStore';
import GlobalHandler from 'handler/globalHandler';
import Property from 'components/common/Property';
import DynamicNativeAd from 'components/common/DynamicNativeAd';
import { sharedHomeAds } from 'utils/googlePublisherTags';
import ga4 from 'utils/GA4';
import { SingleRowMultiColumnCarousel } from '@costar/land-ui-components';
import { BreakPoints, ImageMimeType } from 'utils/variables';
import { getPropertyCardImageData, PropertyCardProps } from 'utils/propertyCard';
import { Helmet } from 'react-helmet-async';
import {
  MouseEventData,
  TouchEventData,
  adInputDataToTouchEvent,
  getIframeInputEventMessageData
} from './AdInputMessageHelpers';

interface ShowcaseProps {
  handler: GlobalHandler;
  store: GlobalStore;
  showcaseListings: PropertyListing[];
  homePageType: HomePageTypes;
  adTargeting: any;
  onAdsFinishedRendering: (finalShowcaseListings: PropertyListing[]) => void;
}

function getSourceIframe(event: MessageEvent): HTMLIFrameElement | undefined {
  const iframes = document.getElementsByTagName('iframe');
  for (let i = 0; i < iframes.length; i++) {
    const iframe = iframes[i];
    const window = iframe.contentWindow || iframe.contentDocument?.defaultView;
    if (window?.parent === event.source || window === event.source) {
      return iframe;
    }
  }
  return undefined;
}

export default function ShowcaseWithDynamicAds(props: ShowcaseProps) {
  const { isLAF, isLW } = props.store.siteIdentity;
  const loadedGoogleAds = useRef(false);
  const renderedAdCount = useRef(0);
  const listingsToRemove = useRef([]);

  const { store, handler, homePageType, showcaseListings, adTargeting, onAdsFinishedRendering } = props;
  const { showPlacardCarouselonHomePage } = store.featureFlags;

  let adMapper;
  if (isLAF && showPlacardCarouselonHomePage) {
    // Spotlight1 should be in card spot 2, and Spotlight2 should be in card spot 3
    adMapper = { 1: sharedHomeAds.showcaseSpotlight1, 2: sharedHomeAds.showcaseSpotlight2 };
  } else if (isLW && showPlacardCarouselonHomePage) {
    // Spotlight1 should be in card spot 1, and Spotlight2 should be in card spot 2
    adMapper = { 0: sharedHomeAds.showcaseSpotlight1, 1: sharedHomeAds.showcaseSpotlight2 };
  } else {
    // Ad slots (3rd and 5th) .
    adMapper = { 2: sharedHomeAds.showcaseSpotlight1, 4: sharedHomeAds.showcaseSpotlight2 };
  }

  // place the 7th and 8th elements into the ad slots as fallback elements.
  const orderedShowcaseListings = showcaseListings.slice(0, 6);

  if (isLW && showPlacardCarouselonHomePage) {
    if (showcaseListings.length > 6) {
      orderedShowcaseListings.splice(0, 0, showcaseListings[6]);
      orderedShowcaseListings.splice(1, 0, showcaseListings[7]);
    }
  } else if (isLAF && showPlacardCarouselonHomePage) {
    if (showcaseListings.length > 6) {
      orderedShowcaseListings.splice(1, 0, showcaseListings[6]);
      orderedShowcaseListings.splice(2, 0, showcaseListings[7]);
    }
  } else {
    if (showcaseListings.length > 6) {
      orderedShowcaseListings.splice(2, 0, showcaseListings[6]);
      orderedShowcaseListings.splice(4, 0, showcaseListings[7]);
    }
  }

  const onAdSlotWasRendered = useCallback(
    (containsListing: boolean, listingId: number): void => {
      if (!containsListing) {
        listingsToRemove.current.push(listingId);
      }

      renderedAdCount.current++;
      const totalAdCount = Object.keys(adMapper).length;
      if (renderedAdCount.current === totalAdCount) {
        // Notify parent component that all ads have been rendered.
        const visibleListings = orderedShowcaseListings.filter(
          listing => !listingsToRemove.current.includes(listing.id)
        );
        onAdsFinishedRendering(visibleListings);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onAdsFinishedRendering]
  );

  const handleGoogleAdMessage = useCallback(
    (event: MessageEvent) => {
      let { data } = event;
      if (
        event.origin === 'http://tpc.googlesyndication.com' ||
        event.origin === 'https://tpc.googlesyndication.com' ||
        event.origin.indexOf('safeframe.googlesyndication.com') !== -1
      ) {
        if (typeof data === 'string' && data.length > 0) {
          if (data[0] === '{' || data[0] === '[') {
            data = JSON.parse(data);
          }
        }

        const adMessageData = getIframeInputEventMessageData(data);
        if (adMessageData) {
          const iframe = getSourceIframe(event);
          if (!iframe) {
            return;
          }

          const offset = iframe.getBoundingClientRect();
          if (data.type === 'MouseEvent') {
            const mouseEventData = adMessageData.data as MouseEventData;
            const event = new MouseEvent(mouseEventData.type, {
              ...mouseEventData,
              clientX: mouseEventData.clientX + offset.left,
              clientY: mouseEventData.clientY + offset.top
            });

            iframe.parentElement.dispatchEvent(event);
            return;
          }

          if (data.type === 'TouchEvent') {
            const touchEventData = adMessageData.data as TouchEventData;
            const touchEvent = adInputDataToTouchEvent(touchEventData, iframe.parentElement, offset);
            iframe.parentElement.dispatchEvent(touchEvent);
            return;
          }
        }

        if (data && data.brokerId) {
          handler.handleBroker().then(handleBroker => {
            handleBroker.loadContactBrokerModal({
              siteId: store.siteId,
              leadSource: LeadSource.NativeAdHome,
              brokerId: data.brokerId,
              countyId: null,
              showCheckboxes: false,
              listingLogInfo: null,
              isCreditFarm: false,
              isBuyerAgentAd: false,
              price: 0,
              acres: 0,
              propertyTitle: null,
              ga4Eventfn: () => ga4.leadEvents.leadFormViewedFromBroker(data.brokerId)
            });
          });
        }
      }
    },
    [handler, store.siteId]
  );

  const isSSR = typeof window === 'undefined';
  if (!isSSR && !loadedGoogleAds.current) {
    loadedGoogleAds.current = true;
    handler.handleGoogleAds.destroyAds();
    handler.handleGoogleAds.setGoogleAdTargeting(adTargeting);
    window.addEventListener('message', handleGoogleAdMessage);
  }

  useEffect(
    () => {
      return () => {
        if (loadedGoogleAds.current) {
          window.removeEventListener('message', handleGoogleAdMessage);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const preloadImageLinks = useMemo(() => {
    return orderedShowcaseListings.slice(0, 1).map(listing => {
      const cardProps = { listing, isPlacard: true, isHome: true } as PropertyCardProps;
      const { imageDomain, isMobileDevice, isTabletDevice, windowWidth, siteIdentity } = store;

      const data = getPropertyCardImageData(
        cardProps,
        imageDomain,
        isMobileDevice,
        isTabletDevice,
        windowWidth,
        siteIdentity
      );

      // The vast majority of browsers support WEBP. If a browser doesn't, it just won't preload that image.
      return data.imageSources
        .filter(imageSource => imageSource.imageType === ImageMimeType.webp)
        .map(imageSource => {
          return (
            <link
              key={imageSource.imageUrl}
              as="image"
              rel="preload"
              href={imageSource.imageUrl}
              media={imageSource.media}
              fetchPriority="high"
            />
          );
        });
    });
  }, [orderedShowcaseListings, store]);

  const showcaseElements = () => {
    const propertyCards = orderedShowcaseListings.map(listing => (
      <Property
        key={listing.id}
        handler={handler}
        isHome={homePageType === HomePageTypes.Home}
        isLanding={homePageType !== HomePageTypes.Home}
        isLazy={true}
        isLiked={listing.isLiked}
        listing={listing}
        loading={store.loading}
        showSchema={true}
        store={store}
      />
    ));

    const showcaseElementsWithAds = propertyCards.map((showcaseElement, i) => {
      if (loadedGoogleAds.current && Object.keys(adMapper).includes(i.toString())) {
        const adUnit = adMapper[i];

        return (
          <div id="dynamic-native-ad" className={styles['wrapper--showcase']} key={i}>
            <DynamicNativeAd
              key={'ad-' + i}
              adContainerId={adUnit.adContainerId}
              adUnitPath={adUnit.adUnitPath}
              fallbackElement={showcaseElement}
              fallbackListingId={orderedShowcaseListings[i].id}
              onAdSlotWasRendered={onAdSlotWasRendered}
              className={styles['dynamic-ad-home']}
              windowWidth={store.windowWidth}
            />
          </div>
        );
      } else {
        return (
          <div id="placard" className={styles['wrapper--showcase']} key={i}>
            {showcaseElement}
          </div>
        );
      }
    });

    return showcaseElementsWithAds;
  };

  return (
    <div>
      <Helmet>{preloadImageLinks}</Helmet>
      <h2 className={styles.header}>Land for Sale in the United States</h2>
      {showPlacardCarouselonHomePage ? (
        <div className={styles.carousel}>
          {/* 
            We want the property content to be here for SEO and crawlability purposes
            but also need it to be in the DOM during SSR so we can retrieve it at startup 
            on the client in order to rehydrate the HydrationData. 
            */}
          {typeof window === 'undefined' ? (
            <div style={{ display: 'none' }}>
              {showcaseElements()}
            </div>
          ) : null}
          <SingleRowMultiColumnCarousel
            slideData={[...showcaseElements()]}
            windowWidth={store.windowWidth}
            enableOffsetAlign={isLW && store.windowWidth < BreakPoints.small ? true : false}
          />
        </div>
      ) : (
        <div className={styles['inner-section']}>{showcaseElements()}</div>
      )}
    </div>
  );
}
