import { placardDescriptionType } from 'utils/format';
import {
  Circle,
  OnlyShow,
  PlacardLocation,
  checkHasAllFeaturesAllowed,
  logPlacardListingClickedEvent,
  tagQa
} from '../PlacardHelpers';
import { PropertyListing, SiteIdentity } from 'store/commonInterfaces/iSearchObjects';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PlacardBroker from '../PlacardBroker';
import PlacardPriceChangeTag from '../PlacardPriceChangeTag';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './PlacardInfo.scss';

export default function PlacardInfo({
  siteIdentity,
  children,
  listing,
  imageDomain,
  isMapView,
  isLazy,
  isMapClickCard = false,
  placardLocation,
  listingType,
  displayPriceChangeTag = false
}: {
  siteIdentity: SiteIdentity;
  children?: ReactNode;
  listing: PropertyListing;
  imageDomain?: string;
  isMapView?: boolean;
  isLazy?: boolean;
  isMapClickCard?: boolean;
  placardLocation: PlacardLocation;
  listingType: string;
  displayPriceChangeTag?: boolean;
}) {
  const {
    priceDisplay,
    acresDisplay,
    bedsDisplay,
    bathsDisplay,
    homesqftDisplay,
    address,
    city,
    stateCode,
    zip,
    county,
    priceChangeAmount,
    shortPriceChangeAmount
  } = listing;

  const hasAllFeaturesAllowed = checkHasAllFeaturesAllowed(listing);
  const { canonicalUrl, relativeUrl } = listing;
  const listingUrl = canonicalUrl || relativeUrl;
  const [bed, bath, sqft] = [!!bedsDisplay, !!bathsDisplay, !!homesqftDisplay];
  const hasSummary = !!(bed || bath || sqft);
  const isDiamondSearchResult =
    listing.isDiamond && placardLocation === PlacardLocation.SearchResult && !isMapClickCard;
  const placardType = placardDescriptionType(siteIdentity, listing, hasSummary);

  return (
    <div
      id="placard-info"
      className={cx(styles[`level-${listingType}`], styles[`placard-info${isMapView ? '-map' : ''}`])}
      {...tagQa('placardinfo', true)}
    >
      <div className={styles['placard-detail']}>
        <div className={styles['placard-detail-left-column']}>
          <div className={styles['placard-header']}>
            <Link to={listingUrl} onClick={() => logPlacardListingClickedEvent(listing, siteIdentity, placardLocation)}>
              <span className={styles.price}>{priceDisplay}</span>
              <span className={styles.circle}>
                <Circle isShown={!!priceDisplay && !!acresDisplay} />
              </span>
              <span className={styles.acres}>{acresDisplay}</span>
            </Link>
            {displayPriceChangeTag && (
              <span className={styles['price-change-tag']}>
                <PlacardPriceChangeTag
                  priceChangeAmount={priceChangeAmount}
                  shortPriceChangeAmount={shortPriceChangeAmount}
                />
              </span>
            )}
          </div>
          <OnlyShow when={hasSummary}>
            <div className={styles.summary}>
              <Link
                to={listingUrl}
                onClick={() => logPlacardListingClickedEvent(listing, siteIdentity, placardLocation)}
              >
                {bedsDisplay}
                <Circle isShown={bed && (bath || sqft)} />
                {bathsDisplay}
                <Circle isShown={bath && sqft} />
                {homesqftDisplay}
              </Link>
            </div>
          </OnlyShow>
          {/* Insert an empty div to maintain the summary space. */}
          <OnlyShow when={siteIdentity.isLand && isDiamondSearchResult && !hasSummary}>
            <div className={cx(styles.summary, styles['summary-placeholder'])} />
          </OnlyShow>
          {!isDiamondSearchResult && (
            <div className={cx((!hasSummary || !priceDisplay) && styles['no-ellipsis'], styles.address)}>
              <Link
                to={listingUrl}
                onClick={() => logPlacardListingClickedEvent(listing, siteIdentity, placardLocation)}
              >
                {placardLocation === PlacardLocation.SearchResult || isMapClickCard ? (
                  <p className={styles.address1}>
                    {`${address ? address + ', ' : ''}${city}, ${stateCode}, ${zip}, ${county}`}
                  </p>
                ) : (
                  <p className={styles.address1}>{`${city}, ${stateCode}, ${zip}, ${county}`}</p>
                )}
              </Link>
            </div>
          )}

          <OnlyShow when={siteIdentity.isLand && isDiamondSearchResult}>
            <div className={styles['diamond-srp-broker-container']}>
              <PlacardBroker
                brokerUrl={listing.brokerCanonicalUrl}
                imageDomain={imageDomain}
                isMapView={isMapView}
                isLazy={isLazy}
                listing={listing}
                siteIdentity={siteIdentity}
                listingType={listingType}
              />
            </div>
          </OnlyShow>
        </div>

        <div className={styles[`desc${isMapView ? '-map' : ''}`]}>
          {hasAllFeaturesAllowed && (
            <Link to={listingUrl} onClick={() => logPlacardListingClickedEvent(listing, siteIdentity, placardLocation)}>
              <div
                className={styles[`${placardType}-placard-info-desc`]}
                id={`placard-description-${listing.id}`}
                data-qa-placard-description={`${listing.siteListingId}`}
              >
                {listing.description}
              </div>
            </Link>
          )}
        </div>
      </div>
      <>{children && <div>{children}</div>}</>
    </div>
  );
}
