/* eslint-disable @typescript-eslint/naming-convention */
import { GA4Event } from '../ga4Classes';
import { pushToDataLayer, getEcommerceForListing } from '../ga4Helpers';
import { DetailsAPIProperty, PropertyListing, SiteIdentity } from 'store/commonInterfaces/iSearchObjects';

export function listingFavorited(
  listing: PropertyListing | DetailsAPIProperty,
  listingId: number,
  siteIdentity: SiteIdentity
) {
  const event = new GA4Event({
    event: 'add_to_wishlist',
    detailed_event: 'Product Added to Wishlist',
    ecommerce: getEcommerceForListing(listing, listingId, siteIdentity)
  });

  pushToDataLayer(event);
}

export function listingUnfavorited(
  listing: PropertyListing | DetailsAPIProperty,
  listingId: number,
  siteIdentity: SiteIdentity
) {
  const event = new GA4Event({
    event: 'remove_from_wishlist',
    detailed_event: 'Product Removed from Wishlist',
    ecommerce: getEcommerceForListing(listing, listingId, siteIdentity)
  });

  pushToDataLayer(event);
}

export function favoriteListingClicked() {
  console.warn('TODO');
  const event = new GA4Event({});
  pushToDataLayer(event);
}

export function favoriteListingsViewed() {
  console.warn('TODO');
  const event = new GA4Event({});
  pushToDataLayer(event);
}
