import { Component, Fragment } from 'react';
import GlobalHandler from 'handler/globalHandler';
import GlobalStore from 'store/globalStore';
import { HomePageTypes, LOCATION_TYPES, LeadSource, SITE_IDS } from 'utils/enums';
import NativeAd from 'components/common/Ad/NativeAd';
import lifeCycleManager from 'utils/lifeCycleManager';
import { retry } from 'utils/retry';
import Restricted from 'components/ui/Restricted';
import SearchUI from 'store/models/searchUI';
import SearchCriteria from 'store/models/searchCriteria';
import { FinanceCenterBasicInfo } from 'store/models/financeCenter';
import { PropertyListing, SearchCriteriaOptions, CarouselCount } from 'store/commonInterfaces/iSearchObjects';
import { LandMagazineArticle, LandingPage, SSRSearchCriteria, SeoLinkSection } from 'ssr/hydrationData';
import { History } from 'history';
import ga4 from 'utils/GA4';
import {
  CriteoDatalayerObject,
  DatalayerObject,
  DynRmktDatalayerObject,
  GtmTrackingDatalayerObject
} from 'store/commonInterfaces/GenericInterfaces';
import utils, { ProcessApiResponse } from 'utils/convenience';
import RouteHead from 'components/common/RouteHead';
import { GPTHome, getGPTAds } from 'utils/googlePublisherTags';
import styles from './Home.scss';
import { SeoType } from 'store/commonInterfaces/HydrationData';
import DynamicNativeAd from 'components/common/DynamicNativeAd/DynamicNativeAd';
import HomeLand from './HomeLand';
import HomeLWLAF from './HomeLWLAF';

interface Params {
  countyName: string;
  regionName: string;
  stateAbbreviation: string;
  stateName: string;
}

interface MatchLayout {
  params: Params;
  url: string;
}

interface Props {
  store: GlobalStore;
  handler: GlobalHandler;
  match: MatchLayout;
  homePageType: HomePageTypes;
  history: History;
  staticContext: { statusCode: number; componentClassName: string };
}

interface State {
  topLeaderBoardAdElement: JSX.Element;
  bottomLeaderBoardAdElement: JSX.Element;
  farmCreditAdElement: JSX.Element;
  listingCardAdElement: JSX.Element;
  propertyCarousel: any;
  propertyTypeGrid: any;
  landingPage: LandingPage;
  search: any;
  loading: boolean;
  homeLand: any;
  homeLWLAF: any;
}

const defaultLandingPage: LandingPage = {
  adTargeting: {
    co: [],
    rg: [],
    s: [],
    gs: [],
    pta: [],
    ['m-co']: [],
    se: process.env.NODE_ENV === 'Production' ? 'LOAPROD' : 'LOADEV'
  },
  breadcrumbSchema: '',
  carouselCounts: [] as CarouselCount[],
  landMagazineArticles: [] as LandMagazineArticle[],
  locationNames: { name: '', namePlural: '' },
  paginationData: {
    canonicalUrl: '',
    locationName: '',
    metaDescription: '',
    pageTitle: '',
    pageHeader: '',
    pageSubHeader: '',
    relativeUrl: '',
    siteName: '',
    siteUrl: '',
    searchBarLocationText: ''
  },
  metricsSection: [],
  promotedProperties: [] as PropertyListing[],
  routeContext: {
    component: '',
    locationName: '',
    locationType: LOCATION_TYPES.DEFAULT,
    locationId: -1,
    isDiamondListing: false,
    pageType: '',
    pageTypeGA: '',
    urlKey: '',
    financeCenter: new FinanceCenterBasicInfo()
  },
  schemaOrganization: '',
  searchCriteria: {} as SearchCriteriaOptions & SSRSearchCriteria,
  seoLinkSections: [] as SeoLinkSection[],
  seoTextSection: { header: '', seoText: '', footer: '' },
  seoTextSection2: { header: '', seoText: '' },
  seoType: SeoType.Default,
  stateId: -1,
  siteId: -1 as SITE_IDS,
  stateAbbreviation: '',
  stateName: ''
};

class Home extends Component<Props, State> {
  componentClassName = 'Home';
  hasLoggedPageLoadEvents = false;

  constructor(props) {
    super(props);
    if (this.props.store.renderingOnServer && this.props.staticContext) {
      this.props.staticContext.componentClassName = this.componentClassName;
    }

    this.state = {
      landingPage: this.props.store.landingPage || defaultLandingPage,
      search: null,
      topLeaderBoardAdElement: null,
      bottomLeaderBoardAdElement: null,
      farmCreditAdElement: null,
      listingCardAdElement: null,
      propertyCarousel: null,
      propertyTypeGrid: null,
      homeLand: null,
      homeLWLAF: null,
      loading: false
    };
  }

  getData = async (path: string) => {
    this.setState(
      {
        loading: true,
        topLeaderBoardAdElement: null,
        bottomLeaderBoardAdElement: null,
        farmCreditAdElement: null,
        listingCardAdElement: null
      },

      async () => {
        const response = await fetch(`/api/landing/${this.props.store.siteId}/${path.replace(/\//g, '')}`);
        if (response.ok) {
          const { app } = this.props.handler;
          const { featureFlags } = this.props.store;
          const landingPage = (await ProcessApiResponse(response, app, featureFlags)) as LandingPage;
          this.setState({ landingPage, loading: false }, this.afterDataLoad);
        } else {
          this.props.handler.app.setState({ statusCode: -1 });
          console.warn('We just directed to an error page', response);
          window.location.replace('/error');
        }
      }
    );
  };

  afterDataLoad = async () => {
    const { landingPage } = this.state;
    const { searchCriteria, paginationData, noIndex, routeContext } = landingPage;
    const { pageType, urlKey, locationId, locationType } = landingPage.routeContext;
    const isHomePage = pageType === 'HomePage';
    // check back button
    if (this.props.history.action === 'PUSH') {
      window.scroll(0, 0);
    }

    this.buildAds();
    const newSearchUi = new SearchUI(
      {},
      {
        locationDisplayValueArray: isHomePage ? [] : [paginationData.searchBarLocationText],
        autoCompleteValues: isHomePage
          ? []
          : [
            {
              id: locationId,
              location: paginationData.searchBarLocationText,
              searchPath: urlKey,
              type: locationType
            }
          ]
      }
    );
    // Restore recentSearches because other pages (e.g. SavedProperties) erase them.
    let newRecentSearches = [];
    if (this.props.store.cookiesAndLocalStorageAvailability.isLocalStorageAvailable) {
      newRecentSearches = utils.getRecentSearchesFromCookies();
    }

    const newSearchCriteria = new SearchCriteria({}, searchCriteria as Partial<SearchCriteria>);
    this.props.handler.app.setState(
      {
        searchUI: newSearchUi,
        recentSearches: newRecentSearches,
        searchCriteria: newSearchCriteria,
        noIndex: noIndex,
        // Seems like all we are really doing in Menu.tsx is setting the buy menu links and finance center.
        // Every route should tell the header this information instead of the header trying to figure all these thing out.
        // Every route should populate routeContext on mount.
        routeContext: routeContext
      },
      () => {
        this.props.handler.handleLog.logGaPageView(window.location.pathname);
        this.logPageLoadEvents();
      }
    );
  };

  dynamicImports() {
    if (!this.state.search) {
      retry(() => import(/* webpackChunkName: "HomeSearchComponent" */ 'components/pages/PropertySearch/Search')).then(
        component => this.setState(() => ({ search: component.default }))
      );
    }
  }

  componentDidMount() {
    if (this.props.history.action === 'PUSH') {
      window.scroll(0, 0);
    }

    this.dynamicImports();
    // Checking for a login token because we direct users to this page with loginTokens.
    this.props.handler.handleCheckForLoginViaToken(this.props.history).then(() => {
      this.checkStripeSession();

      lifeCycleManager.onMount(
        this.props.store.hydrated,
        this.didMountFromServer,
        () => this.didMountFromClient(this.props.history.location.pathname),
        { rootElement: this.props.store.rootElement, cssClassName: this.componentClassName }
      );
    });
  }

  componentDidUpdate(prevProps: Props) {
    lifeCycleManager.onUpdate(prevProps.match.url, this.props.match.url, () =>
      this.afterUrlChange(this.props.history.location.pathname)
    );
  }

  componentWillUnmount() {
    lifeCycleManager.onUnmount(
      { rootElement: this.props.store.rootElement, cssClassName: this.componentClassName },
      null,
      this.props.handler
    );
  }

  didMountFromServer = () => {
    this.props.handler.app.setState({ hydrated: false, landingPage: null }, this.afterDataLoad);
  };

  didMountFromClient = (path: string) => {
    this.getData(path);
  };

  afterUrlChange = (path: string) => {
    this.hasLoggedPageLoadEvents = false;
    this.getData(path);
  };

  // this is to handle after customer add a credit card in Stripe
  checkStripeSession = async () => {
    let query = window.location.search;
    if (query) {
      query = query.substring(1);
      if (query) {
        const kvPairs = query.split('&');
        for (const kvp of kvPairs) {
          const part = kvp.split('=');
          if (part[0] === 'session_id') {
            const [, sessionId] = part;
            // replace history state so that back button won't trigger API call a second time
            this.props.history.replace('/');

            const landApi = this.props.store.settings.servers.LandApi;
            const rawResponse = await fetch(`${landApi}payment/retrieve-session/${sessionId}`, { method: 'GET' });
            const response = await rawResponse.json();

            alert(`${response?.data}`);
            break;
          }
        }
      }
    }
  };

  buildAds = async () => {
    const { landingPage } = this.state;
    const isLandingPage = this.props.homePageType !== HomePageTypes.Home;
    const { handler, store } = this.props;

    if (isLandingPage) {
      handler.handleGoogleAds.destroyAds();
      handler.handleGoogleAds.setGoogleAdTargeting(landingPage.adTargeting);
    }

    const { topLeaderBoard, bottomLeaderBoard, farmCredit, listingCardAd } = getGPTAds(
      store.siteIdentity,
      'HOME'
    ) as GPTHome;

    this.setState({
      topLeaderBoardAdElement: topLeaderBoard && this.nativeAd(topLeaderBoard.adUnitPath, topLeaderBoard.adContainerId),
      bottomLeaderBoardAdElement:
        bottomLeaderBoard && this.nativeAd(bottomLeaderBoard.adUnitPath, bottomLeaderBoard.adContainerId),
      listingCardAdElement:
        listingCardAd &&
        this.nativeAd(
          listingCardAd.adUnitPath,
          listingCardAd.adContainerId,
          isLandingPage ? LeadSource.NativeAdLanding : LeadSource.NativeAdHome
        ),
      // FCAd only displays on Land Landing Pages (not home).
      farmCreditAdElement: farmCredit && isLandingPage && this.nativeAd(farmCredit.adUnitPath, farmCredit.adContainerId)
    });
  };

  dynamicNativeAd = (adUnitPath: string, adContainerId: string) => (
    <DynamicNativeAd adUnitPath={adUnitPath} adContainerId={adContainerId} className={styles['home-ad']} />
  );

  nativeAd = (adUnitPath: string, adContainerId: string, leadSource = undefined, className = styles['home-ad']) => (
    <NativeAd
      adUnitPath={adUnitPath}
      adContainerId={adContainerId}
      className={className}
      siteId={this.props.store.siteId}
      handler={this.props.handler}
      leadSource={leadSource}
    />
  );

  async logPageLoadEvents() {
    if (this.hasLoggedPageLoadEvents) {
      return;
    }

    this.hasLoggedPageLoadEvents = true;
    const { store, handler, homePageType } = this.props;
    const { siteIdentity } = store;
    const { routeContext, promotedProperties, locationNames, paginationData, stateAbbreviation } =
      this.state.landingPage;
    const { pageTypeGA } = routeContext;

    let { loginInfo } = store;
    if (loginInfo.isLoggedIn == null) {
      loginInfo = await handler.handleUserAuthenticationCore.getUser();
    }

    const { hashedEmail } = loginInfo;
    const showcaseProperties: PropertyListing[] = promotedProperties.length > 0 ? promotedProperties : [];
    const listingIdsForDataLayer =
      showcaseProperties.length > 0
        ? showcaseProperties.slice(0, 3).map(showcaseProperty => showcaseProperty.siteListingId.toString())
        : [];
    // need to rewrite this to build a list of strings with double quotes and commas, surrounded by brackets

    const emailValue = loginInfo.userInfo ? loginInfo.userInfo.email : ' ';
    const ObjectsToPushToDatalayer: DatalayerObject[] = [];
    const { pageTitle } = paginationData;

    const isHomePage = homePageType === HomePageTypes.Home;
    if (isHomePage) {
      ga4.pageEvents.homePage.pageLoadStarted(store.siteIdentity, pageTitle, hashedEmail);
      ga4.pageEvents.homePage.pageLoadFinished();
    } else {
      ga4.pageEvents.landingPage.pageLoadStarted(siteIdentity, locationNames.name, pageTitle, hashedEmail);
      ga4.pageEvents.landingPage.pageLoadFinished();
    }

    ObjectsToPushToDatalayer.push({
      PageType: 'HomePage',
      email: emailValue,
      emailHash: hashedEmail,
      ProductIDList: listingIdsForDataLayer,
      event: 'criteoEvent',
      State: stateAbbreviation ? stateAbbreviation : ''
    } as CriteoDatalayerObject);

    ObjectsToPushToDatalayer.push({
      event: 'dynRmktParamsReady',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      google_tag_params: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        listing_pagetype: 'home',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        listing_id: listingIdsForDataLayer
      }
    } as DynRmktDatalayerObject);

    ObjectsToPushToDatalayer.push({
      event: 'pageLoaded',
      pageType: pageTypeGA,
      hashedEmail: hashedEmail
    } as GtmTrackingDatalayerObject);

    utils.pushToDataLayer(ObjectsToPushToDatalayer);
  }

  onDynamicAdsFinishedRendering = (finalShowcaseListings: PropertyListing[]): void => {
    const { routeContext, searchCriteria, siteIdentity } = this.props.store;
    const { pageType } = routeContext;
    const isHomePage = pageType === 'HomePage';
    const siteLocation = isHomePage ? ga4.siteLocations.HOMEPAGE : ga4.siteLocations.LANDING_PAGE;
    ga4.listingEvents.listingResultsViewed(finalShowcaseListings, searchCriteria.pageIndex, siteIdentity, siteLocation);
  };

  render() {
    const { handler, store, homePageType } = this.props;
    const { siteIdentity, settings, ogSiteName } = store;

    const { isLW, isLAF, isLand } = siteIdentity;

    const isLandingPage = homePageType !== HomePageTypes.Home;
    const { loading, landingPage } = this.state;
    const {
      paginationData,
      promotedProperties,
      breadcrumbSchema,
      schemaOrganization
    } = landingPage as LandingPage;
    const { canonicalUrl, pageTitle, metaDescription } = paginationData;
    const showcaseListings = promotedProperties.map(listing => {
      listing.isLiked = store.loginInfo.userInfo.listOfLiked.includes(listing.id);
      return listing;
    });

    const { topLeaderBoardAdElement, bottomLeaderBoardAdElement, farmCreditAdElement, listingCardAdElement } =
      this.state;

    const pageAssets = [
      settings.assetsByChunkName.Home,
      isLW && settings.assetsByChunkName.PropertyTypeCarousel,
      isLAF && settings.assetsByChunkName.PropertyTypeGrid
    ].filter(Boolean);

    const OGImageURLForLOA = isLand ? `${canonicalUrl}assets/images/land/land-hero/land-hero-1600.jpg` : '';

    return (
      <Fragment>
        <RouteHead
          pageTitle={pageTitle}
          metaDescription={metaDescription}
          canonicalUrl={canonicalUrl}
          pageAssets={pageAssets}
          noIndex={store.noIndex}
        >
          {homePageType === HomePageTypes.Home && schemaOrganization && (
            <script type="application/ld+json" id="schemaOrganization">{schemaOrganization}</script>
          )}
          {homePageType === HomePageTypes.Home && breadcrumbSchema && (
            <script type="application/ld+json" id="breadcrumbSchema">{breadcrumbSchema}</script>
          )}

          {/* Eliminating page layout shifting when navigating to and from the homepage.
           The styles are inline because the css file css doesn't load fast enough sometimes on slow connections,
           It's possible we should add more inline above the fold styling to resolve the layout shift completely*/}
          <style>
            {`.inline-home-main {
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;
                position: relative;
                width: 100%;
                min-height: 100vh;}`}
          </style>

          {/* <!-- Facebook Meta Tags --> */}
          <meta property="og:description" content={metaDescription} />
          <meta property="og:image" content={OGImageURLForLOA} />
          <meta property="og:image:secure_url" content={OGImageURLForLOA} />
          <meta property="og:site_name" content={ogSiteName} />
          <meta property="og:title" content={pageTitle} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={canonicalUrl} />

          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={canonicalUrl} />
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={metaDescription} />
          <meta name="twitter:image" content={OGImageURLForLOA} />
        </RouteHead>
        <Restricted to={[SITE_IDS.LAND]}>
          <HomeLand
            store={store}
            handler={handler}
            landingPage={landingPage}
            isLandingPage={isLandingPage}
            loading={loading}
            topLeaderBoardAdElement={topLeaderBoardAdElement}
            bottomLeaderBoardAdElement={bottomLeaderBoardAdElement}
            farmCreditAdElement={farmCreditAdElement}
            listingCardAdElement={listingCardAdElement}
            showcaseListings={showcaseListings}
            onDynamicAdsFinishedRendering={this.onDynamicAdsFinishedRendering}
            homePageType={homePageType}
          />
        </Restricted>
        <Restricted to={[SITE_IDS.LANDWATCH, SITE_IDS.LANDANDFARM]}>
          <HomeLWLAF
            store={store}
            handler={handler}
            landingPage={landingPage}
            isLandingPage={isLandingPage}
            loading={loading}
            topLeaderBoardAdElement={topLeaderBoardAdElement}
            bottomLeaderBoardAdElement={bottomLeaderBoardAdElement}
            farmCreditAdElement={farmCreditAdElement}
            listingCardAdElement={listingCardAdElement}
            showcaseListings={showcaseListings}
            onDynamicAdsFinishedRendering={this.onDynamicAdsFinishedRendering}
            homePageType={homePageType}
          />
        </Restricted>
      </Fragment>
    );
  }
}

export default Home;
