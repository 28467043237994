/* eslint-disable @typescript-eslint/naming-convention */
import { GA4Event, GA4EventData, GA4PageData, GA4UserData } from '../ga4Classes';
import { DetailsAPIProperty, SiteIdentity } from 'store/commonInterfaces/iSearchObjects';
import SearchCriteria from 'store/models/searchCriteria';
import {
  MARKET_STATUSES_OPTIONS,
  ACTIVITIES_OPTIONS,
  TRANSACTION_TYPES_OPTIONS,
  PROPERTY_TYPES_OPTIONS,
  PROPERTY_TYPES_OPTIONS_LW,
  DATE_LISTED_OPTIONS
} from 'utils/enums';
import util from 'utils/convenience';
import {
  pushToDataLayer,
  getSiteBrand,
  getListingLevel,
  getUpgradeLevelString,
  getSearchFacets,
  getBrokerListingTier
} from '../ga4Helpers';
import BrokerDetails from 'store/models/brokerDetails';

function getPageLocation(path: string, siteIdentity: SiteIdentity): string {
  let pageLocation = window.location.origin;

  if (!pageLocation.endsWith('/') && !path.startsWith('/')) {
    pageLocation += '/';
  }

  pageLocation += path;

  const canonicalPathEndsWithSlash = !siteIdentity.isLW;
  if (canonicalPathEndsWithSlash && !pageLocation.endsWith('/')) {
    pageLocation += '/';
  }

  pageLocation += window.location.search;

  return pageLocation;
}

export function errorPageDisplayed(errorCode: number, siteIdentity: SiteIdentity) {
  const { getErrorPageText } = util;
  const eventData = new GA4EventData({
    type: errorCode.toString()
  });
  const detailedEventText = getErrorPageText(errorCode, siteIdentity);

  const event = new GA4Event({
    event: 'site_error',
    detailed_event: detailedEventText,
    event_data: eventData
  });

  pushToDataLayer(event);
}

export function pageLoadFinished() {
  const event = new GA4Event({
    event: 'page_view',
    detailed_event: 'Page Load Completed'
  });

  pushToDataLayer(event);
}

export function homePageLoadStarted(siteIdentity: SiteIdentity, pageTitle: string, hashedEmail: string) {
  const pageData = new GA4PageData({
    brand: getSiteBrand(siteIdentity),
    language: 'en',
    name: 'Homepage',
    type: 'Homepage',
    page_location: window.location.origin,
    page_title: pageTitle
  });

  const event = new GA4Event({
    event: 'page_load_started',
    detailed_event: 'Page Load Started',
    page_data: pageData,
    user_data: new GA4UserData({
      user_id: hashedEmail
    })
  });

  pushToDataLayer(event);
}

export function landingPageLoadStarted(
  siteIdentity: SiteIdentity,
  locationName: string,
  pageTitle: string,
  hashedEmail: string
) {
  const pageData = new GA4PageData({
    brand: getSiteBrand(siteIdentity),
    language: 'en',
    name: `${locationName} Landing Page`,
    type: 'Landing Page',
    page_location: window.location.href,
    page_title: pageTitle
  });

  const event = new GA4Event({
    event: 'page_load_started',
    detailed_event: 'Page Load Started',
    page_data: pageData,
    user_data: new GA4UserData({
      user_id: hashedEmail
    })
  });

  pushToDataLayer(event);
}

export function listingPageLoadStarted(
  listing: DetailsAPIProperty,
  pageTitle: string,
  siteIdentity: SiteIdentity,
  hashedEmail: string
) {
  const pageData = new GA4PageData({
    account_id: listing.accountId ? listing.accountId.toString() : undefined,
    brand: getSiteBrand(siteIdentity),
    city: listing.city ? listing.city.name : undefined,
    county: listing.county ? listing.county.name : undefined,
    country: 'USA',
    language: 'en',
    global_listing_id: listing.listingId ? listing.listingId.toString() : undefined,
    site_listing_id: listing.siteListingId ? listing.siteListingId.toString() : undefined,
    listing_tier: listing.listingLevel ? getListingLevel(listing.listingLevel) : undefined,
    name: 'Listing Detail',
    type: 'Listing Detail',
    page_location: getPageLocation('/property/*propertyid*', siteIdentity),
    page_title: pageTitle,
    parent_account_id: listing.parentAccountId ? listing.parentAccountId.toString() : undefined,
    region: listing.region ? listing.region.name : undefined,
    state: listing.address ? listing.address.stateName : undefined,
    upgrade_level: getUpgradeLevelString(listing.upgradeLevelId),
    zipcode: listing.address ? listing.address.zip : undefined
  });

  const event = new GA4Event({
    event: 'page_load_started',
    detailed_event: 'Page Load Started',
    page_data: pageData,
    user_data: new GA4UserData({
      user_id: hashedEmail
    })
  });

  pushToDataLayer(event);
}

export function searchResultsPageLoadStarted(
  siteIdentity: SiteIdentity,
  searchCriteria: SearchCriteria,
  pageTitle: string,
  pathForGA4PageLocation: string,
  hashedEmail: string
) {
  const pageData = new GA4PageData({
    brand: getSiteBrand(siteIdentity),
    country: 'USA',
    language: 'en',
    name: 'Search Results',
    type: 'Search Results',
    search_action: 'search',
    page_location: getPageLocation(pathForGA4PageLocation, siteIdentity),
    page_title: pageTitle,
    city: searchCriteria.cityName || undefined,
    state: searchCriteria.stateName || undefined,
    county: searchCriteria.countyName || undefined,
    region: searchCriteria.regionName || undefined,
    zipcode: searchCriteria.zip || undefined,
    search_facets: getSearchFacets(searchCriteria),
    search_keyword: searchCriteria.keywordQuery || undefined,
    search_min_acres: searchCriteria.acresMin ? searchCriteria.acresMin.toString() : undefined,
    search_max_acres: searchCriteria.acresMax ? searchCriteria.acresMax.toString() : undefined,
    search_min_square_feet: searchCriteria.sqftMin ? searchCriteria.sqftMin.toString() : undefined,
    search_max_square_feet: searchCriteria.sqftMax ? searchCriteria.sqftMax.toString() : undefined,
    search_min_price: searchCriteria.priceMin ? searchCriteria.priceMin.toString() : undefined,
    search_max_price: searchCriteria.priceMax ? searchCriteria.priceMax.toString() : undefined,
    search_min_bathrooms: searchCriteria.bathMin ? searchCriteria.bathMin.toString() : undefined,
    search_max_bathrooms: searchCriteria.bathMax ? searchCriteria.bathMax.toString() : undefined,
    search_min_bedrooms: searchCriteria.bedMin ? searchCriteria.bedMin.toString() : undefined,
    search_max_bedrooms: searchCriteria.bedMax ? searchCriteria.bedMax.toString() : undefined,
    search_radius: searchCriteria.radius ? searchCriteria.radius.toString() : undefined,
    search_residence: siteIdentity.isLand
      ? searchCriteria.hasHouse
        ? 'Yes'
        : searchCriteria.hasHouse === null
        ? 'Either'
        : 'No'
      : undefined,
    search_listing_age: searchCriteria.dateListed === 0 ? 'Any' : DATE_LISTED_OPTIONS[searchCriteria.dateListed],
    search_property_type:
      searchCriteria.propertyTypes.length > 0
        ? searchCriteria.propertyTypes
            .map(propertyType =>
              siteIdentity.isLW ? PROPERTY_TYPES_OPTIONS_LW[propertyType] : PROPERTY_TYPES_OPTIONS[propertyType]
            )
            .join(', ')
        : undefined,
    search_activities:
      searchCriteria.activityTypes.length > 0
        ? searchCriteria.activityTypes.map(activityType => ACTIVITIES_OPTIONS[activityType]).join(', ')
        : undefined,
    search_listing_sale_type:
      searchCriteria.transactionTypes.length > 0
        ? searchCriteria.transactionTypes.map(transactionType => TRANSACTION_TYPES_OPTIONS[transactionType]).join(', ')
        : undefined,
    search_listing_status:
      searchCriteria.marketStatuses.length > 0
        ? searchCriteria.marketStatuses.map(marketStatus => MARKET_STATUSES_OPTIONS[marketStatus]).join(', ')
        : undefined
  });

  const event = new GA4Event({
    event: 'page_load_started',
    detailed_event: 'Page Load Started',
    page_data: pageData,
    user_data: new GA4UserData({
      user_id: hashedEmail
    })
  });

  pushToDataLayer(event);
}

export function brokerListingsPageLoadStarted(
  brokerDetails: BrokerDetails,
  pageTitle: string,
  siteIdentity: SiteIdentity,
  hashedEmail: string
) {
  const pageData = new GA4PageData({
    account_id: brokerDetails?.accountId?.toString(),
    parent_account_id: brokerDetails?.parentAccountId ? brokerDetails?.parentAccountId?.toString() : undefined,
    brand: getSiteBrand(siteIdentity),
    city: brokerDetails?.companyCity || brokerDetails?.city,
    state: brokerDetails?.companyState || brokerDetails?.stateAbbreviation,
    zipcode: brokerDetails?.companyZip || brokerDetails?.zip,
    listing_tier: getBrokerListingTier(brokerDetails),
    country: 'USA',
    language: 'en',
    name: 'Search Results Members',
    type: 'Search Results Members',
    page_location: window.location.href,
    page_title: pageTitle
  });

  const event = new GA4Event({
    event: 'page_load_started',
    detailed_event: 'Page Load Started',
    page_data: pageData,
    user_data: new GA4UserData({
      user_id: hashedEmail
    })
  });

  pushToDataLayer(event);
}

export function brokerDetailsPageLoadStarted(
  brokerDetails: BrokerDetails,
  pageTitle: string,
  siteIdentity: SiteIdentity,
  hashedEmail: string
) {
  const { isLand, isLW } = siteIdentity;
  const pageLocationSegment = isLW
    ? '/profile/**accountid**'
    : isLand
    ? '/member/**accountid**'
    : '/broker/**accountid**';

  const pageData = new GA4PageData({
    account_id: brokerDetails?.accountId?.toString(),
    parent_account_id: brokerDetails?.parentAccountId ? brokerDetails?.parentAccountId?.toString() : undefined,
    brand: getSiteBrand(siteIdentity),
    city: brokerDetails?.companyCity || brokerDetails?.city,
    state: brokerDetails?.companyState || brokerDetails?.stateAbbreviation,
    zipcode: brokerDetails?.companyZip || brokerDetails?.zip,
    listing_tier: getBrokerListingTier(brokerDetails),
    country: 'USA',
    language: 'en',
    name: 'Member Page',
    type: 'Member Page',
    page_location: getPageLocation(pageLocationSegment, siteIdentity),
    page_title: pageTitle
  });

  const event = new GA4Event({
    event: 'page_load_started',
    detailed_event: 'Page Load Started',
    page_data: pageData,
    user_data: new GA4UserData({
      user_id: hashedEmail
    })
  });

  pushToDataLayer(event);
}

export function brokerDirectoryPageLoadStarted(pageTitle: string, siteIdentity: SiteIdentity, hashedEmail: string) {
  const pageData = new GA4PageData({
    brand: getSiteBrand(siteIdentity),
    language: 'en',
    name: 'Member Directory',
    type: 'Member Directory',
    page_location: window.location.href,
    page_title: pageTitle
  });

  const event = new GA4Event({
    event: 'page_load_started',
    detailed_event: 'Page Load Started',
    page_data: pageData,
    user_data: new GA4UserData({
      user_id: hashedEmail
    })
  });

  pushToDataLayer(event);
}

export function financePageLoadStarted(pageTitle: string, siteIdentity: SiteIdentity, hashedEmail: string) {
  const pageData = new GA4PageData({
    brand: getSiteBrand(siteIdentity),
    language: 'en',
    name: 'Finance Center',
    type: 'Finance Center',
    page_location: window.location.href,
    page_title: pageTitle
  });

  const event = new GA4Event({
    event: 'page_load_started',
    detailed_event: 'Page Load Started',
    page_data: pageData,
    user_data: new GA4UserData({
      user_id: hashedEmail
    })
  });

  pushToDataLayer(event);
}

export function savedSearchesPageLoadStarted(pageTitle: string, siteIdentity: SiteIdentity, hashedEmail: string) {
  const pageData = new GA4PageData({
    brand: getSiteBrand(siteIdentity),
    language: 'en',
    name: 'Saved Searches',
    type: 'Saved Searches',
    page_location: window.location.href,
    page_title: pageTitle
  });

  const event = new GA4Event({
    event: 'page_load_started',
    detailed_event: 'Page Load Started',
    page_data: pageData,
    user_data: new GA4UserData({
      user_id: hashedEmail
    })
  });

  pushToDataLayer(event);
}

export function savedPropertiesPageLoadStarted(pageTitle: string, siteIdentity: SiteIdentity, hashedEmail: string) {
  const pageData = new GA4PageData({
    brand: getSiteBrand(siteIdentity),
    language: 'en',
    name: 'Saved Properties',
    type: 'saved properties',
    page_location: window.location.href,
    page_title: pageTitle
  });

  const event = new GA4Event({
    event: 'page_load_started',
    detailed_event: 'Page Load Started',
    page_data: pageData,
    user_data: new GA4UserData({
      user_id: hashedEmail
    })
  });

  pushToDataLayer(event);
}

export function sitemapPageLoadStarted(pageTitle: string, siteIdentity: SiteIdentity, hashedEmail: string) {
  const pageData = new GA4PageData({
    brand: getSiteBrand(siteIdentity),
    language: 'en',
    name: 'Sitemap',
    type: 'Sitemap',
    page_location: window.location.href,
    page_title: pageTitle
  });

  const event = new GA4Event({
    event: 'page_load_started',
    detailed_event: 'Page Load Started',
    page_data: pageData,
    user_data: new GA4UserData({
      user_id: hashedEmail
    })
  });

  pushToDataLayer(event);
}
