import React, { useState, useEffect, Fragment } from 'react';
import styles from './DynamicNativeAd.scss';
import cx from 'classnames';

interface DynamicAdProps {
  adUnitPath: string;
  adContainerId: string;
  fallbackElement?: JSX.Element;
  fallbackListingId?: number;
  onAdSlotWasRendered?: (containsListing: boolean, listingId: number) => void;
  className?: string;
  windowWidth?: number;
}

/* The role of this component is used for Ads that will display a fall back element when a google ad is not available */
export default function DynamicNativeAd(props: DynamicAdProps) {
  const [isEmpty, setIsEmpty] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const {
    adUnitPath,
    adContainerId,
    fallbackElement,
    fallbackListingId,
    onAdSlotWasRendered,
    className,
    windowWidth
  } = props;

  const handleSlotRendered = (event: googletag.events.SlotRenderEndedEvent) => {
    if (event.slot.getSlotElementId() === adContainerId) {
      setIsEmpty(event.isEmpty);
      setLoaded(true);

      // Notify parent component that the ad was rendered
      onAdSlotWasRendered && onAdSlotWasRendered(event.isEmpty, fallbackListingId);
    }
  };

  useEffect(() => {
    const generateAd = () => {
      const googletag = (window.googletag = window.googletag || ({ cmd: [] } as typeof window.googletag));

      const existingSlot =
        googletag?.pubads &&
        googletag
          .pubads()
          .getSlots()
          .find(slot => slot.getSlotElementId() === adContainerId);

      if (existingSlot) {
        // setLoaded is important here!  The loaded state is reset to false on window resize for ad elements outside of the viewport,
        // If an ad slot is already present, we need to reset loaded to true in order for the ads to display.
        setLoaded(true);
        return;
      }

      googletag.cmd.push(() => {
        const adSlot = googletag.defineSlot(`/21873080469/${adUnitPath}`, ['fluid'], adContainerId);
        adSlot.addService(googletag.pubads());

        googletag.pubads().collapseEmptyDivs(true);
        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
        googletag.display(adContainerId);
        // This listener is called when a creative iframe render event completes. Gives isEmpty if nothing was returned.
        googletag.pubads().addEventListener('slotRenderEnded', handleSlotRendered);
      });
    };

    generateAd();

    return () => {
      const googletag = (window.googletag = window.googletag || ({ cmd: [] } as typeof window.googletag));
      googletag?.pubads && googletag.pubads().removeEventListener('slotRenderEnded', handleSlotRendered);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adUnitPath, adContainerId, windowWidth]);

  const noGoogleAdAvailable = loaded && isEmpty;
  const hasAd = !noGoogleAdAvailable && loaded;

  return (
    <div id="dynamic-native-ad-wrapper" className={cx(styles['ad-container'], className)}>
      <Fragment>
        <div
          id={adContainerId}
          className={cx(noGoogleAdAvailable ? styles['hide-ad'] : null, hasAd ? styles['google-ad'] : null)}
          data-google-ad
        />
        <div>{noGoogleAdAvailable && fallbackElement}</div>
      </Fragment>
    </div>
  );
}
