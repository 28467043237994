import React from 'react';
import { Link } from 'react-router-dom';
import styles from './InfoTile.scss';

interface Props {
  count: string;
  index: number;
  displayText: string;
  infoType: string;
  relativeUrlPath: string;
  total: number;
}

const InfoTile: React.FunctionComponent<Props> = ({ count, relativeUrlPath, displayText, index, infoType, total }) => (
  <div role="navigation" className={styles.main}>
    <Link to={relativeUrlPath}>
      <div className={styles.info}>
        <p className={styles.name}>{displayText}</p>
        <p className={styles.listings}> {count.toLocaleString()} Listings</p>
        <p className={styles.index}>
          #{index} of {total} {infoType}
        </p>
      </div>
    </Link>
  </div>
);

export default InfoTile;
