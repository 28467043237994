import { useState } from 'react';
import styles from './SeoLinkTabs.scss';
import cx from 'classnames';
import { SeoLinkSection } from 'ssr/hydrationData';
import ShowMoreLessList from 'components/ui/ShowMoreLessList';

export default function SeoLinkTabs({ seoLinkSections }: { seoLinkSections: SeoLinkSection[] }): JSX.Element {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const handleTabClick = (index: number): void => {
    setActiveTabIndex(index);
  };

  const headers = ['Popular States', 'Popular Counties', 'Popular Cities', 'Popular Searches'];

  const renderSeoLinkSections = (section: SeoLinkSection): JSX.Element[] => {
    return section.links.map(link => (
      <div className={styles['tab-pane-link-container']} key={link.relativeUrlPath}>
        <a key={link.relativeUrlPath} href={link.relativeUrlPath} className={styles['tab-pane-link']}>
          {link.displayText}
          <div className={styles['tab-pane-link-text']}>Land For Sale</div>
        </a>
      </div>
    ));
  };

  return (
    <div className={styles['container']}>
      <div className={styles.title}>Popular Land for Sale</div>
      <div className={styles['tab-header-container']}>
        {headers.map((text, index) => (
          <div
            className={cx(styles['tab-header'], activeTabIndex === index && styles['tab-header-active'])}
            key={text}
            onClick={(): void => handleTabClick(index)}
            role="presentation"
          >
            <div>{text}</div>
          </div>
        ))}
      </div>
      <div className={styles['tab-pane-container']}>
        {seoLinkSections &&
          seoLinkSections.length > 0 &&
          seoLinkSections.map((section, index) => (
            <div
              key={section.header}
              className={cx(styles['tab-pane'], activeTabIndex === index && styles['tab-pane-active'])}
            >
              <ShowMoreLessList
                collapsedLength={16}
                moreText="View All"
                lessText="View Less"
                buttonStyle={styles['show-more-button']}
                arrowStyle={styles['show-more-arrow']}
                fnGetList={(): JSX.Element[] => renderSeoLinkSections(section)}
              />
            </div>
          ))}
      </div>
    </div>
  );
}
