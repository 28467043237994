import { Component, Fragment } from 'react';
import Property from 'components/common/Property';
import { PropertyListing } from 'store/commonInterfaces/iSearchObjects';
import { HomePageTypes } from 'utils/enums';
import GlobalHandler from 'handler/globalHandler';
import GlobalStore from 'store/globalStore';
import cx from 'classnames';
/* eslint-disable css-modules/no-unused-class */
import styles from './Showcase.scss';

interface ShowcaseProps {
  bannerAd: JSX.Element;
  handler: GlobalHandler;
  listingCardAd: JSX.Element;
  homePageType: HomePageTypes;
  showcaseListings: PropertyListing[];
  store: GlobalStore;
}

class Showcase extends Component<ShowcaseProps> {
  getPropertyCards(
    showcaseListings: PropertyListing[],
    homePageType: HomePageTypes,
    preOrPostAdClassName: string
  ): JSX.Element[] {
    const cardArray = showcaseListings.map((listing, i) => (
      <div
        key={listing.id}
        className={cx(
          styles['wrapper--showcase'],
          preOrPostAdClassName?.length > 0 && styles[`${preOrPostAdClassName}-${i}`]
        )}
      >
        <Property
          handler={this.props.handler}
          isHome={homePageType === HomePageTypes.Home}
          isLanding={homePageType !== HomePageTypes.Home}
          isLazy={true}
          isLiked={listing.isLiked}
          listing={listing}
          loading={this.props.store.loading}
          showSchema={true}
          store={this.props.store}
        />
      </div>
    ));

    return cardArray;
  }

  // Land homepage does not include banner ads. Land homepage displays an inline listing card ad.
  landHomeShowcase() {
    const { homePageType, showcaseListings, listingCardAd } = this.props;
    const shouldIncludeAd = listingCardAd !== null;
    const adIndex = 2;
    const propertyCards = this.getPropertyCards(showcaseListings, homePageType, '');
    const preAdPromotedProperties = propertyCards.slice(0, adIndex);
    const postAdPromotedProperties = propertyCards.slice(adIndex);

    return (
      <Fragment>
        <div className={styles['inner-section']}>
          {preAdPromotedProperties}
          {shouldIncludeAd && (
            <span id="listing-card-ad" className={cx(styles['listing-card-ad'], styles['wrapper--showcase'])}>
              {listingCardAd}
            </span>
          )}
          {postAdPromotedProperties}
        </div>
      </Fragment>
    );
  }

  // We use banner ads for LW, LAF, and landing pages.
  showcase() {
    const { store, homePageType, showcaseListings, bannerAd } = this.props;
    const { isLAF, isLW } = store.siteIdentity;
    const adIndex = isLAF || isLW ? 8 : 4;

    // The pre-ad listings also appear in the post-ad listings.
    // We use breakpoints in CSS to control whether these property cards appear before or after the ad.
    const preAdListings = showcaseListings.slice(0, adIndex);
    const postAdListings = showcaseListings;
    const preAdProperties = this.getPropertyCards(preAdListings, homePageType, 'pre-ad-listing');
    const postAdProperties = this.getPropertyCards(postAdListings, homePageType, 'post-ad-listing');

    return (
      <Fragment>
        <div className={styles['inner-section']}>{preAdProperties}</div>
        {bannerAd}
        <div className={styles['inner-section']}>{postAdProperties}</div>
      </Fragment>
    );
  }

  render() {
    const { store, homePageType } = this.props;
    const { isLAF, isLW, isLand } = store.siteIdentity;
    const isLandHome = isLand && this.props.homePageType === HomePageTypes.Home;

    const locationText =
      store.routeContext.locationName === 'America'
        ? 'the United States'
        : homePageType === HomePageTypes.Landing_County
        ? store.routeContext.locationName + ' ' + store.searchCriteria.stateName
        : store.routeContext.locationName;
    const headerText = isLAF || isLW ? 'Showcase Properties' : `Land for Sale in ${locationText}`;

    return (
      <div>
        <h2 className={styles.header}>{headerText}</h2>
        <div className={styles['header-underline']} />
        {isLandHome ? this.landHomeShowcase() : this.showcase()}
      </div>
    );
  }
}

export default Showcase;
