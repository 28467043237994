import { LandMagazineArticle } from 'ssr/hydrationData';
import LandMagazineArticleItem from './LandMagazineArticleItem';
import styles from './LandMagazineArticleGroup.scss';
import utils from 'utils/convenience';
import Button from 'components/ui/Button';

interface LandMagazineArticleGroupProps {
  landMagazineArticles: LandMagazineArticle[];
  imageDomain: string;
  imageLocation: 'left' | 'top';
}

export default function LandMagazineArticleGroup(props: LandMagazineArticleGroupProps) {
  const { landMagazineArticles, imageDomain } = props;

  const landNetworkUrl = 'https://network.land.com/news/';

  return (
    <div className={styles['article-group-container']} datatest-id="land-magazine-article-group">
      <div className={styles.header}>
        <h2 className={styles['header-text']}>News from Land.com</h2>
      </div>
      <div className={styles['article-grid']}>
        {landMagazineArticles.map((landMagazineArticle: LandMagazineArticle) => {
          return (
            <LandMagazineArticleItem
              key={landMagazineArticle.title}
              imageDomain={imageDomain}
              landMagazineArticle={landMagazineArticle}
            />
          );
        })}
      </div>
      <div className={styles['button-container']}>
        <Button
          className={styles['view-articles-button']}
          onClick={e => utils.yieldExternalLinkClickToMainThread(e, landNetworkUrl)}
          href={landNetworkUrl}
          isSecondary
        >
          View all articles
        </Button>
      </div>
    </div>
  );
}
