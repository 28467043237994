import { useEffect, useRef, useState, useMemo } from 'react';
import PlacardCarousel from '../PlacardCarousel';
import { PropertyListing, SiteIdentity } from 'store/commonInterfaces/iSearchObjects';
import GlobalStore from 'store/globalStore';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './PlacardImageOrCarousel.scss';
import PlacardImage from '../PlacardImage';
import cx from 'classnames';
import { PlacardLocation } from '../PlacardHelpers';

export function PlacardImageOrCarousel({
  listing,
  siteIdentity,
  imageDomain,
  store,
  isMapView,
  isLazy,
  children,
  placardLocation,
  listingType,
  isSimilarResult
}: {
  listing: PropertyListing;
  siteIdentity: SiteIdentity;
  imageDomain: string;
  store: GlobalStore;
  isMapView: boolean;
  children: any;
  isLazy: boolean;
  placardLocation: PlacardLocation;
  listingType: string;
  isSimilarResult?: boolean;
}) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [hasBeenViewed, setHasBeenViewed] = useState(false);
  const ref = useRef(null);
  const displayCarousel = useMemo(() => {
    return (
      (listing.isGold || listing.isPlatinum || listing.isDiamond) &&
      placardLocation === PlacardLocation.SearchResult &&
      isIntersecting
    );
  }, [isIntersecting, listing, placardLocation]);

  useEffect(() => {
    if (displayCarousel && !hasBeenViewed) {
      setHasBeenViewed(true);
    }
  }, [isIntersecting, listing.id, displayCarousel, hasBeenViewed]);

  useEffect(() => {
    const isCSR = typeof window !== 'undefined';
    let observer = null;
    if (isCSR) {
      const isIntersectionObserver = 'IntersectionObserver' in window || 'IntersectionObserverEntry' in window;

      if (isIntersectionObserver) {
        observer = new IntersectionObserver(
          ([entry]) => {
            setIsIntersecting(entry.isIntersecting);
          },
          { rootMargin: '300px' }
        );
        observer.observe(ref.current);
      }
    }
    return () => observer && observer.disconnect();
  }, []);

  return (
    <div
      id="image-or-carousel-container"
      className={cx(styles[`level-${listingType}`], styles[`image-or-carousel-container${isMapView ? '-map' : ''}`])}
      ref={ref}
    >
      {hasBeenViewed && (
        <div className={styles['carousel-container']}>
          <PlacardCarousel
            key={`${listing.id}-carousel`}
            siteIdentity={siteIdentity}
            listing={listing}
            imageDomain={imageDomain}
            isMobileDevice={store.isMobileDevice}
            isTabletDevice={store.isTabletDevice}
            isMapView={isMapView}
            windowWidth={store.windowWidth}
            placardLocation={placardLocation}
          >
            {children}
          </PlacardCarousel>
        </div>
      )}
      <div className={styles['image-container']}>
        <PlacardImage
          siteIdentity={siteIdentity}
          listing={listing}
          isLazy={isLazy}
          imageDomain={imageDomain}
          isMobileDevice={store.isMobileDevice}
          isTabletDevice={store.isTabletDevice}
          windowWidth={store.windowWidth}
          placardLocation={placardLocation}
          listingType={listingType}
          isMapView={isMapView}
          isSimilarResult={isSimilarResult}
        >
          {children}
        </PlacardImage>
      </div>
    </div>
  );
}
