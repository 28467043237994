import React from 'react';
/* eslint css-modules/no-unused-class: [1, { markAsUsed: [ 'link' ] }] */
import styles from './LAFAmenityLinks.scss';
import { renderLinks } from 'components/layout/Footer/footerLinkHelpers';

const LAFAmenityLinks: React.FunctionComponent = () => {
  const amenityLinks = renderLinks(
    {
      'Agribusiness for Sale': { url: '/search/present-use-agribusiness/' },
      'Alternative Energy for Sale': { url: '/search/present-use-alternative-energy/' },
      'Aquaculture for Sale': { url: '/search/agricultural-uses-aquaculture/' },
      'Dairy Farm for Sale': { url: '/search/present-use-dairy-farm/' },
      'Golf-Related for Sale': { url: '/search/common-features-golf/' },
      'Historic Property for Sale': {
        url: '/search/lot-description-historical-property/'
      },
      'Hospitality for Sale': { url: '/search/present-use-hospitality/' },
      'Industrial Land for Sale': { url: '/search/present-use-industrial/' },
      'Marina for Sale': { url: '/search/common-features-marina/' },
      'Mobile Home Park for Sale': { url: '/search/present-use-mobile-home-park/' },
      'Natural Forest for Sale': { url: '/search/lot-description-natural-forest/' },
      'Office for Sale': { url: '/search/present-use-commercial/' },

      'Oil or Minerals for Sale': { url: '/search/lot-description-oil-or-minerals/' },
      'Orchard for Sale': { url: '/search/present-use-orchard-vineyards/' },
      'Organic Farm for Sale': { url: '/search/present-use-organic-farm/' },
      'Planted Forest for Sale': { url: '/search/lot-description-planted-forest/' },
      'Poultry Farm for Sale': { url: '/search/present-use-poultry/' },
      'Retail Land for Sale': { url: '/search/present-use-commercial/' },
      'Vegetable Farm for Sale': { url: '/search/present-use-vegetable-farm/' },
      'Vineyard for Sale': { url: '/search/present-use-orchard-vineyards/' }
    },
    styles
  );

  return (
    <>
      <div className={styles['amenity-links-header']}>Additional Property Types</div>

      <div className={styles['amenity-links-container']}>
        <div className={styles['link-section']}>
          <ul role="menu">{amenityLinks.slice(0, 5)}</ul>
        </div>
        <div className={styles['link-section']}>
          <ul role="menu">{amenityLinks.slice(5, 10)}</ul>
        </div>
        <div className={styles['link-section']}>
          <ul role="menu">{amenityLinks.slice(10, 15)}</ul>
        </div>
        <div className={styles['link-section']}>
          <ul role="menu">{amenityLinks.slice(15, 21)}</ul>
        </div>
      </div>
    </>
  );
};

export default LAFAmenityLinks;
