import cx from 'classnames';
import { tagQa } from '../PlacardHelpers';
import Button from 'components/ui/Button';
import styles from './PlacardToggleIcon.scss';

// Placard Toggle Icon
// ====================================================
// Note: This component assumes a naming convention
//       for icons to be <some-name>-filled.svg or
//       <some-name>-stroked.svg in order to work.
//       as long as those rules apply, this component
//       will work for any icon.
//
//       We are explicitly setting width and height
//       on the img tag due to a CLS issue called out
//       by Lighthouse. For more information:
//       https://web.dev/optimize-cls/#images-without-dimensions
// ====================================================
export function PlacardToggleIcon({
  icon,
  isOn,
  onClick,
  altTextOn,
  altTextOff
}: {
  icon: string;
  isOn: boolean;
  onClick: (any) => void;
  altTextOn: string;
  altTextOff: string;
}): JSX.Element {
  const heartIcon = isOn ? styles['saved-icon'] : styles['unsaved-icon'];
  // Envelope is only in the land bundle, but the other icons are in shared bundle.
  const bundleName = icon === 'envelope' ? styles['envelope-icon'] : heartIcon;
  const altText = isOn ? altTextOn : altTextOff;

  return (
    <div className={styles.container}>
      <Button className={styles.toggleIcon} onClick={onClick} {...tagQa(icon, true)}>
        <span className={cx(styles.icon, bundleName)} aria-label={altText} />
      </Button>
    </div>
  );
}

export default PlacardToggleIcon;
