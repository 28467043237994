/* eslint-disable jsx-a11y/alt-text */
import { Component, Fragment, createRef } from 'react';
import cx from 'classnames';
import styles from './Picture.scss';
import { ImageSource } from 'utils/variables';

interface PictureProps {
  alt?: string;
  className?: string;
  height?: number;
  isLazy?: boolean;
  offset?: number;
  placeholder?: any;
  sources?: Array<ImageSource>;
  src: string;
  width?: number;
  onloadFn?: any;
}

export default class Picture extends Component<PictureProps, any> {
  state = {
    isInView: false
  };

  pictureRef = createRef<HTMLDivElement>();
  observer: IntersectionObserver | null = null;

  componentDidMount() {
    const isIntersectionObserver =
      typeof window !== 'undefined' && ('IntersectionObserver' in window || 'IntersectionObserverEntry' in window);

    if (!isIntersectionObserver || !this.pictureRef.current) {
      return;
    }

    this.observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.setState({ isInView: true });
            this.observer.unobserve(entry.target);
          }
        });
      },
      {
        rootMargin: `${this.props.offset || 40}px`,
        threshold: 0
      }
    );

    this.observer.observe(this.pictureRef.current);
  }

  componentWillUnmount() {
    if (this.pictureRef.current && this.observer) {
      this.observer.unobserve(this.pictureRef.current);
      this.observer.disconnect();
    }
  }

  render() {
    const { className, isLazy, placeholder, sources = [], src, onloadFn, ...otherProps } = this.props;

    const Picture = (
      <picture>
        {sources.map((source, id) => {
          const mediaAttribute = !!source.media && { media: source.media };
          return <source key={id} type={source.imageType} srcSet={source.imageUrl} {...mediaAttribute} />;
        })}
        <img onLoad={onloadFn} className={cx(styles.img, className)} src={src} {...otherProps} />
      </picture>
    );

    return isLazy ? (
      <div ref={this.pictureRef} className={styles['lazy-container']}>
        {this.state.isInView ? Picture : placeholder}
      </div>
    ) : (
      <Fragment>{Picture}</Fragment>
    );
  }
}
