/* eslint-disable css-modules/no-undef-class */
import { Fragment, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { Link } from 'react-router-dom';
import { PropertyListing, SiteIdentity } from 'store/commonInterfaces/iSearchObjects';
import { ImageUrl, ImageClass, noPhotoProvided, ImageFormat, BreakPoints, MobileLargeWidth } from 'utils/variables';
import PlacardCarouselSlide from './PlacardCarouselSlide';
import { PlacardLocation, getDiamondType, getLevelName, logPlacardListingClickedEvent } from '../PlacardHelpers';
import { PlacardContext } from '../PlacardCTX';
import utils from 'utils/convenience';
import cx from 'classnames';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './PlacardCarousel.module.scss';
import { LISTING_LEVELS } from 'utils/enums';

/*
    Why not use the getListingType Function from Placard.tsx? Because the carousel should not display on isShowcase.
    When we have listings that are both isShowcase and isPlatinum || isGold || isHealine, the Placard.tsx getListingType function
    returns level-showcase, which will break the Placard Carousel Styles. Therefore, I had to recreate the same function and omit showcase from it.
    To-Do: When we retire showcase listings, we can refactor these two functions and create a single helper function.
*/
export const getListingTypeForCarousel = (
  listing,
  placardLocation,
  isMobileDevice = false,
  isTabletDevice = false,
  windowWidth = 0
) => {
  const { isDiamond, isPlatinum, isGold, listingLevel } = listing;
  if (isDiamond) {
    return getDiamondType(isTabletDevice, isMobileDevice, windowWidth, placardLocation === PlacardLocation.Home);
  }

  if (isPlatinum) return 'platinum';
  if (isGold) return 'gold';

  return getLevelName(listingLevel);
};

const getPlacardImageClass = (windowWidth: number, listingType: string, listing: PropertyListing): ImageClass => {
  if (listing.listingLevel === LISTING_LEVELS.FREE) {
    return ImageClass.free;
  }

  if (!listing.isDiamond) {
    if (windowWidth < MobileLargeWidth) {
      return ImageClass.placardMediumColumn;
    }

    if (windowWidth < BreakPoints.small) {
      return ImageClass.placardLargeColumn;
    }
  }

  return ImageClass[listingType];
};

function PlacardCarousel({
  listing,
  children,
  siteIdentity,
  imageDomain,
  isMobileDevice,
  isTabletDevice,
  isMapView,
  windowWidth,
  placardLocation
}: {
  listing: PropertyListing;
  children: ReactNode;
  siteIdentity: SiteIdentity;
  imageDomain: string;
  isMobileDevice: boolean;
  isTabletDevice: boolean;
  isMapView: boolean;
  windowWidth: number;
  placardLocation: PlacardLocation;
}): JSX.Element {
  const [emblaRef, emblaApi] = useEmblaCarousel({ align: 'start', loop: true, inViewThreshold: 0.7, watchDrag: true });
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slides, setSlides] = useState([]);
  const [renderedSlides, setRenderedSlides] = useState({});
  const { canonicalUrl } = listing;
  const { isHovered } = useContext(PlacardContext);
  const isSingleSlideCarousel = emblaApi && emblaApi.slideNodes()?.length === 1;
  const listingType = getListingTypeForCarousel(listing, placardLocation, isMobileDevice, isTabletDevice, windowWidth);
  const hasSlides = listing.imageIds?.length > 0;
  const placardImageClass = getPlacardImageClass(windowWidth, listingType, listing);

  useEffect(() => {
    const preLoadImage = (image: number) => {
      const img = new Image();
      img.src = ImageUrl(placardImageClass, imageDomain, image, ImageFormat.webp);
    };

    const isAboveSmallScreen = !isMobileDevice || utils.getBreakpoint(windowWidth) > BreakPoints.small;

    if (hasSlides) {
      // Only preload image slides when we're not on a mobile device.
      const isFirstSlide = currentSlide === 0;
      const lookAheadAmount = isFirstSlide ? (isAboveSmallScreen ? 2 : 0) : isAboveSmallScreen ? 5 : 2;
      const prevSlideStartingIndex = isFirstSlide ? listing.imageIds.length - 1 : currentSlide - 1;
      const prevSlide =
        isAboveSmallScreen || !isFirstSlide
          ? listing.imageIds.slice(prevSlideStartingIndex, prevSlideStartingIndex + 1)
          : [];

      const nextImages = [...listing.imageIds.slice(currentSlide, currentSlide + lookAheadAmount), ...prevSlide];
      const newRenderedSlides = { ...renderedSlides };
      nextImages.forEach(image => {
        if (!newRenderedSlides[image]) {
          newRenderedSlides[image] = true;
          preLoadImage(image);
        }
      });

      setRenderedSlides(newRenderedSlides);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slides.length, currentSlide, windowWidth]);

  // When the component mounts and the embla slide mounts, we want to initialize our slides.  Only when the user selects the carousel will we render all slides.
  const initSlides = () => {
    // If no image, display no image provided default
    if (!hasSlides) {
      setSlides([noPhotoProvided]);
      return;
    }

    setSlides([...listing.imageIds]);
  };

  const scrollPrev = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollPrev();
    }
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollNext();
    }
  }, [emblaApi]);

  // We want to locate the slide that is currently in view, the slide the user is currently viewing.
  const findSlideInView = () => {
    if (!emblaApi) {
      return;
    }

    // Find the index of the slide that is currently in view.
    setCurrentSlide(emblaApi.selectedScrollSnap());
  };

  useEffect(() => {
    if (!emblaApi) {
      return;
    }
    initSlides();
    findSlideInView();
    emblaApi.on('select', findSlideInView);
    emblaApi.on('reInit', findSlideInView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emblaApi]);

  return (
    <Fragment>
      <div
        id="placard-carousel"
        className={cx(
          isMobileDevice ? styles['mobile'] : styles.desktop,
          isHovered ? styles['hover'] : '',
          styles.embla,
          styles[`level-${listingType}${isMapView ? '-map' : ''}`]
        )}
      >
        <Link
          to={canonicalUrl}
          onClick={() => logPlacardListingClickedEvent(listing, siteIdentity, placardLocation)}
          aria-label="Listing Details Page"
        >
          <div className={styles['embla__viewport']} ref={emblaRef}>
            <div className={styles['embla__container']}>
              {slides.map((imageId, index) => (
                <PlacardCarouselSlide
                  listing={listing}
                  isMapView={isMapView}
                  key={imageId}
                  index={index}
                  shouldRenderSlide={!!renderedSlides[imageId] || currentSlide === index}
                  imgSrc={ImageUrl(placardImageClass, imageDomain, imageId, ImageFormat.webp)}
                  siteIdentity={siteIdentity}
                  isSingleSlide={isSingleSlideCarousel}
                  listingType={listingType}
                />
              ))}
            </div>
          </div>
        </Link>
        <button onClick={scrollPrev} data-qa-placard-carousel-prev={true} data-testid="previous" aria-label="Previous">
          <div className={styles['embla__prev']} />
          <span className={cx(styles.icon, styles['prev-icon'])} />
        </button>
        <button onClick={scrollNext} data-qa-placard-carousel-next={true} data-testid="next" aria-label="Next">
          <div className={styles['embla__next']} />
          <span className={cx(styles.icon, styles['next-icon'])} />
        </button>

        <div className={styles['embla__slide__number']} data-qa-placard-carousel-counter={true}>
          {currentSlide + 1}/{listing.imageIds?.length || 1}
        </div>
        {children}
      </div>
    </Fragment>
  );
}

export default PlacardCarousel;
