import { LISTING_LEVELS, MARKET_STATUSES } from 'utils/enums';
import { PropertyListing, SiteIdentity } from 'store/commonInterfaces/iSearchObjects';
import { PlacardLocation, checkHasAllFeaturesAllowed } from '../PlacardHelpers';
import GlobalHandler from 'handler/globalHandler';
import styles from './PlacardImageOverlays.scss';
import PlacardStatusPill from './PlacardStatusPill/PlacardStatusPill';
import cx from 'classnames';
import PlacardMVTIcons from './PlacardMVTIcons/PlacardMVTIcons';

export default function PlacardImageOverlays(props: {
  handler: GlobalHandler;
  listing: PropertyListing;
  siteIdentity: SiteIdentity;
  placardLocation: PlacardLocation;
  isMobileDevice?: boolean;
}) {
  const { handler, listing, siteIdentity, placardLocation, isMobileDevice } = props;
  const { status, salesDate, listingLevel } = listing;
  const hasIcons = listing.hasCustomMap || listing.hasVirtualTour || listing.hasVideo;
  const isFreeListing = listingLevel === LISTING_LEVELS.FREE;
  const shouldShowStatusPill =
    status === MARKET_STATUSES.OFF_MARKET ||
    status === MARKET_STATUSES.SOLD ||
    status === MARKET_STATUSES.UNDER_CONTRACT;

  return shouldShowStatusPill ? (
    <div className={cx(styles['placard-image-overlay'], styles.pill, isFreeListing && styles['free-listing'])}>
      <PlacardStatusPill
        status={status}
        salesDate={salesDate}
        isFreeListing={isFreeListing}
        isMobileDevice={isMobileDevice}
      />
    </div>
  ) : (
    checkHasAllFeaturesAllowed(listing) && hasIcons && (
      <div className={cx(styles['placard-image-overlay'], styles.icons)}>
        <PlacardMVTIcons
          handler={handler}
          listing={listing}
          siteIdentity={siteIdentity}
          placardLocation={placardLocation}
        />
      </div>
    )
  );
}
