import { useEffect, useState } from 'react';
import HomeCarousel, { HomepageCarouselSlideData } from '../HomeCarousel/HomeCarousel';
import { BreakPoints } from 'utils/variables';
import utils from 'utils/convenience';
import useEmblaCarousel from 'embla-carousel-react';
import CarouselPagination from '../HomeCarousel/CarouselPagination';
import styles from './ActivitiesCarousel.scss';
import { HomeCarouselCTX } from '../HomeCarousel';
import ga4 from 'utils/GA4';

function ActivitiesCarousel({ windowWidth }: { windowWidth: number }): JSX.Element {
  const [imagesPerSlide, setImagesPerSlide] = useState(0);
  const [columnStyling, setColumnStyling] = useState('');
  const [totalSlideCount, setTotalSlideCount] = useState(0);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    loop: false,
    inViewThreshold: 0.7,
    watchDrag: true
  });
  const imageDirectory = '/assets/images/land/land-homepage/activities';
  const slides: HomepageCarouselSlideData[] = [
    {
      imagePath: `${imageDirectory}/aviation`,
      searchUrl: '/United-States/activities-aviation/',
      title: 'Land with Airstrips'
    },
    {
      imagePath: `${imageDirectory}/beach`,
      searchUrl: '/United-States/activities-beach/',
      title: 'Beach Properties for Sale'
    },
    {
      imagePath: `${imageDirectory}/boating`,
      searchUrl: '/United-States/activities-boating/',
      title: 'Boating Properties for Sale'
    },
    {
      imagePath: `${imageDirectory}/camping`,
      searchUrl: '/United-States/activities-camping/',
      title: 'Camping Land for Sale'
    },
    {
      imagePath: `${imageDirectory}/conservation`,
      searchUrl: '/United-States/activities-conservation/',
      title: 'Conservation Property for Sale'
    },
    {
      imagePath: `${imageDirectory}/kayaking`,
      searchUrl: '/United-States/activities-canoeing-kayaking/',
      title: 'Kayaking Properties for Sale'
    },
    {
      imagePath: `${imageDirectory}/fishing`,
      searchUrl: '/United-States/activities-fishing/',
      title: 'Fishing Property for Sale'
    },
    {
      imagePath: `${imageDirectory}/horseback`,
      searchUrl: '/United-States/activities-horseback-riding/',
      title: 'Horseback Riding Properties'
    },
    {
      imagePath: `${imageDirectory}/off-roading`,
      searchUrl: '/United-States/activities-off-roading/',
      title: 'Off Roading Properties for Sale'
    },
    {
      imagePath: `${imageDirectory}/rv`,
      searchUrl: '/United-States/activities-rving/',
      title: 'RV Lots for Sale'
    },
    {
      imagePath: `${imageDirectory}/poultry-farm`,
      searchUrl: '/United-States/present-use-poultry/',
      title: 'Poultry Farms for Sale'
    },
    {
      imagePath: `${imageDirectory}/orchard-vineyard`,
      searchUrl: '/United-States/present-use-orchard-vineyards/',
      title: 'Orchards and Vineyards for Sale'
    }
  ];

  useEffect(() => {
    const currentBreakPoint = utils.getBreakpoint(windowWidth);

    if (currentBreakPoint < BreakPoints.small) {
      setImagesPerSlide(1);
      setColumnStyling('one-column');
    } else if (currentBreakPoint < BreakPoints.medium && currentBreakPoint >= BreakPoints.small) {
      setImagesPerSlide(2);
      setColumnStyling('two-column');
    } else if (currentBreakPoint >= BreakPoints.medium && currentBreakPoint < BreakPoints.largerThanIpadPro) {
      setImagesPerSlide(3);
      setColumnStyling('three-column');
    } else {
      setImagesPerSlide(4);
      setColumnStyling('four-column');
    }
  }, [windowWidth]);

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Search for Properties by Activities</h2>
      <div className={styles['pagination-container']}>
        <HomeCarouselCTX>
          <HomeCarousel
            slideData={slides}
            columnStylingClass={columnStyling}
            imagesPerSlide={imagesPerSlide}
            setCurrentSlideIndex={setCurrentSlideIndex}
            setTotalSlideCount={setTotalSlideCount}
            emblaApi={emblaApi}
            emblaRef={emblaRef}
            imageClickHandler={ga4.mediaEvents.activitiesCarouselImageClicked}
            slideNavigationHandler={ga4.mediaEvents.activitiesCarouselNavigation}
          />
        </HomeCarouselCTX>
        <CarouselPagination
          emblaApi={emblaApi}
          currentSlideIndex={currentSlideIndex}
          numberOfSlides={totalSlideCount}
        />
      </div>
    </div>
  );
}

export default ActivitiesCarousel;
