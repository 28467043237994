import { useEffect, useState } from 'react';
import styles from './NearMeModule.scss';
import Button from 'components/ui/Button';
import { BreakPoints } from 'utils/variables';
import utils from 'utils/convenience';
import HomeCarousel, { HomepageCarouselSlideData } from '../../HomeCarousel';
import useEmblaCarousel from 'embla-carousel-react';
import CarouselPagination from '../../HomeCarousel/CarouselPagination';
import { HomeCarouselCTX } from '../../HomeCarousel/HomeCarouselCTX';
import ga4 from 'utils/GA4';

export default function NearMeModule({ windowWidth }: { windowWidth: number }): JSX.Element {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    loop: false,
    inViewThreshold: 0.7,
    watchDrag: true
  });
  const [imagesPerSlide, setImagesPerSlide] = useState(0);
  const [columnStyling, setColumnStyling] = useState('');
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [totalSlideCount, setTotalSlideCount] = useState(0);
  const imageDirectory = '/assets/images/land/land-homepage/near-me';
  const slides: HomepageCarouselSlideData[] = [
    {
      imagePath: `${imageDirectory}/cabins`,
      searchUrl: '/United-States/cabins/',
      title: 'Cabins for Sale'
    },
    {
      imagePath: `${imageDirectory}/farms`,
      searchUrl: '/United-States/farms/',
      title: 'Farms for Sale'
    },
    {
      imagePath: `${imageDirectory}/ranches`,
      searchUrl: '/United-States/ranches/',
      title: 'Ranches for Sale'
    },
    {
      imagePath: `${imageDirectory}/cheap-land`,
      searchUrl: '/United-States/cheap-land/',
      title: 'Cheap Land for Sale'
    },
    {
      imagePath: `${imageDirectory}/small-farms`,
      searchUrl: '/United-States/hobby-farms/',
      title: 'Hobby Farms for Sale'
    },
    {
      imagePath: `${imageDirectory}/hunting`,
      searchUrl: '/United-States/hunting-land/',
      title: 'Hunting Land for Sale'
    },
    {
      imagePath: `${imageDirectory}/waterfront`,
      searchUrl: '/United-States/waterfront-property/',
      title: 'Waterfront Land for Sale'
    },
    {
      imagePath: `${imageDirectory}/housing`,
      searchUrl: '/United-States/houses-with-land/',
      title: 'Housing with Land for Sale'
    },
    {
      imagePath: `${imageDirectory}/undeveloped`,
      searchUrl: '/United-States/undeveloped-land/',
      title: 'Undeveloped Land for Sale'
    },
    {
      imagePath: `${imageDirectory}/homestead`,
      searchUrl: '/United-States/homesteads/',
      title: 'Homesteads for Sale'
    },
    {
      imagePath: `${imageDirectory}/barndominium`,
      searchUrl: '/United-States/barndominiums/',
      title: 'Barndominiums for Sale'
    },
    {
      imagePath: `${imageDirectory}/cottages`,
      searchUrl: '/United-States/cottages/',
      title: 'Cottages for sale'
    }
  ];

  useEffect(() => {
    const currentBreakPoint = utils.getBreakpoint(windowWidth);
    if (currentBreakPoint < BreakPoints.small) {
      setImagesPerSlide(1);
      setColumnStyling('one-column');
    } else if (currentBreakPoint < BreakPoints.medium && currentBreakPoint >= BreakPoints.small) {
      setImagesPerSlide(2);
      setColumnStyling('two-column');
    } else {
      setImagesPerSlide(3);
      setColumnStyling('three-column');
    }
  }, [windowWidth]);

  const titleHtml = imagesPerSlide ? (
    <>
      Popular Land
      <br />
      for Sale Near Me
    </>
  ) : (
    'Popular Land for Sale Near Me'
  );

  return (
    <div className={styles['pagination-container']}>
      <div className={styles['near-me-carousel-container']}>
        <div className={styles['blurb-container']}>
          <div className={styles.title}>{titleHtml}</div>
          <div className={styles.body}>
            Find land for sale including undeveloped land, residential and commercial lots, and more.
          </div>
          <div className={styles['button-container']}>
            <Button to={'/near-me/all-land/'} className={styles.button} isPrimary>
              Land for Sale Near Me
            </Button>
          </div>
        </div>
        <HomeCarouselCTX>
          <HomeCarousel
            slideData={slides}
            columnStylingClass={columnStyling}
            imagesPerSlide={imagesPerSlide}
            setCurrentSlideIndex={setCurrentSlideIndex}
            setTotalSlideCount={setTotalSlideCount}
            emblaApi={emblaApi}
            emblaRef={emblaRef}
            imageClickHandler={ga4.mediaEvents.nearMeCarouselImageClicked}
            slideNavigationHandler={ga4.mediaEvents.nearMeCarouselNavigation}
          />
        </HomeCarouselCTX>
      </div>
      <CarouselPagination emblaApi={emblaApi} currentSlideIndex={currentSlideIndex} numberOfSlides={totalSlideCount} />
    </div>
  );
}
