import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import InfoTile from '../InfoTile';
import styles from './AreaTiles.scss';

interface Props {
  viewAllLink: string;
  title: string;
  areaCollection: any[];
  areaType: string;
}

function AreaTiles({ areaCollection, title, areaType, viewAllLink }: Props) {
  return (
    <Fragment>
      <h2 className={styles.h1}>{title}</h2>
      <span className={styles['secondary-text']}>Ranked by search trends</span>
      <div>
        {areaCollection &&
          areaCollection.map &&
          areaCollection.map(
            (item, index) =>
              index < 8 && (
                <InfoTile
                  key={item.displayText}
                  displayText={item.displayText}
                  relativeUrlPath={item.relativeUrlPath}
                  count={item.count}
                  index={index + 1}
                  total={item.total}
                  infoType={areaType}
                />
              )
          )}
      </div>
      <Link className={styles['show-all-trigger']} to={viewAllLink}>
        View all {areaType} <span className={styles.arrow} />
      </Link>
    </Fragment>
  );
}

export default AreaTiles;
