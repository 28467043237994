import React from 'react';
import cx from 'classnames';
import styles from './StatTile.scss';

interface Props {
  rank: number;
  name: string;
  value: string;
  count: number;
  areaName: string;
}

const StatTile: React.FunctionComponent<Props> = ({ rank, name, value, count, areaName }) => (
  <div className={cx(styles.main)}>
    <span className={styles.stat}>{name === 'MEDIAN PRICE PER ACRE' ? `${value} / acre` : value}</span>
    <span className={styles.category}>{name} </span>
    <span className={styles.rank}>
      #{rank.toLocaleString()} of {count.toLocaleString()} {areaName}
    </span>
  </div>
);

export default StatTile;
