/* eslint-disable @typescript-eslint/naming-convention */
import { GA4Event } from '../ga4Classes';
import { pushToDataLayer } from '../ga4Helpers';

export function searchLiked() {
  console.warn('TODO');
  const event = new GA4Event({});
  pushToDataLayer(event);
}

export function searchListViewSelected() {
  const event = new GA4Event({
    event: 'list_view_on',
    detailed_event: 'List View On'
  });

  pushToDataLayer(event);
}

export function searchMapViewSelected() {
  const event = new GA4Event({
    event: 'map_view_on',
    detailed_event: 'Map View On'
  });

  pushToDataLayer(event);
}

export function savedSearch() {
  const event = new GA4Event({
    event: 'search_saved',
    detailed_event: 'Search Saved'
  });

  pushToDataLayer(event);
}
