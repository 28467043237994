/* eslint-disable @typescript-eslint/naming-convention */
import { GA4EventData, GA4Event } from '../ga4Classes';
import { getListingLevel, pushToDataLayer } from '../ga4Helpers';
import BrokerDetailsType from 'store/models/brokerDetails';

function toCommonEventData(
  accountId: number,
  listingId: number,
  siteListingId: number,
  listingLevel: number,
  listingUpgradeLevel: string
) {
  const eventData = new GA4EventData({
    account_id: accountId ? accountId.toString() : undefined,
    global_listing_id: listingId ? listingId.toString() : undefined,
    site_listing_id: siteListingId ? siteListingId.toString() : undefined,
    listing_tier: listingLevel ? getListingLevel(listingLevel) : undefined,
    upgrade_level: listingUpgradeLevel ? listingUpgradeLevel : undefined
  });

  return eventData;
}

export function callClickedFromPlacard(
  accountId: number,
  listingId: number,
  siteListingId: number,
  listingLevel: number,
  listingUpgradeLevel: string
) {
  const event = new GA4Event({
    event: 'click_to_call',
    detailed_event: 'Click to Call',
    event_data: toCommonEventData(accountId, listingId, siteListingId, listingLevel, listingUpgradeLevel)
  });

  pushToDataLayer(event);
}

export function callClicked(brokerDetails: BrokerDetailsType) {
  const event = new GA4Event({
    event: 'click_to_call',
    detailed_event: 'Click to Call',
    event_data: toCommonEventData(
      brokerDetails.accountId,
      brokerDetails.listingId,
      brokerDetails.siteListingId,
      brokerDetails.listingLevel,
      brokerDetails.listingUpgradeLevel
    )
  });

  pushToDataLayer(event);
}

export function seePhoneClicked(brokerDetails: BrokerDetailsType) {
  const event = new GA4Event({
    event: 'click_to_see_phone',
    detailed_event: 'Click to See Phone',
    event_data: toCommonEventData(
      brokerDetails.accountId,
      brokerDetails.listingId,
      brokerDetails.siteListingId,
      brokerDetails.listingLevel,
      brokerDetails.listingUpgradeLevel
    )
  });

  pushToDataLayer(event);
}
