import React, { Component } from 'react';
import cx from 'classnames';
import styles from './Tag.scss';

interface Props {
  children: React.ReactNode;
  showX: boolean;
  onRemoveClick?(event);
  isFocused?: boolean;
  className?: string;
  // Right now, the tags directly on the search results page are styled differently from the tags
  // on the location modal and the tags on the home page. I suspect this'll change soon, but for
  // now, there's this hack.
  onSearchResultsPageAndNotInModal?: boolean;
}

export default class Tag extends Component<Props, null> {
  render() {
    const { onRemoveClick, isFocused, className, children, showX } = this.props;
    return (
      <div
        tabIndex={0}
        role="button"
        className={cx(styles.tag, className, { [styles['is-focused']]: isFocused }, { [styles['tag-no-x']]: !showX })}
      >
        <span className={styles.children}>{children}</span>

        {showX ? (
          <span tabIndex={0} role="button" onClick={onRemoveClick} className={styles.remove} aria-label="Remove">
            <span className={styles.x} />
          </span>
        ) : null}
      </div>
    );
  }
}
