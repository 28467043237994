/*
The Google Publisher Tag (GPT) is an ad tagging library for Google Ad Manager which is used to dynamically build ad requests. 
GPT takes key details from you (such as ad unit code, ad size, and key-values), builds the request, and displays the ad on web pages.
Placing GPT on a page creates a communication path between the ad server and a user’s browser. 
Frank Rodriguez manages our Google Ads. He provides us the adUnitPaths and divIDs, we insert them into the codebase using the NativeAd component.  (4/5/2022).
*/

import { SiteIdentity } from "store/commonInterfaces/iSearchObjects";

/*
Additional Notes About Our GPT Tags (4/5/22):
- Home Page/Landing Page Ads: 
    - farmCredit displays on LOTX Home & Landing Pages.  Plus LOA Landing Pages (Not LOA Home).

- Property Detail Page Ads:
    - promoAd displays on LW/LAF non signature listings, below description
    - farmCredit ad displays on all LW/LAF listings
    - detail FC ad displays on LOA/LOTX Paid listings
    - farmCreditFree displays on LOA/LOTX Free listings
    - buyerAgent displays on Marketplaces Free listings

- Search Results Page Ads:
    - bannerAds display on LW & LAF Search Results
*/

interface AdUnit {
  adUnitPath: string;
  adContainerId: string;
}

export interface GPTHome {
  bottomLeaderBoard: AdUnit;
  farmCredit: AdUnit;
  listingCardAd?: AdUnit;
  topLeaderBoard: AdUnit;
  showcaseSpotlight1: AdUnit;
  showcaseSpotlight2: AdUnit;
}

export interface GPTDetail {
  farmCredit: AdUnit;
  farmCreditFree: AdUnit;
  buyerAgent: AdUnit;
  promoAd: AdUnit;
}

export interface GPTSearch {
  bamAd: AdUnit;
  bamAdV2?: AdUnit;
  bannerAds: AdUnit[];
  brokerAd: AdUnit;
  farmCredit: AdUnit;
  farmCreditText?: string;
  preferredAd: AdUnit;
  preferredAdV2?: AdUnit;
  promoAd: AdUnit;
  universalFCAd: AdUnit;
  universalFCAdV2?: AdUnit;
}

export const sharedHomeAds = {
  showcaseSpotlight1: {
    adUnitPath: 'Homepage_Spotlight_Ads/Homepage_Spotlight_1',
    adContainerId: 'div-gpt-ad-1721929303285-0'
  },
  showcaseSpotlight2: {
    adUnitPath: 'Homepage_Spotlight_Ads/Homepage_Spotlight_2',
    adContainerId: 'div-gpt-ad-1721929493456-0'
  }
};

// Home Page //
////////////////////////////////////
export const LW_GPT_HOME: GPTHome = {
  topLeaderBoard: { adUnitPath: 'lw_national_leader_p1', adContainerId: 'div-gpt-ad-1589227215111-0' },
  bottomLeaderBoard: { adUnitPath: 'lw_national_leader_p2', adContainerId: 'div-gpt-ad-1589227388493-0' },
  farmCredit: null,
  ...sharedHomeAds
};

export const LAF_GPT_HOME: GPTHome = {
  topLeaderBoard: { adUnitPath: 'LAF_National_Leaderboard_P1', adContainerId: 'div-gpt-ad-1645655809919-0' },
  bottomLeaderBoard: { adUnitPath: 'LAF_National_Leaderboard_P2', adContainerId: 'div-gpt-ad-1645656952991-0' },
  farmCredit: null,
  listingCardAd: null,
  ...sharedHomeAds
};

export const LOA_GPT_HOME: GPTHome = {
  topLeaderBoard: { adUnitPath: 'land_landing_leaderbaord_P1', adContainerId: 'div-gpt-ad-1546957792029-0' },
  bottomLeaderBoard: { adUnitPath: 'land_landing_leaderbaord_P2', adContainerId: 'div-gpt-ad-1546957918981-0' },
  farmCredit: { adUnitPath: 'LOA_FC_Logo_With_Text', adContainerId: 'div-gpt-ad-1543947624826-0' },
  listingCardAd: null,
  ...sharedHomeAds
};

export const LAND_GPT_HOME: GPTHome = {
  topLeaderBoard: { adUnitPath: 'land_landing_leaderbaord_P1', adContainerId: 'div-gpt-ad-1546957792029-0' },
  bottomLeaderBoard: { adUnitPath: 'land_landing_leaderbaord_P2', adContainerId: 'div-gpt-ad-1546957918981-0' },
  farmCredit: { adUnitPath: 'LOA_FC_Logo_With_Text', adContainerId: 'div-gpt-ad-1543947624826-0' },
  listingCardAd: { adUnitPath: 'Network_Homepage_Featured_Partner_Card', adContainerId: 'div-gpt-ad-1658954764779-0' },
  ...sharedHomeAds
};

/* Property Detail Page */
////////////////////////////////////
export const LW_GPT_DETAIL: GPTDetail = {
  promoAd: { adUnitPath: 'LW_Details_Promo_Strip_Fluid', adContainerId: 'div-gpt-ad-1597946288826-0' },
  farmCredit: { adUnitPath: 'LW_Details_Text_Ad_FC', adContainerId: 'div-gpt-ad-1594761383353-0' },
  farmCreditFree: null,
  buyerAgent: { adUnitPath: 'LW_Details_Buyers_Rep_Module', adContainerId: 'div-gpt-ad-1615836917078-0' }
};

export const LAF_GPT_DETAIL: GPTDetail = {
  promoAd: { adUnitPath: 'LAF_Details_Promo_Strip_Fluid', adContainerId: 'div-gpt-ad-1645718368770-0' },
  farmCredit: { adUnitPath: 'LAF_Listing_Details_Text_Ad_FC', adContainerId: 'div-gpt-ad-1645723848018-0' },
  farmCreditFree: null,
  buyerAgent: { adUnitPath: 'LAF_Details_Buyers_Rep_Module', adContainerId: 'div-gpt-ad-1645736596638-0' }
};

export const LOA_GPT_DETAIL: GPTDetail = {
  promoAd: null,
  farmCredit: { adUnitPath: 'LOA_Paid_Listings', adContainerId: 'div-gpt-ad-1544023433554-0' },
  farmCreditFree: { adUnitPath: 'LOA_Free_Listings_P1', adContainerId: 'div-gpt-ad-1544023534289-0' },
  buyerAgent: { adUnitPath: 'LOA_Details_Buyers_Rep_Module', adContainerId: 'div-gpt-ad-1615837331637-0' }
};

/* Search Page */
///////////////////////////////////
const UNIVERSAL_GPT_SEARCH = {
  preferredAd: {
    adUnitPath: 'Universal_Native_Ad/Universal_Preferred_Ad',
    adContainerId: 'div-gpt-ad-1671125901175-0'
  },
  FCAd: { adUnitPath: 'Universal_Native_Ad/Universal_Results_FC_Ad', adContainerId: 'div-gpt-ad-1671126058425-0' }
};

export const LW_GPT_SEARCH: GPTSearch = {
  bannerAds: [
    { adUnitPath: 'LW_Inline_Banner/LW_Inline_Banner_P1', adContainerId: 'div-gpt-ad-1591213645778-0' },
    { adUnitPath: 'LW_Inline_Banner/LW_Inline_Banner_P2', adContainerId: 'div-gpt-ad-1591213792757-0' },
    { adUnitPath: 'LW_Inline_Banner/LW_Inline_Banner_P3', adContainerId: 'div-gpt-ad-1591213933178-0' }
  ],
  bamAd: { adUnitPath: 'lw_searchresults_bam', adContainerId: 'div-gpt-ad-1593182955376-0' },
  farmCredit: null,
  universalFCAd: UNIVERSAL_GPT_SEARCH.FCAd,
  promoAd: { adUnitPath: 'LW_Promo_Strip', adContainerId: 'div-gpt-ad-1591889766026-0' },
  preferredAd: UNIVERSAL_GPT_SEARCH.preferredAd,
  brokerAd: null
};

export const LAF_GPT_SEARCH: GPTSearch = {
  bannerAds: [
    {
      adUnitPath: 'LAF_Inline_Results_Banner/LAF_Inline_Results_Banner_P1',
      adContainerId: 'div-gpt-ad-1645658601235-0'
    },
    {
      adUnitPath: 'LAF_Inline_Results_Banner/LAF_Inline_Results_Banner_P2',
      adContainerId: 'div-gpt-ad-1645660431693-0'
    },
    {
      adUnitPath: 'LAF_Inline_Results_Banner_V2/LAF_Inline_Results_Banner_P1_V2', // after 29
      adContainerId: 'div-gpt-ad-1706818421992-0'
    },
    {
      adUnitPath: 'LAF_Inline_Results_Banner_V2/LAF_Inline_Results_Banner_P2_V2', // after 34
      adContainerId: 'div-gpt-ad-1706819136092-0'
    }
  ],
  bamAd: { adUnitPath: 'LAF_SearchResults_BAM', adContainerId: 'div-gpt-ad-1645722934362-0' },
  bamAdV2: { adUnitPath: 'LAF_SearchResults_BAM_V2', adContainerId: 'div-gpt-ad-1706819746240-0' }, // after 27
  farmCredit: null, // Nulling this because farm credit will be back in ticket #2045486
  preferredAd: UNIVERSAL_GPT_SEARCH.preferredAd,
  preferredAdV2: {
    adUnitPath: 'Universal_Native_Ad_V2/Universal_Results_FC_Ad_V2', // after 39
    adContainerId: 'div-gpt-ad-1706820018162-0'
  },
  universalFCAd: UNIVERSAL_GPT_SEARCH.FCAd,
  universalFCAdV2: {
    adUnitPath: 'Universal_Native_Ad_V2/Universal_Preferred_Ad_V2', // after 50
    adContainerId: 'div-gpt-ad-1707159399634-0'
  },
  promoAd: { adUnitPath: 'LAF_Results_Promo_Strip_Fluid', adContainerId: 'div-gpt-ad-1645662127655-0' },
  brokerAd: null
};

export const LOA_GPT_SEARCH: GPTSearch = {
  bannerAds: null,
  bamAd: { adUnitPath: 'LOA_SearchResults_BAM', adContainerId: 'div-gpt-ad-1536940802994-0' },
  farmCredit: null, // Nulling this because farm credit will be back in ticket #2045486
  preferredAd: UNIVERSAL_GPT_SEARCH.preferredAd,
  universalFCAd: UNIVERSAL_GPT_SEARCH.FCAd,
  farmCreditText: null,
  promoAd: null,
  brokerAd: { adUnitPath: 'LOA_Results_Broker_Ad_P1', adContainerId: 'div-gpt-ad-1543512658699-0' }
};

// Get our siteSpecific & page specific Google Publisher Tags, which render our Ads.
export const getGPTAds = (siteIdentity: SiteIdentity, pageType: string) => {
  let ads: GPTHome | GPTDetail | GPTSearch;

  if (siteIdentity.isLAF) {
    ads = pageType === 'HOME' ? LAF_GPT_HOME : pageType === 'DETAIL' ? LAF_GPT_DETAIL : LAF_GPT_SEARCH;
  } else if (siteIdentity.isLW) {
    ads = pageType === 'HOME' ? LW_GPT_HOME : pageType === 'DETAIL' ? LW_GPT_DETAIL : LW_GPT_SEARCH;
  } else if (siteIdentity.isLand) {
    ads = pageType === 'HOME' ? LAND_GPT_HOME : pageType === 'DETAIL' ? LOA_GPT_DETAIL : LOA_GPT_SEARCH;
  } else {
    ads = pageType === 'HOME' ? LOA_GPT_HOME : pageType === 'DETAIL' ? LOA_GPT_DETAIL : LOA_GPT_SEARCH;
  }
  return ads;
};
