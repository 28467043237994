import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './HomeCarousel.scss';
import cx from 'classnames';
import { getSlides, getLastSegmentOfPath } from './HomeCarouselHelper';
import { HomeCarouselContext } from './HomeCarouselCTX';

export interface HomepageCarouselSlideData {
  imagePath: string;
  searchUrl: string;
  title: string;
}

function HomeCarousel({
  slideData,
  columnStylingClass,
  imagesPerSlide,
  emblaApi,
  emblaRef,
  setCurrentSlideIndex,
  setTotalSlideCount,
  imageClickHandler,
  slideNavigationHandler
}: {
  slideData: HomepageCarouselSlideData[];
  columnStylingClass: string;
  imagesPerSlide: number;
  emblaApi: any;
  emblaRef: any;
  setCurrentSlideIndex: (index: number) => void;
  setTotalSlideCount: (count: number) => void;
  imageClickHandler: (eventData: string) => void;
  slideNavigationHandler: (lastVisibleImage: number, totalImages: number) => void;
}): JSX.Element {
  const { isHovered, setIsHovered } = useContext(HomeCarouselContext);
  const [slides, setSlides] = useState<JSX.Element[][]>([[]]);

  const getSlideImage = (slides: HomepageCarouselSlideData[]): JSX.Element[] => {
    return slides.map((val, index) => (
      <div className={styles['embla__slide__img']} key={index}>
        <Link
          to={val.searchUrl}
          onClick={() => {
            imageClickHandler(getLastSegmentOfPath(val.searchUrl));
          }}
        >
          <div className={styles['lazy-load-container']}>
            <LazyLoad height={'100%'}>
              <picture>
                <source type="image/webp" srcSet={`${val.imagePath}.webp`} />
                <source type="image/jpeg" srcSet={`${val.imagePath}.jpg`} />
                <img className={styles.image} src={`${val.imagePath}.jpg`} alt={`${val.searchUrl}`} />
              </picture>
            </LazyLoad>
          </div>
          <div className={styles['embla__slide__overlay']}>
            <div className={styles['embla__slide__title']}>{val.title}</div>
          </div>
        </Link>
      </div>
    ));
  };

  const totalImagesViewed = useCallback(slideIndex => (slideIndex + 1) * imagesPerSlide, [imagesPerSlide]);

  const initSlides = () => {
    if (!emblaApi) {
      return;
    }

    const slideJsx = getSlides(slideData, imagesPerSlide, getSlideImage);
    setTotalSlideCount(slideJsx.length);
    setSlides(slideJsx);
  };

  const scrollPrev = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollPrev();
    }
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollNext();
    }
  }, [emblaApi]);

  const findSlideInView = useCallback(() => {
    if (!emblaApi) {
      return;
    }

    // Find the index of the slide that is currently in view.
    const inView = emblaApi.slidesInView();
    setCurrentSlideIndex(inView[0]);
  }, [emblaApi, setCurrentSlideIndex]);

  const slideNavigationCallback = useCallback(
    slideIndex => {
      slideNavigationHandler(totalImagesViewed(slideIndex), slideData.length);
    },
    [slideNavigationHandler, totalImagesViewed, slideData]
  );

  const handleSelect = useCallback(() => {
    const previousSlideIndex = emblaApi.previousScrollSnap();
    const currentSlideIndex = emblaApi.selectedScrollSnap();

    // Check if scrolling forward
    if (currentSlideIndex > previousSlideIndex) {
      slideNavigationCallback(currentSlideIndex);
    }

    findSlideInView();
  }, [emblaApi, findSlideInView, slideNavigationCallback]);

  useEffect(() => {
    if (!emblaApi) {
      return undefined;
    }
    initSlides();
    findSlideInView();
    emblaApi.on('select', handleSelect);
    emblaApi.on('reInit', findSlideInView);
    // Executes when slide in the view port changes
    emblaApi.on('slidesInView', findSlideInView);
    return () => {
      emblaApi.off('select', handleSelect);
      emblaApi.off('reInit', findSlideInView);
      emblaApi.off('slidesInView', findSlideInView);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emblaApi, imagesPerSlide]);

  // The carousel cannot be rendered on the server side, so we render a list of links instead.
  const ssrLinks = !emblaApi
    ? slideData.map((slide, index) => (
        <a href={slide.searchUrl} key={index}>
          {slide.title}
        </a>
      ))
    : [];

  return (
    <Fragment>
      <div
        id="popular-search-carousel"
        className={cx(
          styles['embla'],
          styles[columnStylingClass],
          imagesPerSlide < 3 ? styles.mobile : styles.desktop,
          isHovered ? styles['hover'] : ''
        )}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className={styles['embla__viewport']} ref={emblaRef}>
          <div className={styles['embla__container']}>
            {!emblaApi && ssrLinks}
            {slides.map((slide, index) => (
              <div className={styles['embla__slide']} key={index}>
                {slide}
              </div>
            ))}
          </div>
        </div>
        <>
          <button
            onClick={scrollPrev}
            data-qa-placard-carousel-prev={true}
            data-testid="previous"
            aria-label="Previous"
            className={styles.button}
          >
            <div className={styles['embla__prev']} />
            <span className={cx(styles.icon, styles['prev-icon'])} />
          </button>
          <button
            onClick={scrollNext}
            data-qa-placard-carousel-next={true}
            data-testid="next"
            aria-label="Next"
            className={styles.button}
          >
            <div className={styles['embla__next']} />
            <span className={cx(styles.icon, styles['next-icon'])} />
          </button>
        </>
      </div>
    </Fragment>
  );
}

export default HomeCarousel;
