import { useState, useCallback } from 'react';
import cx from 'classnames';
import Picture from 'components/ui/Picture';
import LoadingIndicator from 'components/ui/LoadingIndicator';
import { PropertyListing, SiteIdentity } from 'store/commonInterfaces/iSearchObjects';
import { tagQa } from '../../PlacardHelpers';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './PlacardCarouselSlide.module.scss';

const PlacardCarouselSlide = ({
  listing,
  imgSrc,
  index,
  isMapView,
  listingType,
  siteIdentity,
  isSingleSlide,
  shouldRenderSlide
}: {
  listing: PropertyListing;
  imgSrc: string;
  index: number;
  isMapView: boolean;
  listingType: string;
  siteIdentity: SiteIdentity;
  isSingleSlide: boolean;
  shouldRenderSlide: boolean;
}) => {
  // hasLoaded is used to apply our transition animation to images as they load into view.
  // It also displays the loading indicator until the image has loaded.
  const [hasLoaded, setHasLoaded] = useState(false);

  const setLoaded = useCallback(() => {
    setHasLoaded(true);
  }, [setHasLoaded]);

  return shouldRenderSlide ? (
    <div
      className={cx(
        isSingleSlide ? styles['single-slide-carousel'] : styles['multi-slide-carousel'],
        styles['embla__slide'],
        styles[`level-${listingType}${isMapView ? '-map' : ''}`]
      )}
      {...tagQa('placard-carousel', true)}
    >
      <div
        className={cx(
          styles['embla__lazy-load'],
          hasLoaded && !isSingleSlide ? styles['embla__lazy-load--has-loaded'] : ''
        )}
      >
        {!hasLoaded && index > 0 && (
          <div className={styles['embla__lazy-load__spinner']}>
            <LoadingIndicator message={!siteIdentity.isLand && 'Loading Results'} />
          </div>
        )}

        <Picture
          onloadFn={setLoaded}
          className={cx(styles['embla__lazy-load__img'], styles['embla__slide__img'])}
          src={imgSrc}
          isLazy={!isMapView}
          offset={isMapView ? 0 : 40}
          alt={listing.imageAltTextDisplay}
        />
      </div>
    </div>
  ) : (
    <div className={styles['embla__slide']} />
  );
};

export default PlacardCarouselSlide;
