import { Component } from 'react';
import { withRouter } from 'react-router';
import { Location } from 'history';
import Search from 'components/pages/PropertySearch/Search';
import Button from 'components/ui/Button';
import { SITE_IDS } from 'utils/enums';
import { Helmet } from 'react-helmet-async';
import GlobalHandler from 'handler/globalHandler';
import GlobalStore from 'store/globalStore';
import styles from './Hero.scss';
import Restricted from 'components/ui/Restricted';

interface Props {
  store: GlobalStore;
  handler: GlobalHandler;
  location: Location;
  pageHeader: string;
  pageSubHeader: string;
  stateName: string;
  toggleMapUI: any;
}

class Hero extends Component<Props> {
  constructor(props) {
    super(props);
  }

  render() {
    const { store, handler, location, pageHeader, pageSubHeader, stateName, toggleMapUI } = this.props;
    const isHomePage = location.pathname === '/';
    const heroFileName = isHomePage ? 'united-states' : !!stateName && stateName.replace(/ /g, '-').toLowerCase();

    return (
      <section className={styles.main}>
        {!!heroFileName && (
          <Restricted to={SITE_IDS.LAND}>
            <Helmet>
              <link
                rel="preload"
                as="image"
                href={`/assets/images/state-hero/webp/mobile/${heroFileName}.webp`}
                media="(max-width: 601px)"
              />
              <link
                rel="preload"
                as="image"
                href={`/assets/images/state-hero/webp/tablet/${heroFileName}.webp`}
                media="(min-width: 602px) and (max-width: 901px)"
              />
              <link
                rel="preload"
                as="image"
                href={`/assets/images/state-hero/webp/desktop/${heroFileName}.webp`}
                media="(min-width: 902px)"
              />
            </Helmet>
            <picture>
              <source
                type="image/webp"
                srcSet={`/assets/images/state-hero/webp/mobile/${heroFileName}.webp`}
                media="(max-width: 601px)"
              />
              <source
                type="image/webp"
                srcSet={`/assets/images/state-hero/webp/tablet/${heroFileName}.webp`}
                media="(min-width: 602px) and (max-width: 901px)"
              />
              <source
                type="image/webp"
                srcSet={`/assets/images/state-hero/webp/desktop/${heroFileName}.webp`}
                media="(min-width: 902px)"
              />
              <source
                type="image/jpeg"
                srcSet={`/assets/images/state-hero/jpg/mobile/${heroFileName}.jpg`}
                media="(max-width: 601px)"
              />
              <source
                type="image/jpeg"
                srcSet={`/assets/images/state-hero/jpg/tablet/${heroFileName}.jpg`}
                media="(min-width: 602px) and (max-width: 901px)"
              />
              <source
                type="image/jpeg"
                srcSet={`/assets/images/state-hero/jpg/desktop/${heroFileName}.jpg`}
                media="(min-width: 902px)"
              />
              <img
                className={styles.hero}
                src={`/assets/images/state-hero/jpg/desktop/${heroFileName}.jpg`}
                alt="hero"
              />
            </picture>
          </Restricted>
        )}
        <div className={styles['opaque-background']}>
          <header className={styles.header}>
            <h1 className={styles['main-header']}>
              <span>{pageSubHeader}</span>
            </h1>
            <Restricted not={SITE_IDS.LANDWATCH}>
              <h2 className={styles.h2}>{pageHeader}</h2>
            </Restricted>
          </header>
          <div className={styles['search--placeholder']}>
            <Search isHomeRoute={true} handler={handler} store={store} />
          </div>
          {isHomePage && (
            <Restricted to={SITE_IDS.LANDWATCH}>
              <Button className={styles['search-by-state']} onClick={toggleMapUI}>
                <span className={styles['sbs-span']}>Search by Map</span>
              </Button>
            </Restricted>
          )}
        </div>
      </section>
    );
  }
}

export default withRouter(Hero);
