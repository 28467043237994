import React from 'react';
import cx from 'classnames';
import styles from './SearchWrapper.scss';

interface Props {
  isHomeRoute?: boolean;
  shouldUnstick?: boolean;
  children: React.ReactNode;
}

const SearchWrapper: React.FunctionComponent<Props> = ({ children, isHomeRoute, shouldUnstick }) => {
  if (isHomeRoute) {
    return <>{children}</>;
  }

  return <div className={cx(styles.main, shouldUnstick && styles.unstick)}> {children}</div>;
};

export default SearchWrapper;
