import React from 'react';
import cx from 'classnames';
import styles from './Loading.scss';

interface Props {
  grayDots?: boolean;
  bigDots?: boolean;
  blackDots?: boolean;
  containerClass?: string;
  withOpacity?: boolean;
}

const Loading: React.FunctionComponent<Props> = ({
  grayDots = false,
  bigDots = false,
  blackDots = false,
  containerClass = null,
  withOpacity = false
}) => {
  const classes = cx(grayDots && styles.gray, bigDots && styles.big, blackDots && styles.black);

  return (
    <div className={cx(withOpacity && styles.main, containerClass)}>
      <div className={styles.ellipsis}>
        {[1, 2, 3, 4].map(num => (
          <div key={num} className={classes} />
        ))}
      </div>
    </div>
  );
};

export default Loading;
