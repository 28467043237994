/* eslint-disable @typescript-eslint/naming-convention */
import { GA4Event, GA4Ecommerce, GA4EcommerceItem, GA4EventData } from '../ga4Classes';
import { DetailsAPIProperty, PropertyListing, SiteIdentity } from 'store/commonInterfaces/iSearchObjects';
import {
  pushToDataLayer,
  getSiteBrand,
  getListingLevel,
  getUpgradeLevelString,
  GA4_SITE_LOCATIONS
} from '../ga4Helpers';

export function downloadLinkClicked(fileName: string) {
  const eventData = new GA4EventData({
    file_name: fileName
  });

  const event = new GA4Event({
    event: 'file_download',
    detailed_event: 'Download Link Clicked',
    event_data: eventData
  });

  pushToDataLayer(event);
}

export function listingShared(method: string) {
  const eventData = new GA4EventData({
    method: method
  });

  const event = new GA4Event({
    event: 'share',
    detailed_event: 'Share Listing',
    event_data: eventData
  });

  pushToDataLayer(event);
}

export function listingViewed(listing: DetailsAPIProperty, siteIdentity: SiteIdentity) {
  const ecommerceItem = new GA4EcommerceItem({
    affiliation: listing.accountId ? listing.accountId.toString() : undefined,
    item_brand: getSiteBrand(siteIdentity),
    item_category: listing.listingLevel ? getListingLevel(listing.listingLevel) : undefined,
    item_category4: listing.parentAccountId ? listing.parentAccountId.toString() : undefined,
    item_id: listing.siteListingId ? listing.siteListingId.toString() : undefined,
    item_name: listing.listingId ? listing.listingId.toString() : undefined,
    item_variant: getUpgradeLevelString(listing.upgradeLevelId)
  });

  const ecommerce = new GA4Ecommerce({
    currency: 'USD',
    items: [ecommerceItem]
  });

  const event = new GA4Event({
    event: 'view_item',
    detailed_event: 'Product Viewed',
    ecommerce: ecommerce
  });

  pushToDataLayer(event);
}

export function listingWebsiteClicked() {
  const event = new GA4Event({
    event: 'listing_website_click',
    detailed_event: 'Listing Website Click'
  });

  pushToDataLayer(event);
}

export function listingResultClicked(
  listing: PropertyListing,
  siteIdentity: SiteIdentity,
  siteLocation: GA4_SITE_LOCATIONS
) {
  const ecommerceItem = new GA4EcommerceItem({
    affiliation: listing.accountId ? listing.accountId.toString() : undefined,
    item_brand: getSiteBrand(siteIdentity),
    item_category: listing.listingLevel ? getListingLevel(listing.listingLevel) : undefined,
    item_id: listing.siteListingId ? listing.siteListingId.toString() : undefined,
    item_name: listing.id ? listing.id.toString() : undefined,
    item_variant: getUpgradeLevelString(listing.upgradeLevelId)
  });

  const ecommerce = new GA4Ecommerce({
    item_list_id: undefined,
    item_list_name: `${siteLocation} Results`,
    items: [ecommerceItem]
  });

  const event = new GA4Event({
    event: 'select_item',
    detailed_event: 'Product Listing Item Clicked',
    ecommerce: ecommerce
  });

  pushToDataLayer(event);
}

export function listingResultsViewed(
  listings: PropertyListing[],
  pageIndex: number,
  siteIdentity: SiteIdentity,
  siteLocation: GA4_SITE_LOCATIONS
) {
  const isNotHomePage = siteLocation !== GA4_SITE_LOCATIONS.HOMEPAGE;
  const ecommerceItems: GA4EcommerceItem[] = listings.map((listing, index) => {
    const pageNumber = pageIndex != null ? pageIndex + 1 : undefined;
    const listingIndex = index + 1;

    return new GA4EcommerceItem({
      affiliation: listing.accountId ? listing.accountId.toString() : undefined,
      item_brand: getSiteBrand(siteIdentity),
      item_category: listing.listingLevel ? getListingLevel(listing.listingLevel) : undefined,
      item_category2: isNotHomePage && pageNumber ? pageNumber.toString() : undefined,
      item_category3: isNotHomePage ? listingIndex.toString() : undefined,
      item_category4: listing.parentAccountId ? listing.parentAccountId.toString() : undefined,
      item_id: listing.siteListingId ? listing.siteListingId.toString() : undefined,
      item_name: listing.id ? listing.id.toString() : undefined,
      item_variant: getUpgradeLevelString(listing.upgradeLevelId)
    });
  });

  const ecommerce = new GA4Ecommerce({
    item_list_id: undefined,
    item_list_name: `${siteLocation} Results`,
    items: ecommerceItems
  });

  const event = new GA4Event({
    event: 'view_item_list',
    detailed_event: 'Product Listing Displayed',
    ecommerce: ecommerce
  });

  pushToDataLayer(event);
}
