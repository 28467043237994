import { ReactNode, createContext, useState } from 'react';

export const HomeCarouselContext = createContext(null);

export function HomeCarouselCTX({ children }: { children: ReactNode }) {
  const [isPlacardHovered, setIsPlacardHovered] = useState(false);

  return (
    <HomeCarouselContext.Provider
      value={{
        isHovered: isPlacardHovered,
        setIsHovered: setIsPlacardHovered
      }}
    >
      {children}
    </HomeCarouselContext.Provider>
  );
}
