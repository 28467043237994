import React from 'react';
import styles from './PlacardPriceChangeTag.scss';
import cx from 'classnames';

interface PlacardPriceChangeTagProps {
  priceChangeAmount: number;
  shortPriceChangeAmount: string;
}

const PlacardPriceChangeTag: React.FC<PlacardPriceChangeTagProps> = ({ priceChangeAmount, shortPriceChangeAmount }) => {
  const hasPriceDecreased = priceChangeAmount < 0;

  return (
    <div className={cx(hasPriceDecreased ? styles['decrease'] : styles['increase'], styles['tag-container'])}>
      <span className={styles['price-change']}>{shortPriceChangeAmount}</span>
      <span className={styles['icon']} />
      <span />
    </div>
  );
};

export default PlacardPriceChangeTag;
