import Picture from 'components/ui/Picture';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { PropertyListing, SiteIdentity } from 'store/commonInterfaces/iSearchObjects';
import { getPropertyCardImageData, PropertyCardProps } from 'utils/propertyCard';
import { ImageSizesPx } from 'utils/variables';
import { PlacardLocation, getLevelName, logPlacardListingClickedEvent, tagQa } from '../PlacardHelpers';

import cx from 'classnames';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './PlacardImage.scss';

export default function PlacardImage({
  siteIdentity,
  children,
  listing,
  imageDomain,
  isLazy,
  isMobileDevice,
  isTabletDevice,
  windowWidth,
  placardLocation,
  listingType,
  isMapView = false,
  isSimilarResult
}: {
  siteIdentity: SiteIdentity;
  children: ReactNode;
  listing: PropertyListing;
  imageDomain: string;
  isLazy: boolean;
  isMobileDevice: boolean;
  isTabletDevice: boolean;
  windowWidth?: number;
  placardLocation: PlacardLocation;
  listingType: string;
  isMapView?: boolean;
  isSimilarResult?: boolean;
}) {
  const cardProps = {
    listing,
    isPlacard: true,
    isHome: placardLocation === PlacardLocation.Home,
    isSimilarResult
  } as PropertyCardProps;
  const { imageSrc, imageSources } = getPropertyCardImageData(
    cardProps,
    imageDomain,
    isMobileDevice,
    isTabletDevice,
    windowWidth,
    siteIdentity
  );
  const { canonicalUrl, relativeUrl, isPlatinum, isGold, isShowcase, isDiamond } = listing;

  const levelName = getLevelName(listing.listingLevel);
  const adName = isShowcase
    ? 'showcase'
    : isDiamond
      ? 'diamond'
      : isPlatinum
        ? 'platinum'
        : isGold
          ? 'gold'
          : null;

  const placeholderStyle = ImageSizesPx[adName || levelName];

  return (
    <div
      id="placard-image"
      className={cx(isMapView ? styles['map-view'] : '', styles[`level-${listingType}`], styles['placard-image'])}
      {...tagQa('placard-image', true)}
    >
      <Link
        to={canonicalUrl || relativeUrl}
        onClick={() => logPlacardListingClickedEvent(listing, siteIdentity, placardLocation)}
        aria-label="Listing Details Page"
      >
        <Picture
          alt={listing.imageAltTextDisplay}
          className={styles['placard-picture']}
          src={imageSrc}
          sources={imageSources}
          isLazy={isLazy}
          placeholder={
            <div style={placeholderStyle}>
              <img
                style={placeholderStyle}
                src="/assets/images/property-placeholder.webp"
                alt={listing.imageAltTextDisplay}
              />
            </div>
          }
          offset={isMapView ? 0 : 40}
        />
      </Link>
      {children}
    </div>
  );
}
