import { ReactNode } from 'react';
import { PropertyListing, SiteIdentity } from 'store/commonInterfaces/iSearchObjects';
import { PlacardLocation, logPlacardListingClickedEvent, tagQa } from '../PlacardHelpers';
import { Link } from 'react-router-dom';
import styles from './PlacardHeader.scss';
import cx from 'classnames';

// Only render on Property Search page for Diamond Listings
export function PlacardHeader({
  siteIdentity,
  children,
  listing,
  placardLocation
}: {
  siteIdentity: SiteIdentity;
  children?: ReactNode;
  listing: PropertyListing;
  placardLocation: PlacardLocation;
}) {
  const { address, city, stateCode, zip, county, canonicalUrl } = listing;
  const title = listing.title || `${listing.acresDisplay} in ${county}, ${stateCode}`;

  return (
    <div className={cx(styles['placard-header-body'])}>
      <div className={styles['placard-header-info']} {...tagQa('placardheaderinfo', true)}>
        <div className={styles['placard-detail']}>
          <div className={styles['placard-header-title']}>
            <Link
              to={canonicalUrl}
              onClick={() => logPlacardListingClickedEvent(listing, siteIdentity, placardLocation)}
            >
              {title}
            </Link>
          </div>
          <div className={styles.address}>
            <Link
              to={canonicalUrl}
              onClick={() => logPlacardListingClickedEvent(listing, siteIdentity, placardLocation)}
            >
              <p className={styles.address1}>
                {`${address ? address + ', ' : ''}${city}, ${stateCode}, ${zip}, ${county}`}
              </p>
            </Link>
          </div>
        </div>
        <>
          <div>{children}</div>
        </>
      </div>
    </div>
  );
}
