/* eslint-disable @typescript-eslint/naming-convention */
import { SearchCriteriaOptions } from 'store/commonInterfaces/iSearchObjects';
import { TRANSACTION_TYPES, MARKET_STATUSES, DATE_LISTED, LOCATION_TYPES, SITE_IDS } from './enums';
import { DefaultMarketStatuses } from 'store/models/searchCriteria';
import { getLatLong } from './convenience';

const stateNames = { '0': 'United-States' };
const stateAbbreviations = {};
const regionNames = {};
const countyNames = {};
const cityNames = {};
const lakeNames = {};
const propertyTypeNames = {};
const locallyTransformableProperties: string[] = [
  'stateId',
  'propertyTypes',
  'priceMin',
  'priceMax',
  'acresMin',
  'acresMax',
  'sqftMin',
  'sqftMax'
];

// Checks if provided URL begins with a valid protocol, adds http:// or https:// if it does not.
// Certain functions that open links, such as window.open(), will append to the current url
// instead of replacing the current url if no protocol is present.
export function addUrlProtocol(url: string, useSecure = false) {
  const secureProtocol = 'https://';
  const protocol = 'http://';

  if (!url || url?.toLowerCase().startsWith(protocol) || url?.toLowerCase().startsWith(secureProtocol)) {
    return url;
  }

  return useSecure ? secureProtocol + url : protocol + url;
}

async function getLocationName(type: LOCATION_TYPES, id: number): Promise<string> {
  const raw = await fetch(`/api/location/locationName/${type}/${id}/0/`);
  return raw.ok ? (await raw.json()).replace(/ /g, '-') : null;
}

async function getCountyName(id: number): Promise<string> {
  if (!countyNames[id]) {
    countyNames[id] = getLocationName(LOCATION_TYPES.COUNTY, id);
  }

  return await countyNames[id];
}

async function getStateAbbreviation(id: number): Promise<string> {
  if (!stateAbbreviations[id]) {
    stateAbbreviations[id] = getLocationName(LOCATION_TYPES.STATEABBR, id);
  }

  return await stateAbbreviations[id];
}

async function getStateName(id: number): Promise<string> {
  if (!stateNames[id]) {
    stateNames[id] = getLocationName(LOCATION_TYPES.STATE, id);
  }

  return await stateNames[id];
}

async function getCityName(id: number): Promise<string> {
  if (!cityNames[id]) {
    cityNames[id] = getLocationName(LOCATION_TYPES.CITY, id);
  }

  return await cityNames[id];
}

async function getRegionName(id: number): Promise<string> {
  if (!regionNames[id]) {
    regionNames[id] = getLocationName(LOCATION_TYPES.REGION, id);
  }

  return await regionNames[id];
}

async function getLakeName(id: number): Promise<string> {
  if (!lakeNames[id]) {
    lakeNames[id] = getLocationName(LOCATION_TYPES.LAKE, id);
  }

  return await lakeNames[id];
}

async function getPropertyTypeName(id: number, siteId: SITE_IDS): Promise<string> {
  if (!propertyTypeNames[id]) {
    const raw = await fetch(`/api/property/type/${id}/name/${siteId}`);
    propertyTypeNames[id] = raw.ok ? (await raw.json()).replace(/ /g, '-') : null;
  }

  return await propertyTypeNames[id];
}

export async function fromSearchCriteria(sc: SearchCriteriaOptions, siteId: number, withAllLand = true) {
  let searchPath = '';
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  if (siteId === SITE_IDS.LAND && canBeTransformedLocally(sc)) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    searchPath = await transformToSearchPathLocally(sc, siteId, withAllLand);
  } else {
    const request: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/plain'
      },
      body: JSON.stringify(sc)
    };

    const response = await fetch(`/api/property/searchUrl/${siteId}`, request);

    if (response.ok) {
      searchPath = await response.text();
    }
  }

  return searchPath;
}

// This function is only used internally to this file but is being exported for unit
// testing purposes.
export function canBeTransformedLocally(sc: SearchCriteriaOptions): boolean {
  if (sc?.userSavedProperties) {
    return true;
  }

  // This will always return false. We should remove or refactor this function. User story 2543837
  for (const [key, value] of Object.entries(sc)) {
    if (value !== null && !locallyTransformableProperties.includes(key)) {
      return false;
    }
  }

  return true;
}

async function transformToSearchPathLocally(
  sc: SearchCriteriaOptions,
  siteId: SITE_IDS,
  withAllLand = true
): Promise<string> {
  if (!sc) {
    return '/United-States/all-land/';
  }

  if (sc.userSavedProperties) {
    return '/account/savedproperties/' + siteId + '/page-' + (sc.pageIndex > 0 ? sc.pageIndex + 1 : '');
  }

  const segments = [''];
  const queryString = [];

  // Do we have inventory Id?
  if (sc.inventoryIdList && Array.isArray(sc.inventoryIdList) && sc.inventoryIdList.length > 0) {
    if (sc.inventoryIdList.length === 1) {
      return `/property/${sc.inventoryIdList[0]}/`;
    } else {
      return `/United-States/all-land/prop-${sc.inventoryIdList.join('-')}/`;
    }
  }

  // Do we have city Id?
  else if (sc.cityIdList && Array.isArray(sc.cityIdList) && sc.cityIdList.length === 1) {
    const cityName = await getCityName(sc.cityIdList[0].toString());
    const stateAbbr = await getStateAbbreviation(sc.stateId);
    if (cityName && stateAbbr) {
      segments.push(`${cityName}-${stateAbbr}`);
    }
  }

  // Do we have county Id?
  else if (sc.countyIdList && Array.isArray(sc.countyIdList) && sc.countyIdList.length === 1) {
    const countyName = await getCountyName(sc.countyIdList[0]);
    const stateAbbr = await getStateAbbreviation(sc.stateId);
    if (countyName && stateAbbr) {
      segments.push(`${countyName}-${stateAbbr}`);
    }
  }

  // Do we have region Id?
  else if (sc.regionIdList && Array.isArray(sc.regionIdList) && sc.regionIdList.length === 1) {
    const regionName = await getRegionName(sc.regionIdList[0].toString());
    if (regionName) {
      segments.push(regionName);
    }
  }

  // Do we have lake Id?
  else if (sc.lakeIdList && Array.isArray(sc.lakeIdList) && sc.lakeIdList.length === 1) {
    const lakeName = await getLakeName(sc.lakeIdList[0].toString());
    const stateAbbr = await getStateAbbreviation(sc.stateId);
    if (lakeName && stateAbbr) {
      segments.push(`${lakeName}-in-${stateAbbr}`);
    }
  }

  // Do we have zip code?
  else if (sc.zip && sc.zip.length > 1) {
    segments.push(`zip-${sc.zip}`);
  }

  // Do we have a state Id?
  else if (sc.stateId) {
    const stateName = await getStateName(sc.stateId);
    if (stateName) {
      segments.push(stateName);
    }
  }

  // Is it a near me search?
  else if (sc.isNearMeSearch) {
    // Move the followin into a helper method
    let lat = '';
    let long = '';
    await getLatLong().then(
      (position: any) => {
        lat = encodeURIComponent(position.lat.toString());
        long = encodeURIComponent(position.long.toString());
      },
      () => {
        // Noop if we cannot get lat/long
      }
    );

    segments.push('/lat' + lat);
    segments.push('/long' + long);
    segments.push('/radius-50');
  }

  // Fallback, assume national search.
  if (segments.length === 1) {
    segments.push('United-States');
  }

  // Property Type
  let needAllLand = withAllLand;
  if (sc.propertyTypes && Array.isArray(sc.propertyTypes) && sc.propertyTypes.length > 0) {
    if (sc.propertyTypes.length === 1) {
      const propertyTypeName = await getPropertyTypeName(sc.propertyTypes[0], siteId);

      segments.push(propertyTypeName.replace(/ /g, '-').toLowerCase());
      needAllLand = false;
    } else {
      const flag = sc.propertyTypes.reduce((acc, val) => acc | val);
      segments.push(`type-${flag}`);
    }
  }

  // /has-house or /no-house
  if (typeof sc.hasHouse === 'boolean') {
    if (sc.hasHouse) {
      segments.push('has-house');
    } else {
      segments.push('no-house');
    }
  }

  // transaction type /at-auction or /for-sale
  if (sc.transactionTypes && Array.isArray(sc.transactionTypes) && sc.transactionTypes.length > 0) {
    if (sc.transactionTypes.length === 1) {
      switch (sc.transactionTypes[0]) {
        case TRANSACTION_TYPES.SALE:
          segments.push('for-sale');
          break;
        case TRANSACTION_TYPES.AUCTION:
          segments.push('at-auction');
          break;
        default:
          break;
      }
    }
  }

  // price
  if (typeof sc.priceMin === 'number' || typeof sc.priceMax === 'number') {
    if (sc.priceMin && sc.priceMax) {
      segments.push(sc.priceMin + '-' + sc.priceMax);
    } else if (sc.priceMax) {
      segments.push('under-' + sc.priceMax);
    } else if (sc.priceMin) {
      segments.push('over-' + sc.priceMin);
    }
  }

  // acres
  if (typeof sc.acresMin === 'number' || typeof sc.acresMax === 'number') {
    if (sc.acresMin && sc.acresMax) {
      segments.push(sc.acresMin + '-' + sc.acresMax + '-acres');
    } else if (sc.acresMax) {
      segments.push('under-' + sc.acresMax + '-acres');
    } else if (sc.acresMin) {
      segments.push('over-' + sc.acresMin + '-acres');
    }
  }

  // sqft
  if (typeof sc.sqftMin === 'number' || typeof sc.sqftMax === 'number') {
    if (!sc.hasHouse && (sc.sqftMin || sc.sqftMax)) {
      segments.push('has-house');
    }

    if (sc.sqftMin && sc.sqftMax) {
      segments.push(sc.sqftMin + '-' + sc.sqftMax + '-sqft');
    } else if (sc.sqftMax) {
      segments.push('under-' + sc.sqftMax + '-sqft');
    } else if (sc.sqftMin) {
      segments.push('over-' + sc.sqftMin + '-sqft');
    }
  }

  // beds and baths
  if (typeof sc.bedMin === 'number' && sc.bedMin) {
    segments.push(`over-${sc.bedMin}-bed${sc.bedMin > 1 ? 's' : ''}`);
  }
  if (typeof sc.bathMin === 'number' && sc.bathMin) {
    segments.push(`over-${sc.bathMin}-bath${sc.bathMin > 1 ? 's' : ''}`);
  }

  // keywords
  if (typeof sc.keywordQuery === 'string' && sc.keywordQuery.length > 0) {
    const clean = sc.keywordQuery.replace(/[\W+]+/g, '-');
    segments.push(`with-${clean}`);
  }

  // DateListed
  if (typeof sc.dateListed !== 'undefined' && sc.dateListed !== DATE_LISTED.ANY) {
    switch (sc.dateListed) {
      case DATE_LISTED.LAST_DAY:
        segments.push('listed-last-day');
        break;
      case DATE_LISTED.LAST_WEEK:
        segments.push('listed-last-week');
        break;
      case DATE_LISTED.LAST_MONTH:
        segments.push('listed-last-month');
        break;
      case DATE_LISTED.LAST_TWO_MONTHS:
        segments.push('listed-last-2-months');
        break;
    }
  }

  // market status
  if (sc.marketStatuses && Array.isArray(sc.marketStatuses) && sc.marketStatuses.length > 0) {
    if (
      DefaultMarketStatuses.length === sc.marketStatuses.length &&
      DefaultMarketStatuses.map(status => sc.marketStatuses.includes(status)).length === DefaultMarketStatuses.length
    ) {
      sc.marketStatuses.forEach(val => {
        if (val === MARKET_STATUSES.AVAILABLE) {
          segments.push('is-active');
        } else if (val === MARKET_STATUSES.UNDER_CONTRACT) {
          segments.push('is-under-contract');
        } else if (val === MARKET_STATUSES.OFF_MARKET) {
          segments.push('is-inactive');
        } else if (val === MARKET_STATUSES.SOLD) {
          segments.push('is-sold');
        }
      });
    }
  }

  if (typeof sc.mineralRights === 'boolean' && sc.mineralRights) {
    segments.push('mineral-rights');
  }

  if (typeof sc.ownerFinancing === 'boolean' && sc.ownerFinancing) {
    segments.push('owner-financing');
  }

  if (typeof sc.hasVideo === 'boolean' && sc.hasVideo) {
    segments.push('video');
  }

  if (typeof sc.hasVirtualTour === 'boolean' && sc.hasVirtualTour) {
    segments.push('virtual-tour');
  }

  if (typeof sc.hasCustomMap === 'boolean' && sc.hasCustomMap) {
    segments.push('custom-map');
  }

  // multiple regions
  if (sc.regionIdList && Array.isArray(sc.regionIdList) && sc.regionIdList.length > 1) {
    segments.push(`region-${sc.regionIdList.join('-')}`);
  }

  // multiple counties
  if (sc.countyIdList && Array.isArray(sc.countyIdList) && sc.countyIdList.length > 1) {
    segments.push(`county-${sc.countyIdList.join('-')}`);
  }

  // multiple cities
  if (sc.cityIdList && Array.isArray(sc.cityIdList) && sc.cityIdList.length > 1) {
    segments.push(`city-${sc.cityIdList.join('-')}`);
  }

  // multiple lakes
  if (sc.lakeIdList && Array.isArray(sc.lakeIdList) && sc.lakeIdList.length > 1) {
    segments.push(`lake-${sc.lakeIdList.join('-')}`);
  }

  // zoom & bounds
  if (typeof sc.mapZoomLevel === 'number' && sc.mapZoomLevel) {
    segments.push(`zoom-${sc.mapZoomLevel}`);
  }

  if (sc.mapUpperRightLatitude && sc.mapUpperRightLongitude && sc.mapLowerLeftLatitude && sc.mapLowerLeftLongitude) {
    // these values are arriving as numbers sometimes, not strings.  So adding toString to make sure we handle both until typed
    const urLat = sc.mapUpperRightLatitude.toString().replace(/\-/g, 'n');
    const urLon = sc.mapUpperRightLongitude.toString().replace(/\-/g, 'n');
    const llLat = sc.mapLowerLeftLatitude.toString().replace(/\-/g, 'n');
    const llLon = sc.mapLowerLeftLongitude.toString().replace(/\-/g, 'n');
    segments.push(`bounds-${urLat}-${urLon}-${llLat}-${llLon}`);
  }

  if (sc.latitude && sc.longitude) {
    segments.push(`lat${sc.latitude}/long${sc.longitude}`);
  }

  if (typeof sc.mapEncodedCords === 'string' && sc.mapEncodedCords.length > 0) {
    queryString.push(`sk=${sc.mapEncodedCords}`);
  }

  if (typeof sc.radius === 'number' && sc.radius) {
    segments.push(`radius-${sc.radius}`);
  }

  if (typeof sc.pageIndex === 'number' && sc.pageIndex) {
    segments.push(`page-${sc.pageIndex + 1}`);
  }

  if (needAllLand) {
    segments.splice(2, 0, 'all-land');
  }

  if (queryString.length === 0) {
    return segments.join('/') + '/';
  }

  return segments.join('/') + '/?' + queryString.join('&');
}
