/* eslint-disable @typescript-eslint/naming-convention */
import * as accountEvents from './AccountEvents/AccountEvents';
import * as contactEvents from './ContactEvents/ContactEvents';
import * as favoriteEvents from './FavoriteListingEvents/FavoriteListingEvents';
import * as leadEvents from './LeadEvents/LeadEvents';
import * as listingEvents from './ListingEvents/ListingEvents';
import * as mediaEvents from './MediaEvents/MediaEvents';
import * as pageEvents from './PageEvents/PageEvents';
import * as searchEvents from './ListingSearchEvents/ListingSearchEvents';
import { GA4_SITE_LOCATIONS, getUpgradeLevelString } from './ga4Helpers';

export default {
  accountEvents: accountEvents,
  contactEvents: contactEvents,
  favoriteEvents: favoriteEvents,
  getUpgradeLevelString: getUpgradeLevelString,
  leadEvents: leadEvents,
  listingEvents: listingEvents,
  mediaEvents: mediaEvents,
  pageEvents: {
    /* We map page events explicity so we can organize by page type and reuse the pageLoadFinished event. */
    brokerDetailsPage: {
      pageLoadStarted: pageEvents.brokerDetailsPageLoadStarted,
      pageLoadFinished: pageEvents.pageLoadFinished
    },
    brokerDirectoryPage: {
      pageLoadStarted: pageEvents.brokerDirectoryPageLoadStarted,
      pageLoadFinished: pageEvents.pageLoadFinished
    },
    brokerListingsPage: {
      pageLoadStarted: pageEvents.brokerListingsPageLoadStarted,
      pageLoadFinished: pageEvents.pageLoadFinished
    },
    errorPageDisplayed: pageEvents.errorPageDisplayed,
    financePage: {
      pageLoadStarted: pageEvents.financePageLoadStarted,
      pageLoadFinished: pageEvents.pageLoadFinished
    },
    homePage: {
      pageLoadStarted: pageEvents.homePageLoadStarted,
      pageLoadFinished: pageEvents.pageLoadFinished
    },
    landingPage: {
      pageLoadStarted: pageEvents.landingPageLoadStarted,
      pageLoadFinished: pageEvents.pageLoadFinished
    },
    listingPage: {
      pageLoadStarted: pageEvents.listingPageLoadStarted,
      pageLoadFinished: pageEvents.pageLoadFinished
    },
    savedPropertiesPage: {
      pageLoadStarted: pageEvents.savedPropertiesPageLoadStarted,
      pageLoadFinished: pageEvents.pageLoadFinished
    },
    savedSearchesPage: {
      pageLoadStarted: pageEvents.savedSearchesPageLoadStarted,
      pageLoadFinished: pageEvents.pageLoadFinished
    },
    searchResultsPage: {
      pageLoadStarted: pageEvents.searchResultsPageLoadStarted,
      pageLoadFinished: pageEvents.pageLoadFinished
    },
    sitemapPage: {
      pageLoadStarted: pageEvents.sitemapPageLoadStarted,
      pageLoadFinished: pageEvents.pageLoadFinished
    }
  },
  searchEvents: searchEvents,
  siteLocations: GA4_SITE_LOCATIONS
};
