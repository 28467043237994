import { areUrlsEqualIgnoringCaseAndSlashes } from 'utils/urlCore';
import GlobalHandler from 'handler/globalHandler';
import { FinanceCenterBasicInfo } from 'store/models/financeCenter';
import { LOCATION_TYPES } from './enums';

interface RouteProps {
  rootElement: HTMLElement;
  cssClassName: string;
}

export default {
  onMount(isSSR: boolean, didMountFromServer: () => void, didMountFromClient: () => void, routeProps: RouteProps) {
    if (isSSR) {
      // Mounted from server with data.
      didMountFromServer();
    } else {
      // Go get data because we aren't coming from server.
      didMountFromClient();
      if (routeProps.rootElement && routeProps.cssClassName) {
        routeProps.rootElement.classList.add(routeProps.cssClassName);
      }
    }
  },

  onUpdate(previous: string, current: string, afterUrlChange: () => void, isLoading = false) {
    // revist moving "isloading" check to Propertysearch if not necessary on home or detail page
    // We compare the entire path on home and search pages, and just the final node for the property detail page (to gracefully handle shortcut route.)
    if (!isLoading) {
      const urlHasChanged = !areUrlsEqualIgnoringCaseAndSlashes(previous, current);
      if (urlHasChanged) {
        afterUrlChange();
      }
    }
  },

  onUnmount(routeProps: RouteProps, onUnmountAction?: () => void, handler?: GlobalHandler) {
    if (routeProps.rootElement && routeProps.cssClassName) {
      routeProps.rootElement.classList.remove(routeProps.cssClassName);
    }
    // clear / modify values of dependent data object and remove event listeners

    // we could also set prevroute url here
    if (onUnmountAction) {
      onUnmountAction();
    }
    if (handler) {
      const searchUI = {
        ...handler.app.state.searchUI,
        autoCompleteValues: [],
        locationDisplayValueArray: []
      };

      handler.app.setState({
        searchUI,
        routeContext: {
          urlKey: '',
          locationId: -1,
          locationType: LOCATION_TYPES.DEFAULT,
          isDiamondListing: false,
          locationName: '',
          pageType: '',
          pageTypeGA: '',
          component: '',
          financeCenter: new FinanceCenterBasicInfo()
        }
      });
    }
  }
};
