import { type EmblaCarouselType as CarouselApi } from 'embla-carousel';
import styles from './CarouselPagination.scss';
import cx from 'classnames';

function CarouselPagination({
  emblaApi,
  currentSlideIndex,
  numberOfSlides
}: {
  emblaApi: CarouselApi;
  currentSlideIndex: number;
  numberOfSlides: number;
}): JSX.Element {
  const slides = Array.from(Array(numberOfSlides).keys());

  return (
    <div className={styles['carousel-pagination']}>
      {slides.map((_, index) => (
        <div
          key={index}
          className={styles['pagination-icon']}
          onClick={() => emblaApi && emblaApi.scrollTo(index)}
          role="button"
          tabIndex={0}
        >
          <div className={cx(styles.ellipse, currentSlideIndex === index && styles['ellipse-selected'])} />
        </div>
      ))}
    </div>
  );
}
export default CarouselPagination;
