import Restricted from 'components/ui/Restricted';
import { SITE_IDS } from 'utils/enums';
import { PropertyListing, SiteIdentity } from 'store/commonInterfaces/iSearchObjects';
import { PlacardLocation } from '../../PlacardHelpers';
import PlacardIcon from '../../PlacardIcon';
import GlobalHandler from 'handler/globalHandler';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './PlacardMVTIcons.scss';

export default function PlacardMVTIcons(props: {
  handler: GlobalHandler;
  listing: PropertyListing;
  siteIdentity: SiteIdentity;
  placardLocation: PlacardLocation;
}) {
  const { handler, listing, siteIdentity, placardLocation } = props;

  return (
    <>
      <Restricted to={[SITE_IDS.LANDWATCH, SITE_IDS.LANDANDFARM]}>
        <div className={styles.ctas}>
          <PlacardIcon
            type="video"
            listing={listing}
            handler={handler}
            iconText="VIDEO"
            siteIdentity={siteIdentity}
            placardLocation={placardLocation}
          />
          <PlacardIcon
            placardLocation={placardLocation}
            type="map"
            listing={listing}
            handler={handler}
            iconText="MAP"
            siteIdentity={siteIdentity}
          />
          <PlacardIcon
            placardLocation={placardLocation}
            type="tour"
            listing={listing}
            handler={handler}
            iconText="3D TOURS"
            siteIdentity={siteIdentity}
          />
        </div>
      </Restricted>
      <Restricted to={SITE_IDS.LAND}>
        <PlacardIcon
          placardLocation={placardLocation}
          type="video"
          listing={listing}
          handler={handler}
          siteIdentity={siteIdentity}
        />
        <PlacardIcon
          placardLocation={placardLocation}
          type="tour"
          listing={listing}
          handler={handler}
          siteIdentity={siteIdentity}
        />
        <PlacardIcon
          placardLocation={placardLocation}
          type="map"
          listing={listing}
          handler={handler}
          siteIdentity={siteIdentity}
        />
      </Restricted>
    </>
  );
}
