import ListingLogInfo from 'store/models/listingLogInfo';
import { EXTERNAL_LINK_TYPES } from './enums';
import GlobalHandler from 'handler/globalHandler';
import ga4 from './GA4';
import { getUpgradeLevelString } from './GA4/ga4Helpers';
import BrokerDetailsType from 'store/models/brokerDetails';
import { DetailsAPIProperty, PropertyListing } from 'store/commonInterfaces/iSearchObjects';

export function handleWebsiteClick(
  handler: GlobalHandler,
  propertyData: DetailsAPIProperty,
  externalLink: string,
  openLink = true
) {
  if (propertyData) {
    if (openLink) {
      handler.handleLog.logAndOpenExternalLink(
        [ListingLogInfo.APIPropertyToLogInfo(propertyData)],
        externalLink,
        EXTERNAL_LINK_TYPES.EXTERNAL_LISTING_URL
      );
    } else {
      handler.handleLog.logExternalLink(
        [ListingLogInfo.APIPropertyToLogInfo(propertyData)],
        externalLink,
        EXTERNAL_LINK_TYPES.EXTERNAL_LISTING_URL
      );
    }
    ga4.listingEvents.listingWebsiteClicked();
  }
}

export function logPhoneClick(
  handler: GlobalHandler,
  brokerDetails: BrokerDetailsType,
  listingLogInfo?: ListingLogInfo
) {
  ga4.contactEvents.callClicked(brokerDetails);
  const logInfo = listingLogInfo ?? brokerDetails.listingLogInfo;
  // Because elastic logging must be associated with a listing, we are not able to log on the broker details page
  if (logInfo) {
    handler.handleLog.logPhoneClick([logInfo]);
  }
}

export function logPhoneClickFromPlacard(handler: GlobalHandler, listing: PropertyListing) {
  ga4.contactEvents.callClickedFromPlacard(
    listing.accountId,
    listing.id,
    listing.siteListingId,
    listing.listingLevel,
    getUpgradeLevelString(listing.upgradeLevelId)
  );
  handler.handleLog.logPhoneClick([ListingLogInfo.ListingToLogInfo(listing)]);
}
